import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { EnvironmentService } from '../../Services/environment.service';

@Component({
  selector: 'app-inactive-dialog',
  templateUrl: './inactive-dialog.component.html',
  styleUrls: ['./inactive-dialog.component.scss']
})
export class InactiveDialogComponent implements OnInit {

  public minute: number;
  public second: number;
  public seconds = 480;
  public expire = false;

  constructor(public dialogRef: MatDialogRef<InactiveDialogComponent>, private env: EnvironmentService,) {
    if(this.env.localEnvironment.inactiveCountdownSeconds)
    {
      this.seconds = this.env.localEnvironment.inactiveCountdownSeconds;
    }

    const that = this;
    const x = window.setInterval(function() {
      that.minute = Math.floor(that.seconds / 60);
      that.second = that.seconds - that.minute * 60;
      that.seconds --;
      if (that.seconds < 0) {
        clearInterval(x);
        that.expire = true;
        that.dialogRef.close(true);
      }
    }, 1000);
  }

  ngOnInit() {
  }

}
