import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MessageService } from './message.service';
import { Observable, of, Subject } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { InternalCookieService } from '../cookie.service';
import { RouterService } from '../Hubs/router.service';
import { EnvironmentService } from '../environment.service';
import { EnvironmentVar } from '../../models/environmentvar';

// export interface Resource { name: string; distance: string; description: string; locations: any[]; providedBy: string; link: string; }
export interface Resource {
    name: string;
    email: string;
    phone: string;
    website: string;
    company: any;
}


@Injectable({
  providedIn: "root",
})
export class ResourceService {
 //todo: IzzyIonic Inspection   
 httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Accept': 'application/json',
          'Access-Control-Allow-Headers': 'Content-Type',
        })
      };


  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private cookieService: InternalCookieService,
    private routerService: RouterService,
    private envService: EnvironmentService
  ) {
    console.log(
      "------------------ Resource Service Constructor ---------------------"
    );
  }


        GetCommunities (): Observable<any[]> {
            console.log('GetCommunities');
          return this.http.get<any[]>(this.envService.localEnvironment.restEndpointUrl + '/api/resources/communities/', this.httpOptions).pipe(
                tap(communities => this.log('fetched communities')),
                catchError(this.handleError('GetCommunities', []))
              );
        }


getEndPoint() {
    let endPoint = this.envService.localEnvironment.restEndpointUrl;
    if (this.envService.localEnvironment.mainSubscriberId === 121) {
        endPoint = 'https://aasas.aasasoneline.ca';
    }
    return endPoint;
}

getCategories(filter: string, communityId: number, community: string,  distance: number): Observable<any> {
    console.log('getCategories Resources q: ' + filter + ', cID: ' + communityId + ', c: ' + community + ', d: ' + distance );

  // tslint:disable-next-line:max-line-length
  return this.http.get<any[]>(this.getEndPoint() + '/api/resources/categories/?q=' + filter + '&c=' + community + '&d=' + distance, this.httpOptions).pipe(
        tap(resources => this.log('fetched resources')),
        catchError(this.handleError('getCategories', []))
      );
  }

  GetServicesForCategory(
    filter: string,
    communityId: number,
    community: string,
    distance: number,
    categoryId
  ): Observable<any[]> {
    // tslint:disable-next-line:max-line-length
    console.log(
      "getCategories Resources q: " +
        filter +
        ", cID: " +
        communityId +
        ", c: " +
        community +
        ", d: " +
        distance +
        ", " +
        categoryId
    );

  // tslint:disable-next-line:max-line-length
  return this.http.get<any[]>(this.getEndPoint() + '/api/resources/category/' + categoryId + '/services?q=' + filter + '&c=' + community + '&d=' + distance, this.httpOptions).pipe(
        tap(resources => this.log('fetched services for category')),
        catchError(this.handleError('GetServicesForCategory', []))
      );
  }

  GetServicesByName(
    filter: string,
    communityId: number,
    community: string,
    distance: number,
    start,
    end
  ): Observable<any[]> {
    // tslint:disable-next-line:max-line-length
    console.log(
      "getCategories Resources q: " +
        filter +
        ", cID: " +
        communityId +
        ", c: " +
        community +
        ", d: " +
        distance +
        ", " +
        start +
        ", " +
        end
    );

  // tslint:disable-next-line:max-line-length
  return this.http.get<any[]>(this.getEndPoint() + '/api/resources/ResourcesByName?q=' + filter + '&c=' + community + '&d=' + distance + '&start=' + start + '&end=' + end, this.httpOptions).pipe(
        tap(resources => this.log('fetched services for category')),
        catchError(this.handleError('GetServicesForCategory', []))
      );
  }

  GetService(id): Observable<any> {
    // tslint:disable-next-line:max-line-length
    console.log("getService ID: " + id);

  // tslint:disable-next-line:max-line-length
  return this.http.get<any[]>(this.getEndPoint() + '/api/resources/service?id=' + id, this.httpOptions).pipe(
        tap(resources => this.log('fetched service')),
        catchError(this.handleError('GetService', []))
      );
  }

  getAllSmall (filter: string, communityId: number, community: string,  distance: number): Observable<any[]> {
    console.log('getAll Resources q: ' + filter + ', cID: ' + communityId + ', c: ' + community + ', d: ' + distance );

  // tslint:disable-next-line:max-line-length
  return this.http.get<any[]>(this.getEndPoint() + '/api/resources/services2/?q=' + filter + '&c=' + community + '&d=' + distance, this.httpOptions).pipe(
        tap(resources => this.log('fetched resources')),
        catchError(this.handleError('getAll', []))
      );
  }

  getAll(
    filter: string,
    communityId: number,
    community: string,
    distance: number
  ): Observable<any[]> {
    console.log(
      "getAll Resources q: " +
        filter +
        ", cID: " +
        communityId +
        ", c: " +
        community +
        ", d: " +
        distance
    );

    // tslint:disable-next-line:max-line-length
    var temp =
    this.envService.localEnvironment.restEndpointUrl +
      "/api/resources/services/?q=" +
      filter +
      "&c=" +
      community +
      "&d=" +
      distance;

    return this.http
      .get<any[]>(
        this.envService.localEnvironment.restEndpointUrl +
          "/api/resources/services/?q=" +
          filter +
          "&c=" +
          community +
          "&d=" +
          distance,
        this.httpOptions
      )
      .pipe(
        tap((resources) => this.log("fetched resources")),
        catchError(this.handleError("getAll", []))
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      if (error.status === 401) {
        this.routerService.redirectToLoginPage();
      } else {
        //window.location.href = window.location.protocol + '//' + window.location.hostname + '/Login.aspx';
      }

      // TODO: send the error to remote logging infrastructure
      console.log("error ", error);
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a HeroService message with the MessageService */
  private log(message: string) {
    this.messageService.add(`Conversation Service: ${message}`);
  }
}
