import { ConversationsService, Conversation } from './conversations.service';
import { EntityDataService } from './entity-data.service';
import { Injectable } from '@angular/core';
import { Observable, of, Subject, BehaviorSubject } from 'rxjs';
import { Subscription } from 'rxjs';


@Injectable({
    providedIn: 'root'
})


export class CallerService {

    subscription: Subscription;
    private activeCallerSource = new BehaviorSubject<any>(null);
    selectedCaller$ = this.activeCallerSource.asObservable();

    constructor(private entityDataService: EntityDataService
    ) {
        console.log('------------------ Caller Service Constructor ---------------------');
     }


    // Service message commands
    setCaller(caller: any) {
        console.log('Caller Service: ', caller);
        this.activeCallerSource.next(caller);
    }

    getActiveCaller() {
        return this.activeCallerSource.getValue();
    }

    loadCaller(callerId): Observable<any[]> {
        console.log('----------- Caller ----------------');
        return this.entityDataService.get(callerId);
    }

    createCaller(): Observable<any> {
        // see if it is already created and return it.
        return this.entityDataService.create({ entityTypeId: 592, properties: { identity: 'Internally Created Caller' } });
    }

    saveCallerNumberVisibility(caller: any, user, conversation): Observable<any> {
        return this.entityDataService.create({
                 entityTypeId: 674,
                 linkedProperties:  {user: { id: user.id },
                  caller: { id: caller.id },
                  conversation: { id: conversation.id }}
              });



        // return this.entityDataService.entitySyncUpdate([{
        //     id: caller.id,
        //     properties: { showphonenumber: caller.properties.showphonenumber }
        // }]);

        // //save record of this change.
        // let linkedProperties = {};
        // linkedProperties['user'] = { id: user.id };
        // linkedProperties['caller'] = { id: caller.id };


        // return this.entityDataService.create({
        //     entityTypeId: 670,
        //     properties: {showcallernumber: caller.properties.showphonenumber},
        //     linkedProperties
        //   });


        // return this.entityDataService.create({ entityTypeId: 670, properties: { showcallernumber: caller.properties.showphonenumber } });


    }

}
