import { UserService } from './user.service';
import { PushNotificationsService } from '../Notifcations/push-notifications.service';
import { CallerService } from './caller.service';
import { Injectable, NgZone, OnInit, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MessageService } from './message.service';
import { Observable, of, Subject, BehaviorSubject, Subscription } from 'rxjs';
import { catchError, tap, distinctUntilChanged, throttleTime  } from 'rxjs/operators';
import { InternalCookieService } from '../cookie.service';
import { EntityTypeMessage } from '../Hubs/entity-type.service';
import { EntityDataService } from './entity-data.service';
import { EntityTypeService } from '../Hubs/entity-type.service';
import { environment } from '../../../environments/environment';
import { SmsService } from './sms.service';
import * as moment from 'moment';
import { SignalRConnectionService } from '../signal-r-connection.service';
import { RouterService } from '../Hubs/router.service';
import { EnvironmentService } from '../environment.service';
import { SoundContext, AudioService } from 'src/app/Services/audio.service';
import { IWarpEntity } from '../models/warp-entity';
import { support } from 'jquery';

// tslint:disable-next-line:max-line-length
export interface Conversation extends IWarpEntity {
  id: number;
  linkedProperties: any;
  callerNickname?: string;
  properties: any;
  name: string;
  time: string;
  isActive: boolean;
  childIds: any;
  numUnviewed: number;
  ifSilence: boolean;
  childEntities: any;
  created: any;
  createdECMA: any;
  guid?: string;
 }

export function getCallerName(name) {
  if (name.indexOf(' - ') >= 0) {
      return name.substring(0, name.indexOf(' - '));
  } else {
      return name;
  }
}

export enum ConversationStatus {
    Incoming   = 1066,
    Active     = 1067,
    Unassigned = 1068,
    Completed  = 1069,
    Blocked    = 1188,
    OutOfHours = 1219,
    Hangup     = 1220,
}

@Injectable({
    providedIn: 'root'
})

export class ConversationsService implements OnInit, OnDestroy {
    // Observable string sources
    selectedConversationSource$ = new BehaviorSubject<Conversation>(null);
    dashBoardConversationsLoading$ = new BehaviorSubject<boolean>(true);
    dashBoardConversations$: BehaviorSubject<any[]>;
    reportConversations$: BehaviorSubject<any[]>;
    tryRefreshList$ = new Subject();
    userSubscription: Subscription;

    user: any = {};
    firstConnected = true;

    conversations: Conversation[] = [];
    handling: any[] = [];
    playedSinceLastChecked = false;
    lastPlayedAudioTime = null;

    conversationColorArr = ['light-blue', 'light-green', 'light-pink', 'light-tourqoise'];
    isMobileApp = environment.isMobileApp;
    httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Accept': 'application/json',
          'Access-Control-Allow-Headers': 'Content-Type'
        })
    };

    subscriptionToMessage: Subscription;

    constructor(
        private http: HttpClient,
        // private hubService: HubService,
        private zone: NgZone,
        private audio: AudioService,
        private entityTypeService: EntityTypeService,
        private entityDataService: EntityDataService,
        private messageService: MessageService,
        private cookieService: InternalCookieService,
        private callerService: CallerService,
        private pushNotificationsService: PushNotificationsService,
        private smsService: SmsService,
        private userService: UserService,
        private signalRConnectionService: SignalRConnectionService,

        private routerService: RouterService,
        public envService: EnvironmentService
  ) {
        console.log('---------------- Conversation Service Constructor -----------------');
        this.dashBoardConversations$ = new BehaviorSubject([]);
        this.reportConversations$ = new BehaviorSubject([]);
        if (!window.location.pathname.includes('resource-dashboard')) {

            this.signalRConnectionService.signalrConnected.subscribe(result => {
                if (result && this.firstConnected) {
                    this.firstConnected = false;
                    this.entityTypeService.subscribe('591').toPromise();
                    this.entityTypeService.subscribe('600').toPromise();
                    console.log('Subscribing to the CONVERSATIONS EntityTypeService');
                    this.entityTypeService.onMessageReceived.pipe(distinctUntilChanged()).subscribe((entityTypeMessage: EntityTypeMessage) => {
                        console.log('Changed!', entityTypeMessage);
                        // tslint:disable-next-line:max-line-length
                        console.log('handling after push', this.handling);
                        this.addOrUpdateConversation(entityTypeMessage);
                    });
                }
            });

            // this.authService.loggedIn.subscribe(val => {
            //   if (val) {
            //     this.addOrUpdateConversations(true);
            //   } else {
            //     this.conversations = [];
            //   }
            // });
            this.userService.currentUser$.subscribe(user => {
              if (user) {
                this.addOrUpdateConversations(true);
              } else {
                this.conversations = [];
              }
            });
            this.NotifyIncomingWaiting();
            const that = this;

            // window.setInterval(function () {
            //     that.NotifyIncomingWaiting();
            // }, 15000);

            this.signalRConnectionService.signalrReconnected.subscribe(connected => {
                if (connected) {
                    console.log('Resubscribing to Conversations');
                    this.entityTypeService.subscribe('591').toPromise();
                    this.entityTypeService.subscribe('600').toPromise();
                }
              });


        }

        this.userSubscription = this.userService.currentUser$.subscribe(user => {
            console.log('Conversations User Subscription returned.');
            this.user = user;
        });

        this.userSubscription.add(
            // this is an expensive operation so lets chill with it
            this.tryRefreshList$
                .pipe(tap(_ => console.log('queue a refresh')), throttleTime(500, undefined, { leading: true, trailing: true }))
                .subscribe(_ => this._refreshConversationList())
        );

    }

    async ngOnInit() {
    }

    ngOnDestroy(): void {
        // Called once, before the instance is destroyed.
        // Add 'implements OnDestroy' to the class.
        this.userSubscription.unsubscribe();
    }

    acceptConversation (user: any, conversation: Conversation): Observable<any> {
        let linkedProperties = {};
        linkedProperties['user'] = { name: '', id: user.id };

        // let dt = moment();
        // let acceptedDateTime = dt.format('YYYY-MM-DD') + ' ' + dt.format('hh:mm:ss');

        // let's silence the notifier.
        if (this.conversations.find(x => x.id === conversation.id)) {
            this.conversations.find(x => x.id === conversation.id).ifSilence = true;
        }

        return this.entityDataService.acceptConversation([{
            id: conversation.id,
            properties: {conversationstatus_cfcid: '1067', tickettype_cfcid: ''},
            linkedProperties
          }]);
    }

    getColor() {
        return  '#' + Math.floor(Math.random() * 16777215).toString(16);
    }


    NotifyNoConversationSelected() {
        console.log('NotifyNoConversationSelected.');
        this.selectedConversationSource$.next(null);
    }


    endConversation(conversation) {
        this.selectedConversationSource$.next(null);
        // tell the ticket to clear.
        this.entityDataService.entitySyncUpdate([{
          id: conversation.id,
          properties: {conversationstatus_cfcid: '1069'}
        }]).subscribe(result => {});
    }

    // Service message commands
    async setConversation(_conversation: Conversation | number) {
        const conversation = typeof _conversation !== 'number' ?
          _conversation : this.dashBoardConversations$.value.find(c => c.id === _conversation);

        if (conversation && conversation.linkedProperties.caller) {


          const promise = new Promise<string>((resolve, reject) => {
            this.callerService.loadCaller(conversation.linkedProperties.caller.id).subscribe(result => {
              this.callerService.setCaller(result);
              // console.log('set conversation to conversation' + JSON.stringify(conversation));
              this.selectedConversationSource$.next(conversation);
              console.log('setConversation.', conversation);
              console.log('set conversation finished =>' + this.selectedConversationSource$.value.id + '<=');
              resolve(this.selectedConversationSource$.value.id.toString());
            });
          });
          const retVal = await promise;
          return retVal;
        } else {
          return 'not found';
        }
    }

    isActiveConversation(conversation: Conversation) {
        return this.selectedConversationSource$.value && this.selectedConversationSource$.value.id === conversation.id;
    }

    getActiveConversation() {
        return this.selectedConversationSource$.getValue();
    }

    async addOrUpdateConversation(entityTypeMessage) {
        console.log('addOrUpdateConversation', entityTypeMessage);
        const activeConv = this.getActiveConversation();
        let playSounds = false;
        // add or update this one conversation and all of it's messages
        let conversationID = parseInt(entityTypeMessage.entityID, 10);
        if (entityTypeMessage.entityTypeID === '600' && entityTypeMessage.message === 'ADDED') {
            // we have a new message, go get it so we can see which conversation is affected.
            let chatMessage = await this.entityDataService.get(entityTypeMessage.entityID).toPromise();
            console.log('chatMessage', chatMessage);
            conversationID = chatMessage.linkedProperties.conversation.id;

            let documentVisible = true;
            // determine if this is the active Tab
            if (document.visibilityState === 'hidden') {
                documentVisible = false;
              }

              // notify if this isn't my conversation and I sent the message.
              // tslint:disable-next-line:max-line-length
              if (!(documentVisible && activeConv && activeConv.id === conversationID && chatMessage.properties.smsdirection === 'Outgoing')) {
            // if (true) { // Always notify  if (!documentVisible || !activeConv || activeConv.id !== conversationID) {
                let curr = this.conversations.find(x => x.id === conversationID);
                if (curr) {
                    this.zone.run(() => { // <== added
                    curr.childEntities.push(chatMessage); // add in this chat message.
                    if (!activeConv || activeConv.id !== conversationID) {
                        curr.numUnviewed++;

                    }
                    if (curr && curr.properties.conversationstatus_cfcid !== '1066') {

                        // we can add another option to only ding if a conversation belongs to the user
                        // This would be specifically for supervisors and superadmins
                        // so they don't have to listen to all of the conversations.
                        console.log('Active User on this dashboard.', this.user);
                        if (curr.linkedProperties.user && curr.linkedProperties.user.id === this.user.id || this.user.playNotificationsForOthers) {

                            console.log('About to Play message', curr);
                            console.log('Active User on this dashboard.', this.user);
                            playSounds = true;
                        }
                    }
                    console.log('numUnviewed: ', curr.numUnviewed);
                    });
                }
            }
        }

        // now if the EntityTypeMessage is for a 600 then done. otherwise update the specific conversation
        if (entityTypeMessage.entityTypeID === '591') {
            let conversation = await this.entityDataService.GetEntityWithChildren(conversationID, '600').toPromise();
            console.log('Conversation', conversation);

            if (conversation != null && conversation.properties) {
                // console.log('conversations: ', this.conversations);
                let curr = this.conversations.find(x => x.id === conversationID);
                if (!curr && entityTypeMessage.message === 'UPDATED')
                    entityTypeMessage.message = 'ADDED';
                    
                if (entityTypeMessage.message === 'ADDED') {
                    if (!curr && conversation) {
                        // this conversation is not in the conversation list yet so add it.
                        console.log('Adding Conversation', conversation);
                        conversation.numUnviewed = conversation.childEntities.length;
                        conversation.ifSilence = true;
                        if (conversation && (conversation.properties.conversationstatus_cfcid === '1066' || conversation.properties.conversationstatus_cfcid === '1068' )) {
                            conversation.ifSilence = false;
                        }
                        // playSounds = true;
                        this.conversations.push(conversation);
                        this.playedSinceLastChecked = false;
                        this.NotifyIncomingWaiting();
                        this.refreshConversationList();
                    }
                } else if (entityTypeMessage.message === 'UPDATED') {
                    // determine if it is completed and therefore needs to be removed.
                    console.log("Convesation updated.");

                    if (curr && curr.callerNickname !== conversation.callerNickname) {
                        curr.name = conversation.name;
                        curr.callerNickname = conversation.callerNickname;
                        try {
                            curr.linkedProperties.caller.name = conversation.linkedProperties.caller.name;
                        } catch (e) {}
                    }

                    if (conversation && conversation.properties.conversationstatus_cfcid === '1069') {

                        if (curr && curr.properties.convesationstatus_cfcid === '1066') {
                            console.log('Reloading conversation...');
                            await this.sleep(1000);
                            this.playedSinceLastChecked = false;
                            this.NotifyIncomingWaiting();
                            conversation = await this.entityDataService.GetEntityWithChildren(conversationID, '600').toPromise();
                        }
                        console.log('Conversation Completed.', conversation);

                        const time = moment.duration('12:00:00');
                        const startDate = moment(new Date()).subtract(time);

                        const callStart = moment(conversation.properties.callstart);
                        console.log('Conversation CallStart: ', callStart);

                        // determine if the conversation is pending assignment. If so add it back in if not already there.
                        // tslint:disable-next-line:max-line-length
                        if (conversation.properties.tickettype_cfcid === '1185' && callStart > startDate && !conversation.linkedProperties.user) {

                            if (!curr) {
                                // not currently in there so add it.
                                conversation.ifSilence = true;
                                this.conversations.push(conversation);
                            } else {
                                curr.properties = conversation.properties;
                                curr.linkedProperties = conversation.linkedProperties;
                                curr.numUnviewed = 0;
                                conversation.ifSilence = true;
                                this.refreshConversationList();
                            }

                        } else {
                            this.removeConversation(conversation);
                        }
                        // tslint:disable-next-line:max-line-length
                    } else if (curr && conversation && conversation.properties.conversationstatus_cfcid !== curr.properties.conversationstatus_cfcid) {
                        // update the conversation status if needed.
                        curr.properties = conversation.properties;
                        curr.linkedProperties = conversation.linkedProperties;
                        curr.numUnviewed = 0;
                        conversation.ifSilence = true;
                        this.refreshConversationList();
                    } else if (curr && conversation && conversation.properties.callend !== curr.properties.callend) {
                        console.log("Conversation Call End updated.");
                        curr.properties.callend = conversation.properties.callend;
                        if (activeConv && activeConv.id === curr.id) {
                            //forcing a refresh of the conversation.  --may not be needed...
                            this.selectedConversationSource$.next(conversation);
                        }
                        this.refreshConversationList();
                    } else if (curr && conversation && conversation.properties.user !== curr.properties.user) {
                        console.log("Conversation Transferred to a different user. ");
                            curr.properties.user = conversation.properties.user;
                            curr.linkedProperties.user = conversation.linkedProperties.user;
                            if (activeConv && activeConv.id === curr.id) {
                                //force the ticket to update.
                                this.selectedConversationSource$.next(conversation);
                            }
                    } else if (curr && conversation && 
                        (curr.properties.enabletranslation !== conversation.properties.enabletranslation 
                            || curr.properties.supportlanguagepreference !== conversation.properties.supportlanguagepreference
                            || curr.properties.clientlanguagepreference !== conversation.properties.clientlanguagepreference)) {
                        curr.properties.enabletranslation = conversation.properties.enabletranslation;
                        curr.properties.supportlanguagepreference = conversation.properties.supportlanguagepreference;
                        curr.properties.clientlanguagepreference = conversation.properties.clientlanguagepreference;
                        
                        this.selectedConversationSource$.next(conversation);
                    }
                    else {
                        if (!curr !== !conversation || curr && conversation && curr.callerNickname !== conversation.callerNickname) {
                            this.refreshConversationList();
                        }
                    }
                } else if (entityTypeMessage.message === 'DELETED') {
                    // try to remove if in list.
                    this.removeConversation(conversation);
                }
            } else {

                if (entityTypeMessage.message === 'UPDATED')
                {
                    setTimeout(async() => {
                        const conversationNew = await this.entityDataService.GetEntityWithChildren(conversationID, '600').toPromise();
                        if (conversationNew == null)
                            this.removeConversationByID(conversationID);
                     }, 2000);

                } else if (entityTypeMessage.message === 'DELETED') {
                    // try to remove if in list.
                    this.removeConversationByID(conversationID);
                }
            }
        }

        if (playSounds) {
            this.playAudio(true);
        }

        // tslint:disable-next-line:max-line-length
        const index: number = this.handling.findIndex(x => x.entityTypeID === entityTypeMessage.entityTypeID && x.entityID === entityTypeMessage.entityID);
        if (index >= 0) {
            this.handling.splice(index, 1);
        }
    }



    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      }

    countUnread(): number {
      let count = 0;
      this.conversations.forEach(conversation => {
        count += conversation.numUnviewed;
        if (conversation.properties.conversationstatus_cfcid === 1066) {
          count++;
        }
      });
      return count;
    }

    updateConversationEntry(conversation:Conversation){
      //console.log(this.conversations)

      let index = this.conversations.findIndex(x=>x.id==conversation.id)
      if(index != -1){
        this.conversations[index] = conversation
      }
      this.dashBoardConversations$.next(this.conversations)

    }

    removeConversation(conversation) {
        this.removeConversationByID(conversation.id);
        // console.log('Removing Conversation', conversation);
        // const index: number = this.conversations.findIndex(x => x.id === conversation.id);
        // if (index >= 0) {
        //     this.conversations.splice(index, 1);
        // }
        // this.refreshConversationList();
    }

    removeConversationByID(ID) {
        console.log('Removing Conversation ID', ID);
        const index: number = this.conversations.findIndex(x => x.id === ID);
        if (index >= 0) {
            this.conversations.splice(index, 1);
        }
        this.refreshConversationList();

        const activeConv = this.getActiveConversation();
        if (activeConv && activeConv.id === ID) {
            // This conversation is no longer active.
            this.NotifyNoConversationSelected();
        }
    }

    refreshConversationList() {
        this.tryRefreshList$.next(undefined);
    }

    _refreshConversationList() {
        this.zone.run(() => { // <== added
            console.log('Refresh Conversations');
            this.conversations.sort(this.SortConversations);
            this.dashBoardConversations$.next(this.conversations);
        });
    }

    CanSendMessageToSelectedConversation() {
        // tslint:disable-next-line:max-line-length
        return this.getActiveConversation() && this.getActiveConversation().properties.conversationstatus === 'Active' && this.getActiveConversation().properties.manuallycreated != 'Yes' && !this.getActiveConversation().properties.callend;
    }

    addOrUpdateConversations(isInit) {
        // reload all of the active/incoming conversations.
        this.loadAllConversation(isInit);
    }

    loadAllConversation(isInit) {
        this.dashBoardConversationsLoading$.next(true);
        // tslint:disable-next-line:max-line-length
        // tslint:disable-next-line:max-line-length                   Completed,                Unassigned,             Blocked,                  Hangup,                  OutOfHours
        this.entityDataService.getAllWithFilter(591, 'conversationstatus!=1069,conversationstatus!=1068,conversationstatus!=1188,conversationstatus!=1220,conversationstatus!=1219', true, 'loadChildren=600' ).subscribe(conversations => {
          const time = moment.duration('12:00:00');
          let startDate = moment(new Date()).subtract(time).format('MM/DD/YYYY h:mm A');
          if (this.envService.localEnvironment.pendingAssignmentDuration && this.envService.localEnvironment.pendingAssignmentDuration < 0)
              startDate = '01/01/2019 1:00:00 PM';

          // const startDate = '03/18/2019 2:37 PM';
          // tslint:disable-next-line:max-line-length
          // tslint:disable-next-line:max-line-length                                                                                   Pending Assignment
          this.entityDataService.getAllWithFilter(591, `conversationstatus=${ConversationStatus.Completed},callstart>=${startDate},tickettype=1185`, true, 'loadChildren=600').subscribe(completedConversations => {
            // console.log('completed conversation:', startDate, completedConversations);

            const activeConv = this.getActiveConversation();
            const tempConversations = completedConversations.filter(item => !item.linkedProperties.user);
            const that = this;
            // update conversations.
            let playSounds = false;
            conversations.forEach(c => {
                if (this.conversations.find(x => x.id === c.id)) {
                    let curr = this.conversations.find(j => j.id === c.id);
                    if (c.childEntities.length !== curr.childEntities.length) {
                        // tslint:disable-next-line:max-line-length
                        if (activeConv && activeConv.id === curr.id) {
                            curr.numUnviewed = 0;
                            curr.childEntities = c.childEntities;
                        } else {
                            curr.numUnviewed = c.childEntities.length - curr.childEntities.length;
                            if (!playSounds && c.childEntities.length > curr.childEntities.length) {
                                playSounds = true;
                            }
                        }
                    }
                    const tempNum = curr.numUnviewed;
                    const tempChildren = curr.childIds;
                    const tempChildrenEntities = curr.childEntities;
                    // if incoming play a sound.
                    if (parseInt(c.properties.conversationstatus_cfcid, 10) !== 1066) {
                      curr.ifSilence = true;
                    }
                    const tempIfSilence = curr.ifSilence;
                    curr = c;
                    curr.numUnviewed = tempNum;
                    curr.childIds = tempChildren;
                    c.childEntities = tempChildrenEntities;
                    curr.ifSilence = tempIfSilence;
                    tempConversations.push(curr);
                } else {
                    if (isInit) {

                        if (!c.properties.conversationcolor || c.properties.conversationcolor.indexOf("#")< 0)
                            c.properties.conversationcolor = '#FFFFFF';

                        c.numUnviewed = 0;
                        c.ifSilence = true;
                        if (parseInt(c.properties.conversationstatus_cfcid) === 1066) {
                          c.ifSilence = false;
                        }
                    } else {
                        c.numUnviewed = 1;
                        c.ifSilence = false;
                    }
                    tempConversations.push(c);
                }
            });
            // this.conversations = conversations;
            // order the tempConversations;
            this.conversations = tempConversations.sort(this.SortConversations);
            if (playSounds) {
                this.playAudio();
            } else {
                this.playedSinceLastChecked = false;
            }
            this.dashBoardConversationsLoading$.next(false);
            this.dashBoardConversations$.next(this.conversations);
            this.NotifyIncomingWaiting();

          });
        });
    }


    SortConversations = (n1, n2) => {
        return n2.id - n1.id;
        // if (n1.id < n2.id) {
        //     return 1;
        // }
        // if (n1.id > n2.id) {
        //     return -1;
        // }
        // return 0;
        }


    NotifyIncomingWaiting() {
        console.log('notifyIncomingWaiting', this.playedSinceLastChecked);
        if (this.conversations.find(x => !x.ifSilence && parseInt(x.properties.conversationstatus_cfcid) === 1066)) {
            console.log('notifyIncomingWaiting Play Audio');
            if (!this.playedSinceLastChecked)
                this.playAudio();
            setTimeout(() => {
               this.NotifyIncomingWaiting();
            }, 15000);
        }
        this.playedSinceLastChecked = false;
    }

    // this play audio should play when it's web version only,
    // for phone app version the audio plays are in mobile-in-app-notification services
    playAudio(incomingMessageSound:boolean = false) {
        if (!this.lastPlayedAudioTime || moment(new Date()).diff(moment(this.lastPlayedAudioTime), 'seconds') >= 5) {
            this.lastPlayedAudioTime = new Date();
            if (this.isMobileApp) {
                return;
            }
            this.pushNotificationsService.notify(this.envService.localEnvironment.notificationTitle,
                'A new message has been received for one of your conversations or a conversation is waiting to be accepted.');
            console.log('Playing New message sound.');

          if (incomingMessageSound)
            this.audio.playAudio(SoundContext.Notification, 'assets/audio/IncomingMessage.wav');
          else
            this.audio.playAudio(SoundContext.Notification, 'assets/audio/NewMessage.mp3');
          this.playedSinceLastChecked = true;
        }
    }



    formatDate(date) {
        let hours = date.getHours();
        let minutes = date.getMinutes();
        let ampm = hours >= 12 ? 'pm' : 'am';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0' + minutes : minutes;
        let strTime = hours + ':' + minutes + ' ' + ampm;
        return date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear() + '  ' + strTime;
      }



      CreateConversation(conversation: any): Promise<Boolean> {
        const retVal: Promise<Boolean> = new Promise<Boolean>((resolve, reject) => {
            this.entityDataService.create(
                conversation).subscribe(newConversation => {
              if (newConversation && newConversation.id > 0) {
                resolve(true);
              } else {
                reject("Create Server error, please try again.");
              }
            });
          });
        return retVal;
      }

    enableTranslation(conversationId: number): Observable<any> {
        const postObj = {
            conversationId
        }
        return this.http
            .post<any[]>(
                this.envService.localEnvironment.restEndpointUrl +
                    '/api/callcentre/translation/enableTranslation', postObj,
                this.httpOptions
            )
            .pipe(
                tap((result) => console.log("Posted Enable Translation", result)),
                catchError(this.handleError("get", []))
            );
    }
    disableTranslation(conversationId: number): Observable<any> {
        const postObj = {
            conversationId
        }
        return this.http
            .post<any[]>(
                this.envService.localEnvironment.restEndpointUrl +
                    '/api/callcentre/translation/disableTranslation', postObj,
                this.httpOptions
            )
            .pipe(
                tap((result) => console.log("Posted Enable Translation", result)),
                catchError(this.handleError("get", []))
            );
    }

    updateLanguagePreference(conversationId: number, supportPref: string, clientPref: string): Observable<any> {
        const postObj = {
            conversationId,
            supportLanguagePref: supportPref,
            clientLanguagePref: clientPref
        }
        return this.http
            .post<any[]>(
                this.envService.localEnvironment.restEndpointUrl + '/api/callcentre/translation/updateLanguagePreference',
                    postObj,
                this.httpOptions
            )
            .pipe(
                tap((result) => console.log("Posted Update Language Preference", result)),
                catchError(this.handleError("get", []))
            );
    }
    translateChatMessage(conversationId: number, messageId: number): Observable<any> {
        const postObj = {
            conversationId,
            messageId
        }
        return this.http
            .post<any[]>(
                this.envService.localEnvironment.restEndpointUrl + '/api/callcentre/translation/translateChatMessage',
                    postObj,
                this.httpOptions
            )
            .pipe(
                tap((result) => console.log("Posted Update Language Preference", result)),
                catchError(this.handleError("get", []))
            );
    }


    /**
     * Handle Http operation that failed.
     * Let the app continue.
     * @param operation - name of the operation that failed
     * @param result - optional value to return as the observable result
     */
    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {

            if (error.status === 401) {
              this.routerService.redirectToLoginPage();
            } else {
                //window.location.href = window.location.protocol + '//' + window.location.hostname + '/Login.aspx';
            }
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }

    /** Log a HeroService message with the MessageService */
    private log(message: string) {
        this.messageService.add(`Conversation Service: ${message}`);
    }

}
