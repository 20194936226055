import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DialOutType, VoiceService } from 'src/app/voice/voice-service/voice.service';
import { EnvironmentService } from '../../Services/environment.service';

interface DialOutDialogData {
  number?: string | number;
}

interface DialOutDialogResult {
  callSid?: string;
}

@Component({
  selector: 'app-dial-out-dialog',
  templateUrl: './dial-out-dialog.component.html',
  styleUrls: ['./dial-out-dialog.component.scss']
})
export class DialOutDialogComponent implements OnInit {
  get dialPadMode() {
    return this.voice.onActiveCall || !this.envService.featureEnabled.dialOut;
  }
  error: any;
  number = '';
  isPhoneNumber = false;
  conversationType: DialOutType = DialOutType.Phone;
  types = ['Phone', 'SMS'];
  @ViewChild('phoneNumber') inputElement: ElementRef;
  makingCall = false;

  dialPad = [
    { number: '1', other: '', },
    { number: '2', other: 'ABC' },
    { number: '3', other: 'DEF' },
    { number: '4', other: 'GHI' },
    { number: '5', other: 'JKL' },
    { number: '6', other: 'MNO' },
    { number: '7', other: 'PQRS' },
    { number: '8', other: 'TUV' },
    { number: '9', other: 'WXYZ' },
    { number: '*', other: '' },
    { number: '0', other: '+' },
    { number: '#', other: '' }
  ];

  constructor(
    public dialogRef: MatDialogRef<DialOutDialogComponent, DialOutDialogResult>,
    @Inject(MAT_DIALOG_DATA) public data: DialOutDialogData,
    public envService: EnvironmentService,
    private voice: VoiceService
  ) {
    if (data.number)
      this.number = `${data.number}`.replace(/[^\d\+\#]]/gi, '');
  }

  ngOnInit() { }

  dialPadPress(key: any) {
    if (this.dialPadMode) {
      this.voice.sendDigits(key.number);
      return;
    }

    const cursor = this.getCursor();
    this.number = this.number.substring(0, cursor[0]) + key.number + this.number.substring(cursor[1]);
    this.setCursor(cursor[1] + 1, cursor[1] + 1);
    this.validatePhoneNumber(this.number);
  }

  validatePhoneNumber(number: string) {
    return this.isPhoneNumber = this.voice.validateNumber(number);
  }

  removeKey() {
    const cursor = this.getCursor();

    if (cursor[0] === 0) return;
    this.number = this.number.substring(0, cursor[0] - 1) + this.number.substring(cursor[1]);
    this.setCursor(cursor[0] - 1, cursor[0] - 1);
    this.validatePhoneNumber(this.number);
  }

  setCursor(start: number, end: number) {
    setTimeout(() => {
      this.inputElement.nativeElement.focus();
      this.inputElement.nativeElement.setSelectionRange(start, end);
    });
  }

   getCursor() {
    const start = this.inputElement.nativeElement.selectionStart;
    const end = this.inputElement.nativeElement.selectionEnd;
    return [start, end];
  }

  call() {
    this.makingCall = true;
    this.voice.dialOut(this.number, this.conversationType)
      .toPromise()
      .then( resp => {
        this.makingCall = false;
        if (resp.success)
          this.dialogRef.close({ callSid: resp.callSid});
      });
  }
}
