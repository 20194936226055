import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-mobile-update-progress',
  templateUrl: './mobile-update-progress.component.html',
  styleUrls: ['./mobile-update-progress.component.scss']
})
export class MobileUpdateProgressComponent implements OnInit {

  @Input() currentStatus = '';
  @Input() currentProgress = 0;
  @Input() currentMessage = '';

  constructor() { }

  ngOnInit() {
  }

}
