import { environment } from '../../../environments/environment';
import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { Conversation, ConversationsService } from '../../Services/Data/conversations.service';
import {MAT_DIALOG_DATA} from '@angular/material';
import { EntityDataService } from '../../Services/Data/entity-data.service';
import { UserService } from '../../Services/Data/user.service';
import { Subscription } from 'rxjs';
import {CallerService } from '../../Services/Data/caller.service';
import { EnvironmentService } from '../../Services/environment.service';
import { EnvironmentVar } from '../../models/environmentvar';


@Component({
  selector: 'app-random-conversation-dialog',
  templateUrl: './random-conversation-dialog.component.html',
  styleUrls: ['./random-conversation-dialog.component.scss']
})
export class RandomConversationDialogComponent implements OnInit {
  startDate = new Date();
  endDate = new Date();
  hub: String = 'All';
  numberCalled: String = 'All';
  callMethod: String = 'All';
  onAdd = new EventEmitter();
  // hubs: any[] = [];
    localEnvironment: EnvironmentVar;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private entityDataService: EntityDataService,
  public envService: EnvironmentService
  ) {
  }

  ngOnInit() {

  }

    checkStartEnd($event) {
        if (this.endDate < this.startDate) {
            let temp = this.endDate;
            this.endDate = this.startDate;
            this.startDate = temp;
        }
        console.log('Event', $event);
    }


    GetRandomConversation() {
        console.log('Get Random Conversation');
        this.entityDataService.getRandomConversation(this.startDate, this.endDate, 'Crisis', this.hub, this.numberCalled, this.callMethod).then(item => {
            console.log('result: ', item);
            this.onAdd.emit(item);
        });
    }


}




