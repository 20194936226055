import { SignalRConnectionService, SignalRState } from '../../Services/signal-r-connection.service';
import { PushNotificationsService } from '../../Services/Notifcations/push-notifications.service';
import { environment } from '../../../environments/environment';
import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { EnvironmentService } from '../../Services/environment.service';
import { EnvironmentVar } from '../../models/environmentvar';
import { SoundContext, AudioService } from 'src/app/Services/audio.service';

@Component({
  selector: 'app-signalr-disconnect-dialog',
  templateUrl: './signalr-disconnect-dialog.component.html',
  styleUrls: ['./signalr-disconnect-dialog.component.scss']
})
export class SignalrDisconnectDialogComponent implements OnInit, OnDestroy {

//   public x: any;

  constructor(public dialogRef: MatDialogRef<SignalrDisconnectDialogComponent>,
    private pushNotificationsService: PushNotificationsService,
    private signalRConnectionService: SignalRConnectionService,
    private envService: EnvironmentService,
    private audio: AudioService,
  ) {}

  ngOnInit() {
    this.playAudioDisconnect();
  }

  ngOnDestroy(): void {
    // clearInterval(this.x);
  }

  playAudioDisconnect() {
      // tslint:disable-next-line:max-line-length
      if (this.signalRConnectionService.signalRConnectionState === SignalRState.disconnected || this.signalRConnectionService.pingConnectionProblem) {
          this.pushNotificationsService.notify(this.envService.localEnvironment.notificationTitle, 'Lost Data Connection.');
          console.log('Playing New message sound SignalR Disconnect.');
          this.audio.playAudio(SoundContext.Alert);
          setTimeout(() => {
              this.playAudioDisconnect();
          }, 15000);
      } else {
          this.close();
      }
  }

  close() {
    this.dialogRef.close(true);
  }

  reloadApp() {
    window.location.reload();
  }



}
