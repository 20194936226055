import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'conversationsFilter',
  pure: false
})
export class ConversationsFilterPipe implements PipeTransform {

    transform(items: any[], filter: {type: string, status: string | string[], ticketType?}): any {
        if (!items) {
            return items;
        }
        if (typeof filter.status === 'string')
            filter.status = [filter.status];
        // filter items array, items which match and return true will be
        // kept, false will be filtered out
        if (filter.type === 'InternalChat') {
            // determine if this is the supervisor
                // tslint:disable-next-line:max-line-length
                return items.filter(item => filter.status.includes(item.properties.conversationstatus) && item.properties.calltype === 'InternalChat').sort((a, b) => {
                    const aMax = Math.max.apply(Math, a.childEntities.map(function(o) { return o.id; }));
                    const bMax = Math.max.apply(Math, b.childEntities.map(function(o) { return o.id; }));
                    if ( aMax > bMax )
                        return -1;
                    else if (aMax < bMax)
                     return 1;
                    else 
                    return 0;
                     
                });

        } else { //normal
            if (filter.ticketType) {
              // tslint:disable-next-line:max-line-length
              return items.filter(item => filter.status.includes(item.properties.conversationstatus) && item.properties.tickettype && item.properties.tickettype === filter.ticketType && item.properties.calltype !== 'InternalChat');
            }
            // tslint:disable-next-line:max-line-length
            return items.filter(item => filter.status.includes(item.properties.conversationstatus) && item.properties.calltype !== 'InternalChat');
        }
    }

}

@Pipe({
    name: 'conversationsByUser',
    pure: false
})
export class ConversationsByUserPipe implements PipeTransform {
    transform(items: any[], filter: any): any {
        if (!items || !filter) {
            return items;
        }
        // filter items array, items which match and return true will be
        if (filter.properties.usertype === 'Supervisor')
            return items;
        // tslint:disable-next-line:max-line-length
        return items.filter(item => filter && filter.id && (!item.linkedProperties.user || (item.linkedProperties.user && item.linkedProperties.user.id === filter.id)));
    }

}


@Pipe({
    name: 'connectionTime',
    pure: false
})
export class ConversationsConnectionTimePipe implements PipeTransform {
    transform(input: any): string { // string type

        // use the

        return input + ' minutes';
    }
}


// @Pipe({
//     name: 'durationFormat',
//     pure: false
// })
// export class DurationFormatPipe implements PipeTransform {
//     transform(input: number): string { // string type
//         return input + ' minutes';
//     }
// }


@Pipe({
    name: 'resourceFilterPipe',
    pure: false
})
export class ResourceFilterPipe implements PipeTransform {
    transform(items: any[]): any {
        if (!items) {
            return items;
        }
        // filter items array, items which match and return true will be
        // tslint:disable-next-line:max-line-length
        return items.filter(item => item.show ); // filter && filter.start && filter.end && item &&  ////  && item.name && item.name >= filter.start && item.name <= filter.end
    }
}

