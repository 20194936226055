import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Observable } from 'rxjs';
import { EntityDataService } from '../../Services/Data/entity-data.service';
import { IWarpEntity } from '../../Services/models/warp-entity';

interface clientType {
  type:string,
  anonymous:boolean
  firstName?:string,
  lastName?:string
}

@Component({
  selector: 'app-new-client-dialog',
  templateUrl: './new-client-dialog.component.html',
  styleUrls: ['./new-client-dialog.component.scss']
})
export class NewClientDialogComponent implements OnInit {

  clientType = {
    CLINICAL: { id: 1998, optionName: 'Clinical' },
    EDUCATION: { id: 1999, optionName: 'Education' },
    PACES: { id: 2001, optionName: 'PACES' },
    CSART: { id: 2225, optionName: 'CSART' },
    CRISIS: { id: 2800, optionName: 'CRISIS' }
  }

  clientForm:FormGroup

  clientRequest:Observable<any>

  constructor(@Inject(MAT_DIALOG_DATA) public data:clientType,private entityDataService:EntityDataService,public dialogRef: MatDialogRef<NewClientDialogComponent>) { }

  ngOnInit() {

    const firstName = this.data.firstName ? this.data.firstName : ""
    const lastName = this.data.lastName ? this.data.lastName : ""

    this.clientForm = new FormGroup({
      firstName: new FormControl(firstName, [
        Validators.required
      ]),
      lastName: new FormControl(lastName, [
        Validators.required
      ]),


    });

  }

  handleConfirm(){

    if(!this.clientForm.valid) return


    const clientType = this.clientType[this.data.type.toUpperCase()]

    const firstname = this.clientForm.get('firstName').value

    const lastname = this.clientForm.get('lastName').value

    const client = {
      entityTypeId:627,
      properties:{
        "first name":firstname,
        "last name":lastname,
        ccasaclienttype_cfcid:clientType.id,
        ccasaclienttype:clientType.optionName,
        anonymous_cfcid:this.data.anonymous ? 736 : 737,
        mnemonic:firstname+lastname
      }

    }

    const arr = []
    arr.push(client)
    this.clientRequest = this.entityDataService.entitySyncCreate(arr)
    this.clientForm.reset()

    this.closeDialog()
    }



  closeDialog() {
    this.dialogRef.close(this.clientRequest);
  }

}
