import { Injectable, NgZone, EventEmitter, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { EnvironmentService } from 'src/app/Services/environment.service';

@Injectable({
  providedIn: "root",
})
export class RouterService {

  isMobileApp = environment.isMobileApp;
  isLocal = false;

  redirectTriggered: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private injector: Injector,
    private envService: EnvironmentService,
    private router: Router,
    private ngZone: NgZone
  ) {
    this.isLocal = this.envService.isLocalByUrl();
  }

  redirectToLoginPage(): void {
    // if we're redirecting to login, we should clear the token
    this.envService.clearTokens();
    this.redirectTriggered.next(true);

    if (this.isMobileApp)
      this.ngZone.run( () => this.router.navigate(['/mobile-dashboard']) );
    else
      this.envService.redirectToLoginPage(this.injector, _ => _);
  }
  //   if (!this.isMobileApp && !window.location.hostname.includes('localhost')) {
  //     if ( window.location.hostname.includes("ccasa") || window.location.hostname.includes("sace") ) {
  //       window.location.href = window.location.protocol + "//" +  window.location.hostname +  "/Login.aspx";
  //     } else {
  //       window.location.href = window.location.protocol + "//" +  window.location.hostname +  "/Login.aspx";
  //     }
  // } else {
  //     // window.location.href = '/mobile-login';
  //     this.ngZone.run(() => {
  //         if (this.isMobileApp)
  //           this.router.navigate(["/mobile-dashboard"]);
  //         else
  //           window.location.href = window.location.protocol + '//' + window.location.hostname + '/Login.aspx'
  //     });
  //   }
}
