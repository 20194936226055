import { Injectable } from '@angular/core';
// import { CookieService } from 'angular2-cookie/core';
import { CookieService } from 'ngx-cookie';
import { Plugins, Capacitor } from '@capacitor/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: "root",
})
export class InternalCookieService {
  isMobileApp = environment.isMobileApp;

  constructor(
    private _cookieService: CookieService,
  ) {}

  getCookie(key: string): string {
    let retVal = '';
    // return await this.getItem(key);
    if (Capacitor.getPlatform().includes('ios') || this.isMobileApp) {
      retVal = window.localStorage.getItem(key);
    } else {
      retVal = this._cookieService.get(key);
    }
    // console.log('get cookie service: =>' + retVal + '<=');
    return retVal;
  }

  setCookie(key: string, value: string): void {
    if (Capacitor.getPlatform().includes('ios') || this.isMobileApp) {
      window.localStorage.setItem(key, value);
    } else {
      var year = new Date().getTime() + 86400000 * 365;
      this._cookieService.put(key, value, {expires:  new Date(year)});
    }
  }

  setInternalStorageValue(key: string, value: string): void {
    window.localStorage.setItem(key, value);
  }

  getInternalStorageValue(key: string): string {
    let retVal = '';
    retVal = window.localStorage.getItem(key);
    return retVal;
  }

  isiOS(): boolean {
    if (Capacitor.getPlatform().includes('ios')) {
      return true;
    } else {
      return false;
    }
  }

  isWeb(): boolean {
    // console.log('this.platform is: ' + Capacitor.getPlatform());
    if (Capacitor.getPlatform().includes('web')) {
      return true;
    } else {
      return false;
    }
  }

  dropCookie(key: string) {
    this._cookieService.remove(key);
  }
}
