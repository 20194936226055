import { Injectable, Injector } from '@angular/core';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { environment as globalEnvironment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { InternalCookieService } from '../cookie.service';
import { RouterService } from './router.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { EntityDataService } from '../Data/entity-data.service';
import { EnvironmentService } from '../environment.service';
import { EnvironmentVar } from 'src/app/models/environmentvar';

export interface LoginInfo {
  username: string;
}

@Injectable()
export class AuthService {
  loggedIn: BehaviorSubject<boolean>;
  isMobileApp = globalEnvironment.isMobileApp;

  get userLoggedIn() {
    return this.loggedIn.getValue();
  }

  localEndpointForUserCheck: string;
  constructor(
    private cookieService: InternalCookieService,
    private routerService: RouterService,
    private http: HttpClient,
    private entityDataService: EntityDataService,
    private envService: EnvironmentService,
    private injector: Injector
  ) {
    if (!globalEnvironment.isMobileApp) {
      this.localEndpointForUserCheck = `${window.location.protocol}//${window.location.hostname}`;
      if ( this.envService.isLocalByUrl() ) {
        this.localEndpointForUserCheck = `http://${window.location.hostname}:56696`;
      }
    } else {
      this.localEndpointForUserCheck = this.cookieService.getCookie('lastEndpoint');
    }
    console.log('---------------------Environment Var--------------------', this.envService.localEnvironment);
    this.loggedIn = new BehaviorSubject<boolean>(false);
    this.checkLoginState();
    this.routerService.redirectTriggered.subscribe(didTrigger => {
      console.log('redirect triggered');
      if (didTrigger) {
        this.logOut();
      }
    });
  }

  async validateLogin(validateObj: {
    mfaToken: string, mfaCode: string, userName: string, password: string, rememberUsername: boolean, company: string, timeLoggedIn: string
                    }): Promise<any> {
    // console.log('validateObj: =>' + JSON.stringify(validateObj));
    if (validateObj.rememberUsername) {
      this.cookieService.setInternalStorageValue('rememberUsername', 'yes');
      this.cookieService.setInternalStorageValue('lastUsername', validateObj.userName);
      this.cookieService.setInternalStorageValue('lastCompany', validateObj.company);
    } else {
      this.cookieService.setInternalStorageValue('rememberUsername', 'no');
      this.cookieService.setInternalStorageValue('lastUsername', '');
      this.cookieService.setInternalStorageValue('lastUsername', '');
    }
    return this.http
      .post<any[]>(this.envService.getTemporaryLoginEndPointForMobile() + '/api/v2/sessions/', validateObj).toPromise();
      // .pipe(
      //   tap((response: any) => {
      //     console.log('response from login backend', response);
      //     if (response.statusText) {
      //       this.loggedIn.next(false);
      //     } else {
      //       this.cookieService.setCookie('token', response.token);
      //       this.loggedIn.next(true);
      //     }
      //   }),
      //   catchError(this.handleError("getAll", []))
      // );
  }

  setToken(token: string) {
    this.cookieService.setCookie('token', token);
  }

  hasLoggedIn() {
    this.envService.getEnviromentVariablesFromServer(this.injector).then((success: boolean) => {
      // this.cookieService.setCookie('lastEndpoint', this.envService.getTemporaryLoginEndPointForMobile());
      this.loggedIn.next(true);
    });
  }

  // async contactBackEndForUrL(companyName: string): Promise<any> {
  //   const toBeSent = {
  //     companyName: companyName
  //   };
  //   return this.http.post<any>(environment.endPointUrlToGetRestEndPointUrL + 'api/v2/subscriberdomains/', toBeSent).toPromise();
  // }

  async pretendValidate(): Promise<string> {
    return '48c647af-82cc-4780-853c-1eb2d2d6c0c9';
  }

  getLastLoginInfo(): LoginInfo {
    return { username: this.cookieService.getCookie('lastUsername') };
  }

  logOut() {
    this.envService.clearTokens();

    console.log('logged out triggered!!!');
    this.envService.destroyEnvironmentVariables();
    this.loggedIn.next(false);
  }

  checkLoginState() {
    this.isLoggedIn().subscribe(response => {
      console.log('isLoggedIn response', response);
      if (response.id) {
        this.loggedIn.next(true);
      }
    });
  }

  private isLoggedIn(): Observable<any> {
    console.log('Checking Login:', `${this.localEndpointForUserCheck}/api/rippleuser/${this.cookieService.getCookie('token')}`);
    return this.http
      .get<any[]>(
        this.localEndpointForUserCheck +
          '/api/rippleuser/' +
          this.cookieService.getCookie('token')
      )
      .pipe(
        tap((response) => {
          return response;
        }),
        catchError(this.handleError('getAll', []))
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error('Error in auth', error);

      if (error.status === 401) {
        this.routerService.redirectToLoginPage();
        this.logOut();
      }
      // TODO: send the error to remote logging infrastructure
      // console.log("error ", error);
      // console.error(error); // log to console instead
      return of(error as T);
    };
  }

}
