import { ThrowStmt } from '@angular/compiler';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { debug } from 'console';
import { Guid } from 'guid-typescript';
import { Observable } from 'rxjs';
import {

  Conversation,
} from '../../Services/Data/conversations.service';
import { EntityDataService } from '../../Services/Data/entity-data.service';
import { LinkClientServiceService } from '../../Services/Data/link-client-service.service';
import { IWarpEntity } from '../../Services/models/warp-entity';

interface dialogData {
  client:any,
  conversationId:number,
  linkAll:boolean,
  latestSession:any,
  anonymous:boolean
}

@Component({
  selector: 'app-link-client-dialog',
  templateUrl: './link-client-dialog.component.html',
  styleUrls: ['./link-client-dialog.component.scss']
})


export class LinkClientDialogComponent implements OnInit {

  linked = false
  filledForm:any
  loading = false
  linkRequest:Observable<any>

  constructor(@Inject(MAT_DIALOG_DATA) public data: dialogData,private entityDataService:EntityDataService,public dialogRef: MatDialogRef<LinkClientDialogComponent>,
  private linkClientService:LinkClientServiceService) {

  }

  ngOnInit() {

  }

    handleConfirm(){

    this.loading = true
    this.linkRequest = this.linkClientService.linkClient(this.data.conversationId,this.data.client.id,this.data.linkAll)
    this.loading = false
    this.closeDialog()
    }



  closeDialog() {
    this.dialogRef.close(this.linkRequest);
  }


}
