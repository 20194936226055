import { Call } from '@twilio/voice-sdk';
import { VoiceNotificationComponent } from 'src/app/voice';

export type CallStatus = 'ringing' | 'active' | 'declined' | 'ended' | 'postponed' | 'missed';

const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));


export interface VoiceNotificationContainer {
  calls: CallData[];
  callActive: boolean;
  hasPreviouslyMissed: (o: CallData) => boolean;
  log: (...messages: any[]) => void;
}

export class CallHelper {
  caller: string;

  constructor(public call: Call) {
    this.caller = this.param('conversationId') || call.parameters.From || 'Unknown';
  }

  isNormal    = () => !( this.isBarge() || this.isInputTest() || this.isFake() || this.isDialOut() );
  isBarge     = () => this.hasParam('isBarge', false);
  isDialOut   = () => this.hasParam('isDialOut', false);
  isFake      = () => this.hasParam('isFake', false);
  isInputTest = () => this.hasParam('isInputTest', false);

  allowMoreTime = () => this.hasParam('allowMoreTime', false);

  callTitle() {
    if (this.isBarge())
      return 'Are you sure you would like to join this call?';
    if (this.isDialOut())
      return 'Dial Out';
    if (this.isFake())
      return 'Incoming Example Call';
    if (this.isInputTest())
      return 'Start Input Test?';
    return 'Incoming Call';
  }

  callName() {
    if (this.isFake())
      return 'Example Call';

    return (this.isBarge() && this.param('from'))
        || (this.isDialOut() && this.param('to'))
        || this.caller;
  }

  isWithoutName() {
    return (this.isBarge() && !this.hasParam('from'))
        || (this.isDialOut() && !this.hasParam('to'));
  }

  private hasParam(name: string, explicitTrue = false) {
    if (explicitTrue)
      return this.param(name).toLowerCase() === 'true';
    else
      return this.call.customParameters.has(name);
  }

  private param(name: string) {
    return this.call.customParameters.get(name);
  }
}

export class CallData extends CallHelper {
  hide: boolean;
  remove: boolean;
  showLines: boolean;
  status: CallStatus;
  interval?: NodeJS.Timer;

  constructor(call: Call, private container: VoiceNotificationContainer, show: boolean = true) {
    super(call);

    this.hide = true;
    this.remove = false;
    this.status = 'ringing';
    this.showLines = false;

    this.call.on('accept', this.makeActive.bind(this));
    this.call.on('disconnect', _ => this.dispose('ended'));

    if (show) this.show();
  }

  public show() {
    this.hide = true;
    setTimeout(_ => this.hide = false, 1);
    return this;
  }

  public dispose(status?: CallStatus): Call {
    if (this.status === 'active')
      this.container.callActive = false;

    if (status)
      this.status = status;

    if (this.interval)
      clearInterval(this.interval);

    if (status !== 'missed' || this.container.hasPreviouslyMissed(this))
      this.animateClose(status);

    return this.call;
  }

  animateClose(status?: CallStatus) {
    const transition = 500;
    const delay = 2000;

    wait(status ? delay : 0)
        .then( _ => (this.hide = true, wait(transition)))
        .then( _ => (this.remove = true, wait(transition)))
        .then( _ => this._dispose(this));
  }

  private _dispose(call: CallData) {
    const index = this.container.calls.indexOf(call);
    if (index >= 0)
      this.container.calls.splice(index, 1);
  }

  makeActive() {
    this.status = 'active';
    this.container.callActive = true;
    this.interval = setInterval(_ => {
      this.showLines = !this.showLines;
    }, 1000);

    return this.call;
  }

  get callSid() { return this.call.parameters.CallSid; }

  isActive(frame?: number) {
    return this.status === 'active' && (frame === undefined || ([this.showLines, !this.showLines])[frame % 2]);
  }

  isRinging() {
    return this.status === 'ringing' && ! this.isPaused();
  }

  isPaused() {
    return this.container.callActive && this.status !== 'active' && !this.isClosed();
  }

  isClosed() {
    return ['declined', 'ended', 'postponed', 'missed' ].includes(this.status);
  }

  statusClass() {
    if (this.isPaused())
      return 'paused';
    return this.status;
  }
}
