import { Component, OnInit, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'app-user-profile-avatar',
  templateUrl: './user-profile-avatar.component.html',
  styleUrls: ['./user-profile-avatar.component.scss']
})

export class UserProfileAvatarComponent implements OnInit {

 @HostBinding('class.uprofile-avatar') avatar:Boolean = true;
 @HostBinding('class.rounded-circle') circle:Boolean = true;


  constructor() { }

  @Input() user: any;

  ngOnInit() {
  }

}
