import { enableProdMode, LOCALE_ID, TRANSLATIONS, TRANSLATIONS_FORMAT } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
// import { AppModuleAlone } from './app/app-alone.module';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
  if (window) {
    // window.console.log = function () { };
  }
}

import { lang, getTranslations } from './i18n';

platformBrowserDynamic().bootstrapModule(AppModule, {
  providers: [
    {provide: LOCALE_ID, useValue: lang },
    {provide: TRANSLATIONS, useValue: getTranslations() },
    {provide: TRANSLATIONS_FORMAT, useValue: 'xlf' },
  ]
})
//  platformBrowserDynamic().bootstrapModule(AppModuleAlone)
 .catch(err => console.log(err));
