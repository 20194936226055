import { ShiftBlock } from './shiftBlock';

export class ShiftWrapper {


    constructor(
        public shift: any,
        public shiftBlockBoth: ShiftBlock,
        public shiftBlockPhone: ShiftBlock,
        public shiftBlockSMS: ShiftBlock,
    )
    {
        
    }

}