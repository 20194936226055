import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { interval, Observable, Subscription } from 'rxjs';
import { bufferCount, map} from 'rxjs/operators';

function hexToRgb(hex: string): [number, number, number] {
  hex = hex.replace('#', '');
  if (hex.length !== 6 && hex.length !== 8) // rgb hex or rgba hex
    return [0, 0, 0];

  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);
  return [r, g, b];
}

// colorChannelA and colorChannelB are ints ranging from 0 to 255
function colorChannelMixer(colorChannelA: number, colorChannelB: number, amountToMix: number) {
  const channelA = colorChannelA * amountToMix;
  const channelB = colorChannelB * ( 1 - amountToMix);
  return Math.floor(channelA + channelB);
}

// rgbA and rgbB are arrays, amountToMix ranges from 0.0 to 1.0
// example (red): rgbA = [255,0,0]
function colorMixer(rgbA: [number, number, number], rgbB: [number, number, number], amountToMix: number) {
  const r = colorChannelMixer(rgbA[0], rgbB[0], amountToMix);
  const g = colorChannelMixer(rgbA[1], rgbB[1], amountToMix);
  const b = colorChannelMixer(rgbA[2], rgbB[2], amountToMix);
  return `rgb(${r},${g},${b})`;
}

@Component({
  selector: 'app-audio-feedback',
  templateUrl: './audio-feedback.component.html',
  styleUrls: ['./audio-feedback.component.scss']
})
export class AudioFeedbackComponent implements AfterViewInit, OnDestroy {
  @Input()
  src: Observable<number>; // ∈ [0, 1]

  @Input()
  pause = true;

  @Input()
  width = 250;

  @Input()
  strokeWidth = 2;

  @Input()
  height = 100;

  @ViewChild('canvas')
  canvas: ElementRef<HTMLCanvasElement>;
  ctx: CanvasRenderingContext2D;

  @Input()
  resolution: 'low' | 'medium' | 'high' =  'high';

  @Output()
  clickInfo = new EventEmitter<any>();

  maxLag: number;
  bufferLength = 5;
  bufMultiplier = 1; // should be odd
  cache: number[];
  flip = 1;
  t = 0;

  fade = false;
  smoothing = true;
  noSound = false;
  animation = 0;
  animations = [];
  drawPoints = false;
  beforeStyle = `width: ${this.width}px; height: ${this.height}px`;

  sub: Subscription;

  constructor() { }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngAfterViewInit(): void {
    let fr = 60;
    switch (this.resolution) {
      case 'low': this.bufMultiplier = 3; fr = 24; break;
      case 'medium': this.bufMultiplier = 3; fr = 40; break;
      case 'high': this.bufMultiplier = 5; fr = 60; break;
    }
    const takeEveryX = fr > 30 ? 2 : 5;
    this.bufferLength *= this.bufMultiplier;
    this.maxLag = this.bufferLength - 1;
    this.cache = Array.from({length: this.bufferLength + this.maxLag + 1}).map(_ => 0);

    this.ctx = this.canvas.nativeElement.getContext('2d');

    this.animations = [this.animate, this.animate1, this.animate2, this.animate3].map( a => a.bind(this));
    this.sub = interval(1000 / fr)
      .subscribe( _ => this.pause || requestAnimationFrame(this.animations[this.animation] || (() => {})) );

    if (this.src) {
      this.sub.add(
        this.src.pipe(bufferCount(takeEveryX), map( a => a[0])).subscribe(n => {
          const value = n > 0.05 ? n : 0; // cut off the bottom (noisy)
          this.cache.pop();
          this.cache.unshift(value);
        }));
      this.sub.add(
        this.src.pipe(
          bufferCount(15))
        .subscribe(
          lastYValues => !this.pause && (this.noSound = lastYValues.every(v => v === 0))));
    }
  }

  // Only allow one mic test animation
  // nextAnimation() {
  //   this.animation = (this.animation + 1) % (this.animations.length || 1);
  // }

  clear() {
    this.cache.fill(0);
    this.ctx.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
  }

  // waves bb
   animate() {
    const edgeBuffer = 20; // px
    this.t += 0.01;
    const width = this.canvas.nativeElement.width + edgeBuffer;
    const height = this.canvas.nativeElement.height;
    const bumpiness = 3;
    const lag1 = Math.cos(this.t * Math.PI);
    const lag2 = Math.sin(this.t * Math.PI);
    const lag = [
      2 + lag1 * this.bufMultiplier,
      5 + lag2 * this.bufMultiplier,
      7 - lag1 * this.bufMultiplier,
      3 - lag2 * this.bufMultiplier,
    ].map( n => n > this.maxLag ? this.maxLag : n);
    let index = 0;
    const nxt = () => ++index;
    const octaves = [
      {
        // tslint:disable-next-line: max-line-length
        fill: false, width: this.strokeWidth + 1 * lag2, color: '#4285f4', i: 0,     lag: 0,          range: [0, this.bufferLength],             y: (n, i) => +Math.sin(i * bumpiness * Math.PI / this.bufferLength) * n
      },
      {
        // tslint:disable-next-line: max-line-length
        fill: false, width: this.strokeWidth + 1 * lag1, color: '#659df7', i: nxt(), lag: lag[index], range: [index, this.bufferLength + index], y: (n, i) => +Math.cos(i * bumpiness * Math.PI / this.bufferLength) * n
      },
      {
        // tslint:disable-next-line: max-line-length
        fill: false, width: this.strokeWidth + 1 * lag2, color: '#11bcbf', i: nxt(), lag: lag[index], range: [index, this.bufferLength + index], y: (n, i) => +Math.sin(i * bumpiness * Math.PI / this.bufferLength) * n
      },
      {
        // tslint:disable-next-line: max-line-length
        fill: false, width: this.strokeWidth + 1 * lag2, color: '#2679ff', i: nxt(), lag: lag[index], range: [index, this.bufferLength + index], y: (n, i) => +Math.sin(i * bumpiness * Math.PI / this.bufferLength) * n
      },
      {
        // tslint:disable-next-line: max-line-length
        fill: false, width: this.strokeWidth + 1 * lag1, color: '#40f4f7', i: nxt(), lag: lag[index], range: [index, this.bufferLength + index], y: (n, i) => -Math.sin(i * bumpiness * Math.PI / this.bufferLength) * n
      },
    ];

    interface Point { x: number; y: number; newVal: number; }
    const t = 1.5; // tension
    // x in = i ∈ [index, buffLen + index], spans bufLength
    // map x to [-edgeBuf/2, width + edgeBuf/2], spans width + make it backwards
    const pSmoothed = (i: number, o, oldVal?: number) => {
      const smoothing = oldVal === undefined ? 0 : 0.65;
      const newVal = (oldVal || 0) * smoothing + this.cache[i] * .7 * height * (1 - smoothing);
      return {
        x: width * (1 - (i - o.i) / this.bufferLength) - edgeBuffer,
        y: height / 2 + o.y(newVal, i + o.lag),
        newVal
      } as Point;
   };
    const pNotSmoothed = (i: number, o) => ({
        x: width * (1 - (i - o.i) / this.bufferLength) - edgeBuffer,
        y: height / 2 + o.y(this.cache[i] * .7 * height, i + o.lag)
      } as Point
    );

    const p = this.smoothing ? pSmoothed : pNotSmoothed;

    const circles = (...points: Point[]) => points.forEach( point => this.ctx.arc(point.x, point.y, 2, 0, 2 * Math.PI, false));
    if (this.fade) {
      this.ctx.fillStyle = 'rgba(255, 255, 255, 0.25)';
      this.ctx.fillRect(0, 0, width, height);
    } else
      this.ctx.clearRect(0, 0, width, height);

    for (const octave of octaves) {
      this.ctx.beginPath();
      if (octave.fill) {
        this.ctx.fillStyle = octave.color;
        this.ctx.lineWidth = 0;
      } else {
        this.ctx.fillStyle = 'transparent';
        this.ctx.strokeStyle = octave.color;
        this.ctx.lineWidth = octave.width;
      }

      const p00 = p(octave.range[0], octave);
      const points = [p00];
      for (let i = octave.range[0], j = 0; i < octave.range[1] + 1; i++, j++)
        points.push(p(i, octave, points[j].newVal));

      if (octave.fill) {
        this.ctx.moveTo(p00.x, height / 2);
        this.ctx.lineTo(p00.x, p00.y);
      } else
        this.ctx.moveTo(p00.x, p00.y);

      for (let i = octave.range[0], j = 0; i < octave.range[1]; i++, j++) {
        const p0 = j > 0 ? points[j - 1] : p00;               // prev or first
        const p1 = points[j];                                 // curr
        const p2 = points[j + 1];                             // next
        const p3 = j >= octave.range[1] ? points[j + 2] : p2; // (next next) or next
        if (this.drawPoints) circles(p1);

        // 1. take one 6th of the direction from prev to next (ignoring this curr)
        // and add to where we are
        // 2. take one 6th of the direction from next next to curr (ignoring next)
        // and add to where we are (next)

        // use those as handles (between curr and next), and end at next
        this.ctx.bezierCurveTo(
          p1.x + (p2.x - p0.x) / 6 * t,
          p1.y + (p2.y - p0.y) / 6 * t,
          p2.x - (p3.x - p1.x) / 6 * t,
          p2.y - (p3.y - p1.y) / 6 * t,
          p2.x, p2.y
          );
      }
      if (octave.fill) {
        const p11 = p(octave.range[1] - 1, octave);
        this.ctx.lineTo(p11.x, height / 2);
        this.ctx.fill();
      } else
        this.ctx.stroke();
    }
    (this.ctx as any).filter = 'blur(0.5px)';
    // performance.mark('audio-feedback-animate-frame-end');
    // performance.measure('audioFeedbackMeasure', 'audio-feedback-animate-frame-start', 'audio-feedback-animate-frame-end');
  }

  // tslint:disable-next-line: member-ordering
  lastCircles: number[];
  // circles
  animate1() {
    // performance.mark('audio-feedback-animate-frame-start');

    const edgeBuffer = 20; // px
    this.t += 0.01;
    const width = this.canvas.nativeElement.width;
    const height = this.canvas.nativeElement.height;
    const x = width / 2, y = height / 2;
    const lag1 = Math.cos(this.t * Math.PI);
    const lag2 = Math.sin(this.t * Math.PI);
    const octaves = [
      { fill: false, width: this.strokeWidth + lag2, color: '#4285f4', i: 0, height: 1 },
      { fill: false, width: this.strokeWidth + lag1, color: '#659df7', i: 1, height: 1 },
      { fill: false, width: this.strokeWidth + lag2, color: '#11bcbf', i: 2, height: 0.5 + lag1 * 0.5 },
      { fill: false, width: this.strokeWidth + lag2, color: '#2679ff', i: 3, height: 0.75 + lag1 * 0.25},
      { fill: false, width: this.strokeWidth + lag1, color: '#40f4f7', i: 4, height: 0.5 + lag2 * 0.5},
      { fill: false, width: this.strokeWidth + lag1, color: '#11bcbf', i: 4, height: 0.75 + lag2 * 0.25},
      { fill: false, width: this.strokeWidth + lag1, color: '#659df7', i: 4, height: 0.9},
    ];

    this.ctx.clearRect(0, 0, width, height);

    if (this.smoothing && !this.lastCircles) this.lastCircles = octaves.map(_ => 0);
    const newCircles = [];
    for (const octave of octaves) {
      this.ctx.beginPath();
      if (octave.fill) {
        this.ctx.fillStyle = octave.color;
        this.ctx.lineWidth = 0;
      } else {
        this.ctx.fillStyle = 'transparent';
        this.ctx.strokeStyle = octave.color;
        this.ctx.lineWidth = octave.width;
      }

      const _r = (this.cache[octave.i] * 0.7 * height * octave.height) + 0.05;
      const smoothing = 0.65;

      const r = this.smoothing ? _r * smoothing + this.lastCircles[octave.i] * (1 - smoothing) : _r;
      newCircles.push(r);

      this.ctx.arc(x, y, Math.max(1, r), 0, 2 * Math.PI, false);

      if (octave.fill)
        this.ctx.fill();
      else
        this.ctx.stroke();
    }

    this.lastCircles = newCircles;
    (this.ctx as any).filter = 'blur(0.5px)';
    // performance.mark('audio-feedback-animate-frame-end');
    // performance.measure('audioFeedbackMeasure', 'audio-feedback-animate-frame-start', 'audio-feedback-animate-frame-end');
  }

  // just a line
  animate2() {
    this.t += 0.001;
    const width = this.canvas.nativeElement.width;
    const height = this.canvas.nativeElement.height;
    const x = width / 2, y = height / 2;
    const lag1 = Math.cos(this.t * Math.PI);
    const lag2 = Math.sin(this.t * Math.PI);
    let index = 0;
    const nxt = () => ++index;
    const octave = ([
      { fill: true, width: this.strokeWidth + lag2, color: '#4285f4', i: 0 },
      { fill: false, width: this.strokeWidth + lag1, color: '#659df7', i: nxt() },
      { fill: false, width: this.strokeWidth + lag2, color: '#11bcbf', i: nxt() },
      { fill: false, width: this.strokeWidth + lag2, color: '#2679ff', i: nxt() },
      { fill: false, width: this.strokeWidth + lag1, color: '#40f4f7', i: nxt() },
    ])[0];

    this.ctx.clearRect(0, 0, width, height);

    this.ctx.fillStyle = octave.color;
    this.ctx.lineWidth = 0;

    const w = Math.max(2, this.cache[0] * width);
    const h = 2; // px

    this.ctx.fillRect(x - (w >> 1), y - h, w, h * 2);

    (this.ctx as any).filter = 'blur(0.5px)';
  }

  // led strip type thing
  animate3() {
    const width = this.canvas.nativeElement.width;
    const height = this.canvas.nativeElement.height;

    const numRectsPerSide = 5;
    const edgeSpacing = 5; // px
    const numRects = numRectsPerSide * 2 + 1;
    const centerSpacing = Math.round(width / numRects);
    const rectWidth = centerSpacing - edgeSpacing;
    const rectHeight = 30; // px
    const rectEdgeOffset = rectWidth / 2;
    this.t += 0.001;
    const x = width / 2, y = height / 2;
    const lag1 = Math.cos(this.t * Math.PI);
    const lag2 = Math.sin(this.t * Math.PI);
    let index = 0;
    const nxt = () => ++index;
    //  blue ->  orange -> red
    //  #4285f4, #eb9630, #ff4081);
    const octaves = [
      { color: '#4285f4', cutoff: -1, i: 0 },
      { color: '#ff8c00', cutoff: 0.66, i: nxt() },
      { color: '#ff4081', cutoff: 0.99, i: nxt() },
    ].reverse();

    this.ctx.clearRect(0, 0, width, height);

    this.ctx.lineWidth = 1;

    const vol = this.cache[0] * width / 2;

    for (let i = 0; i <= numRectsPerSide; i++) {
      this.ctx.beginPath();
      const offset = i * centerSpacing;
      this.ctx.rect(x + offset - rectEdgeOffset, y - (rectHeight / 2), rectWidth, rectHeight);
      if (i !== 0)
        this.ctx.rect(x - offset - rectEdgeOffset, y - (rectHeight / 2), rectWidth, rectHeight);

      const octave = octaves.find(o => (i + 1) / (numRectsPerSide + 1) >= o.cutoff);
      this.ctx.strokeStyle = octave.color;

      let opacity = vol > offset ? 1 : (vol % centerSpacing) / centerSpacing;
      if (vol < offset - centerSpacing)
        opacity = 0;

      if (opacity > 0.02) {
        this.ctx.fillStyle = colorMixer(hexToRgb(octave.color), [255, 255, 255], opacity);
        this.ctx.fill();
      }
      this.ctx.stroke();
    }

    (this.ctx as any).filter = 'blur(0.5px)';
  }

  plot(fn: (number) => number) {
    const padding = 20;
    const width   = this.canvas.nativeElement.width;
    const height  = this.canvas.nativeElement.height;

    const midWidth = width / 2;
    const midHeight = height / 2;

    const _spans = [ width - (padding * 2), height - (padding * 2)];
    const span = Math.min(..._spans);

    const domain = {
      x: [midWidth - span / 2, midWidth + span / 2],
      y: [midHeight - span / 2, midHeight + span / 2],
    };

    this.ctx.fillStyle = '#fff';
    this.ctx.fillRect(0, 0, width, height);

    this.ctx.strokeStyle = 'black';
    this.ctx.lineWidth = 1;
    this.ctx.strokeRect(domain.x[0], domain.y[0], domain.x[1] - domain.x[0], domain.y[1] - domain.y[0]);

    const x = (n) => domain.x[0] + (n * span);
    const y = (n) => domain.y[1] - (fn(n) * span);

    this.ctx.moveTo(x(0), y(0));
    for (const t of Array.from({length: 1000}).map((_, i) => (i + 1) / 1000)) {
      this.ctx.lineTo(x(t), y(t));
    }
    this.ctx.stroke();
  }
}
