import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EntityDataService } from '../../Services/Data/entity-data.service';
import {Router} from '@angular/router';
import { UserService } from '../../Services/Data/user.service';
import { EmailService } from '../../Services/email.service';
import * as moment from 'moment';
import { Subscription } from 'rxjs';



@Component({
  selector: 'app-shift-replacement-confirm',
  templateUrl: './shift-replacement-confirm.component.html',
  styleUrls: ['./shift-replacement-confirm.component.scss']
})
export class ShiftReplacementConfirmComponent implements OnInit {

  reservationGuid: string;
  reservation: any;
  currentUser: any;
  currentReserver: string;
  dateString: string;
  businessUnit: string;
  error: any;
  userSubscription: Subscription;

  constructor(private route: ActivatedRoute,
    public entityDataService: EntityDataService,
    private router: Router,
    public userService: UserService,
      public emailService: EmailService) {
      this.reservationGuid = this.route.snapshot.paramMap.get('reservationGuid');
      this.userSubscription = userService.currentUser$.subscribe(user => {
          if (user == null) {
            let subscribable = this.userService.get();
            if (subscribable) {
              subscribable.subscribe(item => { this.currentUser = item; });
            }
          } else {
            this.currentUser = user;
          }
      });
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
      //Called once, before the instance is destroyed.
      //Add 'implements OnDestroy' to the class.
      this.userSubscription.unsubscribe();
  }


  Login() {
    let subscribable = this.userService.get();
    if (subscribable) {
      subscribable.subscribe(user => { this.currentUser = user; });
    }
  }

  findCurrentReserver() {
    if (this.reservation.properties && this.reservation.properties.shiftphonereservation !== '') {
      this.currentReserver = this.reservation.properties.shiftphonereservation;
    }

    if (this.reservation.properties && this.reservation.properties.shiftsmsreservation !== '') {
      this.currentReserver = this.reservation.properties.shiftsmsreservation;
    }
  }

  getReservation(): Promise<Boolean> {
    const retVal: Promise<Boolean> = new Promise<Boolean>((resolve, reject) => {
      this.entityDataService.getByGuid(this.reservationGuid).subscribe(result => {
        if (result && result.length !== 0) {
          this.reservation = result;
          console.log(this.reservation);
          this.currentReserver = '';
          this.findCurrentReserver();
          this.dateString = moment(new Date(this.reservation.properties.fromdatetime)).format('dddd, MMMM Do, YYYY') +
          ' from ' + moment(new Date(this.reservation.properties.fromdatetime)).format('h:mm A') +
          ' to ' + moment(new Date(this.reservation.properties.enddatetime)).format('h:mm A');
          if (this.reservation.linkedProperties && this.reservation.linkedProperties.shiftgroup) {
            this.entityDataService.get(this.reservation.linkedProperties.shiftgroup.id).subscribe(sg => {
              this.businessUnit = sg.properties.schedule;
              resolve(true);
            });
          }
        } else {
          this.error = {
            message: 'The reservation has been deleted. '
          };
        }

      });
    });

    return retVal;

  }

  accept() {
    this.getReservation().then(success => {
      if (this.reservation.properties.prevreserver && this.reservation.properties.prevreserver === this.currentReserver) {
        const linkedProperties = this.createLinkedProperties(this.currentUser.id);
        this.checkSimultaneous(this.reservation, this.currentUser).then(result => {
          console.log(result);
          if (!result) {
            this.entityDataService.entitySyncUpdate([{
              id: this.reservation.id,
              linkedProperties
            }]).subscribe (reservationArr => {
              console.log(reservationArr);
              this.sendEmail();
              this.router.navigate(['/scheduling']);
            });
          } else {
            this.error = {
              message: 'Time conflict. Sorry you cannot accept this shift replacement as you already have a shift scheduled that conflicts.'
            };
          }
        });
      } else {
        this.error = {
          message: 'Already Accepted.  This shift has already been accepted by another user. '
        };
      }
    });
  }

  createLinkedProperties(reserverId: number) {
    const linkedProperties = this.reservation['linkedProperties'];
    if (linkedProperties['shiftphonereservation'] && linkedProperties['shiftsmsreservation']) {
      linkedProperties['shiftphonereservation'] = { name: '', id: reserverId };
      linkedProperties['shiftsmsreservation'] = { name: '', id: reserverId };
    } else if (linkedProperties['shiftphonereservation']) {
      linkedProperties['shiftphonereservation'] = { name: '', id: reserverId };
      linkedProperties['shiftsmsreservation'] = { name: '', id: NaN };
    } else if (linkedProperties['shiftsmsreservation']) {
      linkedProperties['shiftphonereservation'] = { name: '', id: NaN };
      linkedProperties['shiftsmsreservation'] = { name: '', id: reserverId };
    }
    return linkedProperties;
  }

  sendEmail() {
    this.emailService.sendFindShiftReplacementEmail(this.buildSendObject()).subscribe(result => {
      console.log('success');
    });
  }

  buildSendObject(): any {
    const retVal = {
      title: this.businessUnit + 'Shift Replacement Found',
      start: null,
      end: null,
      to: this.reservation.properties.prevreserver,
      body: '<p>A replacement has been found for the shift on ' + this.dateString + ' \
      <p>Thank you,</p>\
      <p>' + this.businessUnit + '</p><br/>',
      hasPhone: this.reservation.properties.shiftphonereservation !== '',
      hasSMS: this.reservation.properties.shiftsmsreservation !== '',
      allStaff: null,
      allVolunteers: null,
      rolesAllowed: null,
      reservationId: this.reservation.id
    };
    return retVal;
  }

  leave() {
    this.router.navigate(['/scheduling']);
  }

  checkSimultaneous (reservation: any, reserver: any): Promise<boolean> {
    const retVal: Promise<boolean> = new Promise<boolean>((resolve, reject) => {
      const date = reservation.properties.date;
      const start = new Date(reservation.properties.fromdatetime);
      const end = new Date(reservation.properties.enddatetime);
      const endDate = moment(new Date(date)).add(1, 'd').format('M/D/YYYY');
      //this.entityDataService.getAllWithFilter(613, `date>=${date},date<=${endDate}`)
      this.entityDataService.getPageAll(613, 0, 9999999, '', `date>=${date},date<=${endDate}`, '').subscribe(reservations => {
        console.log(reservations);
        reservations.data.forEach(r => {
          if (r.properties && (r.properties.shiftphonereservation === reserver.name ||
            r.properties.shiftsmsreservation === reserver.name)) {
              if (r.properties.shiftphonereservation !== '' && reservation.properties.shiftphonereservation !== '' ||
              r.properties.shiftsmsreservation !== '' && reservation.properties.shiftsmsreservation !== '') {
                const rstart = new Date(r.properties.fromdatetime);
                const rend = new Date(r.properties.enddatetime);
                if (start.getHours() >= rstart.getHours() && start.getHours() < rend.getHours() ||
                start.getHours() <= rstart.getHours() && end.getHours() >= rend.getHours() ||
                end.getHours() > rstart.getHours() && end.getHours() <= rend.getHours()) {
                  resolve(true);
                }
              }
          }
        });
        resolve(false);
      });
    });
    return retVal;
  }

}
