import { SettokenComponent } from './settoken/settoken.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ShiftReplacementConfirmComponent } from './schedule/shift-replacement-confirm/shift-replacement-confirm.component';
import { DiagnosticsComponent } from './diagnostics/diagnostics.component';
import { MobileLoginComponent } from './mobile-apps/mobile-login/mobile-login.component';
import { environment } from './../environments/environment';
import { VibesComponent, TroubleshootAudioComponent } from 'src/app/voice';

//todo: IzzyIonic Merge

// old non-lazy loaded imports
// import { DashboardComponent } from './dashboard/dashboard.component';
// import { ConversationReportComponent } from './conversation-report/conversation-report.component';
// import { SchedulingComponent } from './schedule/scheduling/scheduling.component';
// import { SchedulingMasterComponent } from './schedule/scheduling-master/scheduling-master.component';
// import { SchedulingMonthlyComponent } from './schedule/scheduling-monthly/scheduling-monthly.component';
// import { CallsComponent } from './reports/calls/calls.component';
// import { VolunteerComponent } from './reports/volunteer/volunteer.component';
// import { ResourceDashboardComponent } from './resource-dashboard/resource-dashboard.component';
// import { MobileConversationTabComponent } from './mobile-conversation-tab/mobile-conversation-tab.component';

const routes: Routes = [
  { path: 'dashboard',
    // loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
    loadChildren: './dashboard/dashboard.module#DashboardModule'
  },
  { path: 'dashboard/:conversationID',
    // loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
    loadChildren: './dashboard/dashboard.module#DashboardModule'
  },
  { path: 'token/:tokenGuid', component: SettokenComponent},
  { path: 'conversation-report',
    // loadChildren: () => import('./conversation-report/conversation-report.module').then(m => m.ConversationReportModule)
    loadChildren: './conversation-report/conversation-report.module#ConversationReportModule'
  },
  { path: 'volunteerreport',
    // loadChildren: () => import('./reports/volunteer/volunteer.module').then(m => m.VolunteerModule)
    loadChildren: './reports/volunteer/volunteer.module#VolunteerModule'
  },
  { path: 'callreport',
    // loadChildren: () => import('./reports/calls/calls.module').then(m => m.CallsModule)
    loadChildren: './reports/calls/calls.module#CallsModule'
  },
  { path: 'scheduling',
    // loadChildren: () => import('./schedule/scheduling/scheduling.module').then(m => m.SchedulingModule)
    loadChildren: './schedule/scheduling/scheduling.module#SchedulingModule'
  },
  { path: 'reserved',
    // loadChildren: () => import('./schedule/scheduling/scheduling.module').then(m => m.SchedulingModule)
    loadChildren: './schedule/reserved/reservedscheduling.module#ReservedSchedulingModule'
  },
  { path: 'diagnostics',
    component: DiagnosticsComponent
  },
  { path: 'troubleshoot-audio', component: TroubleshootAudioComponent },
  { path: 'vibes', component: VibesComponent },
  { path: 'resource-dashboard',
    // loadChildren: () => import('./resource-dashboard/resource-dashboard.module').then(m => m.ResourceDashboardModule)
    loadChildren: './resource-dashboard/resource-dashboard.module#ResourceDashboardModule'
  },
  { path: 'scheduling-master',
    // loadChildren: () => import('./schedule/scheduling-master/scheduling-master.module').then(m => m.SchedulingMasterModule)
    loadChildren: './schedule/scheduling-master/scheduling-master.module#SchedulingMasterModule'
  },
  { path: 'scheduling-monthly',
    // loadChildren: () => import('./schedule/scheduling-monthly/scheduling-monthly.module').then(m => m.SchedulingMonthlyModule)
    loadChildren: './schedule/scheduling-monthly/scheduling-monthly.module#SchedulingMonthlyModule'
  },
  { path: 'shiftReplacement/:reservationGuid', component: ShiftReplacementConfirmComponent},

  { path: 'mobile-conversation',
    // loadChildren: () => import('./mobile-apps/mobile-conversation-tab/mobile-conversation-tab.module').then(m => m.MobileConversationTabModule),
    loadChildren: './mobile-apps/mobile-conversation-tab/mobile-conversation-tab.module#MobileConversationTabModule'
  },
  { path: 'mobile-conversation/:conversationID',
    // loadChildren: () => import('./mobile-apps/mobile-conversation-tab/mobile-conversation-tab.module').then(m => m.MobileConversationTabModule)
    loadChildren: './mobile-apps/mobile-conversation-tab/mobile-conversation-tab.module#MobileConversationTabModule'
  },
  { path: 'activetimereport',
    // loadChildren: () => import('./reports/active-time-report/active-time-report.module').then(m => m.ActiveTimeReportModule)
    loadChildren: './reports/active-time-report/active-time-report.module#ActiveTimeReportModule'
  },
  { path: 'referralreport',
    // loadChildren: () => import('./reports/active-time-report/active-time-report.module').then(m => m.ActiveTimeReportModule)
    loadChildren: './reports/organization-referral-report/organization-referral-report.module#OrganizationReferralReportModule'
  },
  { path: 'kpireport',
    // loadChildren: () => import('./reports/kpi/kpi.module').then(m => m.KPIModule)
    loadChildren: './reports/kpi/kpi.module#KPIModule'
  },
  { path: 'mobile-dashboard',
    // loadChildren: () => import('./mobile-apps/mobile-dashboard/mobile-dashboard.module').then(m => m.MobileDashboardModule),
    loadChildren: './mobile-apps/mobile-dashboard/mobile-dashboard.module#MobileDashboardModule',
    data: { preload: true }
  },
  { path: '', redirectTo: environment.isMobileApp ? '/mobile-dashboard' : '/dashboard', pathMatch: 'full' },
  { path: '**', redirectTo: environment.isMobileApp ? '/mobile-dashboard' : '/dashboard', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', anchorScrolling: 'enabled' }) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule { }
