// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export let environment = {
    mobileEndpoints: {
        // these are used in mobile-login.component.ts and app.component.ts, search for environment.mobileEndpoints should show them
        resourceEndpointUrl: 'https://izzyvoip.ngrok.io',
        restEndpointUrl: 'https://izzyvoip.ngrok.io', //
        localEndpointUrl: 'https://izzyvoip.ngrok.io', // for logging into the login screen with "localhost" as company name
        enableMobileUpdate: true, // set to false for development
        mobileUpdateChannel: 'production', // set to 'development' for development, '
        mobileUpdateAppID: 'bb4c116e'
    },
    isBeta: false, // allows the use of experimental features
    production: true,
    isMobileApp: true,
};
