import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserService } from '../Services/Data/user.service';
import { UserProfileAvatarComponent } from '../user-profile-avatar/user-profile-avatar.component';
import { MatDialog, MatDialogRef } from '@angular/material';
import { InactiveDialogComponent } from '../dialogs/inactive-dialog/inactive-dialog.component';
import { environment } from './../../environments/environment';
import { AuthService } from '../Services/Hubs/auth.service';
import { EnvironmentService } from 'src/app/Services/environment.service';

@Component({
  selector: "app-user-profile-menu-header",
  templateUrl: "./user-profile-menu-header.component.html",
  styleUrls: ["./user-profile-menu-header.component.scss"],
})
export class UserProfileMenuHeaderComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  user: any = null;
  active: boolean = false;
  inActiveDialogRef: MatDialogRef<InactiveDialogComponent>;
  isMobileApp = environment.isMobileApp;
  isBeta = environment.isBeta;

  @Input() mainDrawer: any;

  constructor(
    private userService: UserService,
    public inactiveDialog: MatDialog,
    private authService: AuthService,
    protected env: EnvironmentService,
  ) {
    this.subscription = userService.currentUser$.subscribe((item) => {
      if (item) {
        this.user = item;
        // tslint:disable-next-line:max-line-length
        this.active =
          this.user.properties["userinactive"] == null ||
          this.user.properties["userinactive"] === "" ||
          this.user.properties["userinactive"].toLowerCase() === "false";
      }
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  statusChange() {
    this.saveStatus();
    if (!this.active) {
      this.inActiveDialogRef = this.inactiveDialog.open(
        InactiveDialogComponent,
        {
          disableClose: true,
          minWidth: "25vw",
          data: {},
        }
      );
      this.inActiveDialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.active = true;
          this.saveStatus();
        }
      });
    }
  }

  logOut() {
    this.authService.logOut();
  }

  saveStatus() {
    this.user.properties["userinactive"] = (!this.active).toString();
    this.userService.setStatus(this.user);
  }
}
