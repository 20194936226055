import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { InternalCookieService } from '../cookie.service';
import { environment } from './../../../environments/environment';

@Injectable()
export class HttpServiceInterceptor implements HttpInterceptor {

  isMobileApp = environment.isMobileApp;

  constructor(private cookieService: InternalCookieService) {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // console.log('log from interceptor', request.url);
    // console.log('log from interceptor cookie ' + this.cookieService.getCookie('token'));
    // console.log("log from interceptor cookie url: " + request.url);
    // const hasToken = request.headers.has('token');
    // const getToken = request.headers.get('token');
    // if (!request.headers.has('token') || !request.headers.get('token')) {
    let authReq = request;
    authReq = request.clone({
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Access-Control-Allow-Headers': 'Content-Type',
        Accept: 'application/json',
        token: this.cookieService.getCookie('token') ? this.cookieService.getCookie('token') : '1234-5678-8765-4321'
      })
    });
    // }
    return next.handle(authReq);

  }
}
