
import { SignalRConnectionService, SignalRState } from './../signal-r-connection.service';
import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';


import { first, map } from 'rxjs/operators';

import {
    Hub,
    HubService,
    HubWrapper,
    HubSubscription
} from 'ngx-signalr-hubservice';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { SoundContext, AudioService } from 'src/app/Services/audio.service';


// More information can be found here - https://github.com/steveadoo32/ngx-signalr-hubservice


// Define any messages that are coming from the server.


@Injectable()
@Hub({ hubName: 'ConnectionHub' })
export class SignalrConnectionCheckerService {

    private hubWrapper: HubWrapper;

    // private hubID = environment.mainSubscriberId ? environment.mainSubscriberId.toString() : '999';
    private hubID = Math.floor(Math.random() * 1000000000);
    private count = 0;
    private pongs = [];
    private errorCount = 0;
    private firstSignalRPing = true;

    public onMessageReceived = new EventEmitter<string>();
    public firstConnected = true;

    private errorLimit = 3;
    public pingTimer = null;


    constructor(
        private signalRConnectionService: SignalRConnectionService,
        private authService: AuthService,
        private audio: AudioService,
    ) {
        this.signalRConnectionService.signalrConnected.subscribe(connected => {
            if (connected && this.firstConnected) {
                this.hubWrapper = this.signalRConnectionService.getHubService().register(this);
                this.firstConnected = false;
                this.subscribe().toPromise();
                this.sendMessage('signalrConnected');
                this.SendPing();
            }
        });
        this.signalRConnectionService.signalrReconnected.subscribe(connected => {
            if (connected) {
                console.log('Connection Hub Wrapper', this.hubWrapper.hub.connection.id);
                this.subscribe().toPromise();
                console.log('Connection Hub Wrapper', this.hubWrapper);
                this.SendPing();
            }
        });

    }

    public SendPing() {
        if (
            this.signalRConnectionService.signalRConnectionState !== SignalRState.disconnected
            || (environment.isMobileApp && this.authService.loggedIn.getValue()) // on mobile, don't check if the user is not logged in
        ) {
            const num = this.count;
            // don't allow more then one at a time.
            clearTimeout(this.pingTimer);
            this.pingTimer = setTimeout(() => {
                this.CheckPongReceived(num);
            }, this.errorCount > 0 ? 10000 : 70000);
            console.log('ping' + this.count);
            this.sendMessage('ping' + this.count++);
        }
    }

    CheckPongReceived(idNumber) {
        if (!this.pongs.includes(idNumber)) {
            this.errorCount++;
            if (this.errorCount > this.errorLimit) {
                if (this.errorCount === this.errorLimit + 2) {
                    this.audio.playAudio(SoundContext.Alert);
                }
                console.log('------------------SIGNALR ISSUE------------------');
                this.signalRConnectionService.AlertPingProblem(true);
            }
        } else {
            this.signalRConnectionService.AlertPingProblem(false);
            this.errorCount = 0;
        }
        this.SendPing();
    }

    UpdatePongList(message) {
        if (message.indexOf('ping') === 0) {
            const num = message.substring(4);
            this.pongs.push(+num);
        }

    }


    // Client => Server the function name is the same as the name of the function in the c# hub
    public sendMessage(message: string): Observable<string> {
        return this.hubWrapper.invoke<boolean>('sendMessage', this.hubID, message).pipe(map(_ => {
            return message;
        }));
    }

    // Client => Server the function name is the same as the name of the function in the c# hub
    public subscribe(): Observable<string> {
        return this.hubWrapper.invoke<boolean>('subscribe', this.hubID).pipe(map(_ => {
            return this.hubID.toString();
        }));
    }

    public unSubscribe(): Observable<string> {
        return this.hubWrapper.invoke<boolean>('unSubscribe', this.hubID).pipe(map(_ => {
            return this.hubID.toString();
        }));
    }

    // Server => Client
    @HubSubscription()
    private receiveMessage(message: string) {
        // tslint:disable-next-line:max-line-length
        console.log('Connection Check Received Message, message: ' + message);
        this.UpdatePongList(message);
        // ensure that this update is for the domain we are looking at. Ignore others.
        // tslint:disable-next-line:max-line-length
        this.onMessageReceived.emit(message);

    }




}
