import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MessageService } from './message.service';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { InternalCookieService } from '../cookie.service';
import { RouterService } from '../Hubs/router.service';
import { EnvironmentService } from '../environment.service';
import { EnvironmentVar } from './../../models/environmentvar';

export interface IGoogleTranslationRequestBody {
  text: string | string[];
  sourceLangCode: string;
  targetLangCode: string;
}
export class GoogleTranslationLanguage {
  Name: string;
  Code: string;
}
export class GoogleTranslationResult {
  OriginalText: string;
  TranslatedText: string;
  DetectedSourceLanguage: string;
  SpecifiedSourceLanguage: string;
  TargetLanguage: string;
  Model: string;
  ModelName: string;
}

@Injectable({
  providedIn: 'root'
})
export class GoogleTranslationService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type'
    })
  };

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private cookieService: InternalCookieService,
    private routerService: RouterService,
    public envService: EnvironmentService) {
  }

  translate(body: IGoogleTranslationRequestBody): Observable<GoogleTranslationResult[]> {
    return this.http.post<any>(this.envService.localEnvironment.restEndpointUrl + '/api/google/translation/translate', body, this.httpOptions).pipe(
      tap(translation => this.log('translate success')),
      catchError(this.handleError('translate', []))
    );
  }

  listLanguages(): Observable<GoogleTranslationLanguage[]> {
    return this.http.get<any>(this.envService.localEnvironment.restEndpointUrl + '/api/google/translation/listLanguages', this.httpOptions).pipe(
      tap(languages => this.log('list languages success')),
      catchError(this.handleError('listLanguages', []))
    );;
  }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
  
      if (error.status === 401) {
        this.routerService.redirectToLoginPage();
      } else {
          //window.location.href = window.location.protocol + '//' + window.location.hostname + '/Login.aspx';
          if (error.status === 500 && operation === 'getStructure')
              window.location.href = this.envService.localEnvironment.restEndpointUrl + "/users/";
          // else
          //   this.routerService.redirectToLoginPage();
      }
  
      // TODO: send the error to remote logging infrastructure
      console.log('error ', error);
      // console.error(error); // log to console instead
  
      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);
  
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
  private log(message: string) {
    this.messageService.add(`Google Translation Service: ${message}`);
  }
}
