import { Injectable } from '@angular/core';

import { DomSanitizer } from '@angular/platform-browser';

import { BehaviorSubject} from 'rxjs';
import { EnvironmentService } from '../environment.service';

import { EntityDataService } from './entity-data.service';

@Injectable({
  providedIn: 'root'
})
export class LinkClientServiceService {

  constructor(private entityDataService: EntityDataService, private envService: EnvironmentService,public sanitizer: DomSanitizer) { }

    selectClientSubject = new BehaviorSubject(null);

    clientUrl = null;

    createClientUrl(clientId: number) {
      const url = this.envService.getICMUrl(`crisis/${clientId}`);
      return url;
    }

    createClientUrlSafe(clientId:number){
      return this.sanitizer.bypassSecurityTrustResourceUrl(this.createClientUrl(clientId));
    }

  linkClient(conversationId: number, clientId: number, linkAll: boolean) {
    return this.entityDataService.linkClient(clientId, conversationId, linkAll, false);

  }

  unlinkClient(conversationId: number, clientId: number, linkAll: boolean){
    return this.entityDataService.linkClient(clientId, conversationId, linkAll, true);

  }
}
