import { SignalRConnectionService } from './../signal-r-connection.service';
import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from './../../../environments/environment';

import { map } from 'rxjs/operators';

import {
  Hub,
  HubService,
  HubWrapper,
  HubSubscription
} from 'ngx-signalr-hubservice';

// More information can be found here - https://github.com/steveadoo32/ngx-signalr-hubservice


// Define any messages that are coming from the server.
export interface EntityMessage { entityID: string; message: string; }

@Injectable()
@Hub({ hubName: 'entityHub' })
export class EntityService {

  private hubWrapper: HubWrapper;

  public onMessageReceived = new EventEmitter<EntityMessage>();
  public onMessageReceived2 = new EventEmitter<EntityMessage>();

  constructor(
    // private hubService: HubService,
    private signalRConnectionService: SignalRConnectionService
    ) {
      if (environment.isMobileApp) {
        this.configureForMobile();
        return;
      }
  this.hubWrapper = this.signalRConnectionService.getHubService().register(this);
}


  // Client => Server the function name is the same as the name of the function in the c# hub
  public sendMessage(entityID: string, message: string): Observable<EntityMessage> {
    return this.hubWrapper.invoke<boolean>('sendMessage', entityID, message).pipe(map(_ => {
      return {
        entityID,
        message
      };
    }));
  }

  // Client => Server the function name is the same as the name of the function in the c# hub
  public subscribe(entityID: string): Observable<string> {
    return this.hubWrapper.invoke<boolean>('subscribe', entityID).pipe(map(_ => {
      return entityID;
    }));
  }




  // Server => Client
  @HubSubscription()
  private receiveMessage(entityID: string, message: string) {
    console.log('Entity Received Message EntityId: ' + entityID + ', message: ' + message);

    this.onMessageReceived.emit({
      entityID,
      message
    });
  }

  @HubSubscription()
  private receiveMessage2(entityID: string, message: string) {
    console.log('Entity Received Message 2');
    this.onMessageReceived2.emit({
      entityID,
      message
    });
  }

/**
 * ------------------------------------------------ FOR MOBILE VERSION -------------------------------------------------------
 */

    /**
     * On mobile version, every time the user logs in using the login page, the signalR hub has to be updated
     * and the constructor shouldn't try to register for hub connection yet until user has logged in
     * that's why there is a configure function that use case
     */
     configureForMobile() {
      this.signalRConnectionService.connectionChanged.subscribe((connectionChanged: boolean) => {
        const hubService = this.signalRConnectionService.getHubService();
        if (connectionChanged && hubService) {
          this.hubWrapper = hubService.register(this);
        }
      });
    }

}
