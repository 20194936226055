import { Injectable, Injector } from '@angular/core';
import { ConversationsService, Conversation } from './conversations.service';
import { Router } from '@angular/router';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MobileConversationTabService {

  selectedConversationTab = '';

  private conversationsService: ConversationsService;

  constructor(
    private router: Router,
    private snackBar: MatSnackBar,
  ) { }

  setConversationService(injector: Injector) {
    this.conversationsService = injector.get(ConversationsService);
    // console.log('set conversationService', this.conversationsService);
  }

  redirectToConversation(conversation: number | Conversation) {
    // console.log('setting conversation', conversation);
    this.conversationsService.setConversation(conversation).then((response) => {
      // console.log('setting conversation 2', response);
      if (response && response.toLowerCase() !== 'not found') {
        if (environment.isMobileApp) {
          this.router.navigate(['mobile-conversation']);
        }
      } else {
        const config: MatSnackBarConfig = {
          verticalPosition: 'top',
          horizontalPosition: 'center',
          duration: 7000
        };
        const notifiRef = this.snackBar.open('Conversation not found or you are not allowed to view it.', 'Dismiss', config);
        notifiRef.onAction().subscribe(() => {
          notifiRef.dismiss();
        });
      }
    });

  }

}
