import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MessageService } from './message.service';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { InternalCookieService } from '../cookie.service';
import { Conversation } from './conversations.service';
import { RouterService } from '../Hubs/router.service';
import { EnvironmentService } from '../environment.service';
import { EnvironmentVar } from '../../models/environmentvar';


@Injectable({
  providedIn: "root",
})
export class SmsService {
  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private cookieService: InternalCookieService,
    private routerService: RouterService,
    public envService: EnvironmentService
    ) {
    console.log(
      "------------------ SMS Service Constructor ---------------------"
    );
  }

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Headers": "Content-Type",
    }),
  };

  sendSMS(conversation: Conversation, message: any): Observable<any> {
    // send the sms message to the system.
    console.log("Sending SMS for conversation: " + conversation.id, message);
    if (
      conversation.properties.converstaionstatus !== "1069" &&
      conversation.properties.converstaionstatus !== "Completed"
    ) {
      // tslint:disable-next-line:max-line-length
      return this.http
        .post<any[]>(
          this.envService.localEnvironment.restEndpointUrl +
            "/api/callcentre/textandchat/sms/" +
            conversation.id,
          message,
          this.httpOptions
        )
        .pipe(
          tap((result) => console.log("Posted sms", result)),
          catchError(this.handleError("get", []))
        );
    }
  }

  endSMS(conversation: Conversation): Observable<any> {
    // send the sms message to the system.
    if (
      conversation.properties.callend ||
      conversation.properties.calltype === "SMS" ||
      conversation.properties.calltype === "Messenger" ||
      conversation.properties.calltype === "Chat"
    ) {
      if (
        (!conversation.properties.callend ||
          conversation.properties.callend === "") &&
        (conversation.properties.calltype === "SMS" ||
        conversation.properties.calltype === "Messenger" ||
          conversation.properties.calltype === "Chat")
      ) {
        // const endSMSMessage = environment.endSMSMessage;
        // let newChatMessage = { id: -1, user: 'admin', body: endSMSMessage, datetimestamp: '', direction: '1073' };
        // this.sendSMS(conversation, newChatMessage).subscribe(
        //     result => {
        //     });
      }
      return this.http
        .post<any[]>(
          this.envService.localEnvironment.restEndpointUrl +
            "/api/callcentre/textandchat/sms/endsms/" +
            conversation.id,
          this.httpOptions
        )
        .pipe(
          tap((result) => console.log("Posted sms", result)),
          catchError(this.handleError("get", []))
        );
    }

    return of([]);
  }

  endQueuedCall(conversation: Conversation): Observable<any> {
    // send the sms message to the system.
    console.log(
      "Ending a queued call conversation: " + conversation.properties.callsource
    );
    // tslint:disable-next-line:max-line-length
    return this.http
      .get<any[]>(
        this.envService.localEnvironment.restEndpointUrl +
          "/api/callcentre/call/queue/end/" +
          conversation.properties.callsource +
          "/",
        this.httpOptions
      )
      .pipe(
        tap((result) => console.log("End Queued Call", result)),
        catchError(this.handleError("get", []))
      );
  }

  patchInTranslator(conversation: Conversation): Observable<any> {
    // send the sms message to the system.
    console.log(
      "Patching in Translator for conversation: " +
        conversation.properties.callsource
    );
    // tslint:disable-next-line:max-line-length
    return this.http
      .get<any[]>(
        this.envService.localEnvironment.restEndpointUrl +
          "/api/callcentre/call/translator/" +
          conversation.properties.callsource +
          "/",
        this.httpOptions
      )
      .pipe(
        tap((result) => console.log("Patched in Translator", result)),
        catchError(this.handleError("get", []))
      );
  }

  setupInternalChat(message: any): Observable<any> {
    // send the sms message to the system.
    console.log("Setup internal chat: ");
    // tslint:disable-next-line:max-line-length
    return this.http
      .post<any[]>(
        this.envService.localEnvironment.restEndpointUrl +
          "/api/callcentre/textandchat/internalchat/",
        message,
        this.httpOptions
      )
      .pipe(
        tap((result) => console.log("Posted internal chat", result)),
        catchError(this.handleError("get", []))
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      if (error.status === 401) {
        this.routerService.redirectToLoginPage();
      } else {
        //window.location.href = window.location.protocol + '//' + window.location.hostname + '/Login.aspx';
      }

      // TODO: send the error to remote logging infrastructure
      console.log("error ", error);
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a HeroService message with the MessageService */
  private log(message: string) {
    this.messageService.add(`Conversation Service: ${message}`);
  }
}
