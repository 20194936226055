import { environment } from '../../../environments/environment';
import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';


import { map } from 'rxjs/operators';

import {
  Hub,
  HubService,
  HubWrapper,
  HubSubscription
} from 'ngx-signalr-hubservice';
import { EnvironmentService } from '../environment.service';
import { EnvironmentVar } from '../../models/environmentvar';
import { AuthService } from './auth.service';

// More information can be found here - https://github.com/steveadoo32/ngx-signalr-hubservice


// Define any messages that are coming from the server.

@Injectable()
  @Hub({ hubName: 'ConnectionHub' })
export class ConnectionToHubService {

  private hubWrapper: HubWrapper;

  private hubs = [];

  // private hubID = environment.mainSubscriberId ? environment.mainSubscriberId.toString() : '999';
  private hubID = Math.floor(Math.random() * 1000000000);

  public onMessageReceived = new EventEmitter<string>();

  constructor(
    private hubService: HubService,
    private envService: EnvironmentService,
    private authService: AuthService
  ) {
      this.authService.loggedIn.subscribe(loggedIn => {
        console.log('connection-to-hub-check service disconnect', loggedIn, this.hubWrapper, this.hubID);
        if (loggedIn === false && this.hubWrapper && this.hubID) {
          this.hubWrapper.invoke<boolean>('unSubscribe', this.hubID).pipe(map(_ => {
            return this.hubID.toString();
          }));
        } else if (loggedIn === true) {
          this.hubs = this.envService.localEnvironment.hubs;
          this.registerHubWrapper();
        }
      });
  }

  reinitializeHubWrapper(hubServiceInc: HubService) {
    this.hubService = hubServiceInc;
    this.registerHubWrapper();
  }

  registerHubWrapper() {
    this.hubWrapper = this.hubService.register(this);
  }


  // Client => Server the function name is the same as the name of the function in the c# hub
  public sendMessage(message: string): Observable<string> {
    return this.hubWrapper.invoke<boolean>('sendMessage', this.hubID, message).pipe(map(_ => {
      return message;
    }));
  }

  // Client => Server the function name is the same as the name of the function in the c# hub
  public subscribe(): Observable<string> {
    return this.hubWrapper.invoke<boolean>('subscribe', this.hubID).pipe(map(_ => {
      return this.hubID.toString();
    }));
  }

  public unSubscribe(): Observable<string> {
    return this.hubWrapper.invoke<boolean>('unSubscribe', this.hubID).pipe(map(_ => {
      return this.hubID.toString();
    }));
  }

  // Server => Client
  @HubSubscription()
  private receiveMessage(message: string) {
    // tslint:disable-next-line:max-line-length
    console.log('Connection Check Received Message, message: ' + message);

    // ensure that this update is for the domain we are looking at. Ignore others.
    // tslint:disable-next-line:max-line-length
    this.onMessageReceived.emit(message);
  }




}
