import { Component, OnInit, Inject } from '@angular/core';
import { Conversation, ConversationsService } from '../../Services/Data/conversations.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import { EntityDataService } from '../../Services/Data/entity-data.service';
import { UserService } from '../../Services/Data/user.service';
import { Subscription } from 'rxjs';
import {CallerService } from '../../Services/Data/caller.service';

@Component({
  selector: 'app-caller-note-dialog',
  templateUrl: './caller-note-dialog.component.html',
  styleUrls: ['./caller-note-dialog.component.scss']
})
export class CallerNoteDialogComponent implements OnInit {

  caller: any;
  user: any;
  isSupervisor: boolean;
  selectedValue: any [];
  createNew = false;
  flagCaller: any;
  okToShow = true;

  test = ""

  constructor(public dialogRef: MatDialogRef<CallerNoteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  private entityDataService: EntityDataService,
  private callerService: CallerService) {

    console.log('Data: ', this.data);
    this.user = this.data.user;
    this.createNew = this.data.createNew;

    this.setSupervisor();
    this.selectedValue = [];
    this.caller = this.callerService.getActiveCaller();
    if (this.caller) {
        console.log('get caller: ', this.caller);
        if (this.caller.properties.callerphone === '') {
            this.okToShow = false;
        }
        this.selectedValue[0] = this.caller.properties.notestostaff;
        this.selectedValue[1] = this.caller.properties.block_cfcid;
        this.selectedValue[2] = this.caller.properties.requesttoblock_cfcid;
    } else {
        this.dialogRef.close();
    }

    if (this.createNew) {
        this.caller = {
            entitytypeid: 599,
            linkedProperties: {user: {id: this.user.id}, caller: {id: this.caller.id}},
            properties: {
                boundaries: '',
                name: '',
                gender: '',
                age: '',
                speechpattern: '',
                mainconcern: '',
                background: '',
                tipssupporting: '',
                tipsboundaries: '',
                timesnormallycalls: '',
                other: '',
                requesttoblock_cfcid: '334',
                reviewedradio_cfcid: '770' // requires review
            }
        };
    }

  }

  ngOnInit() {
  }

  setSupervisor() {
    console.log('if supervisor?', this.user);
    if (this.user.properties.usertype === 'Supervisor') {
      this.isSupervisor = true;
    } else {
      this.isSupervisor = false;
    }
  }

  createFlagRequest() {
    const properties = this.caller.properties;
    console.log('result save: ', properties);
    // tslint:disable-next-line:max-line-length
    this.entityDataService.create({ entityTypeId: 599, properties: this.caller.properties, linkedProperties: this.caller.linkedProperties }).subscribe(resp => {
        console.log('resp', resp);
    });
  }

}
