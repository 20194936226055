import { EntityDataService } from '../../Services/Data/entity-data.service';
import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import { SmsService } from '../../Services/Data/sms.service';
import { Conversation, ConversationsService } from '../../Services/Data/conversations.service';
import { environment } from '../../../environments/environment';


@Component({
  selector: 'app-end-call',
  templateUrl: './end-call.component.html',
  styleUrls: ['./end-call.component.scss']
})
export class EndCallComponent implements OnInit {

    conversation: Conversation;
    conversationsService;
    isFilled: Boolean;
    isMobileApp = environment.isMobileApp;


    constructor(public dialogRef: MatDialogRef<EndCallComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
    private entityDataService: EntityDataService,
    // private conversationServ: ConversationsService,
    private smsService: SmsService
    ) {
        this.conversation = data.conversation;
        this.conversationsService = data.service;
        this.isFilled = data.isFilled;
        console.log('Conversation Registered', this.conversationsService.conversations);
        // console.log('Conversation Registered', this.conversationServ.conversations);
        console.log('is all filled?', this.isFilled);

     }

    ngOnInit() {
    }

    ConfirmEnd() {
        if (this.conversation.properties.calltype === 'SMS' || this.conversation.properties.calltype === 'Messenger' || this.conversation.properties.calltype === 'Chat') {
            this.smsService.endSMS(this.conversation).subscribe(results => {
                this.conversationsService.endConversation(this.conversation);
                this.dialogRef.close('doneEnd');
            });
        } else {
            this.conversationsService.endConversation(this.conversation);
            this.dialogRef.close('doneEnd');
        }
    }

    // endConversation() {
    //     console.log('Ending Conversation ', this.conversation);
    //     this.entityDataService.entitySyncUpdate([{
    //       id: this.conversation.id,
    //       properties: {conversationstatus_cfcid: '1069'}
    //     }]).subscribe(result => {});
    // }


}
