import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AudioService } from 'src/app/Services/audio.service';
import { UserService } from 'src/app/Services/Data/user.service';
import { VoiceService } from 'src/app/voice/voice-service/voice.service';

@Component({
  selector: 'app-vibes',
  templateUrl: './vibes.component.html',
  styleUrls: ['./vibes.component.scss']
})
export class VibesComponent implements OnInit {

  eqReady = false;
  stream: Observable<number>;

  constructor(
    private user: UserService,
    private voice: VoiceService,
    public audio: AudioService,
  ) { }

  ngOnInit() {
    this.user.get().subscribe(u => u);
    this.stream = this.audio.inputStream();
    this.audio.audioHelper().subscribe(a => a.on('deviceChange', () => this.eqReady =  !a.availableInputDevices.values().next().done) );
  }


  refresh() {
      this.audio.stopInputStream();
      this.audio.getInputPermission().then(success => success && this.audio.startInputStream() );
  }
}
