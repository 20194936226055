import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {Router} from '@angular/router';
import { InternalCookieService } from '../Services/cookie.service';
import { UserService } from '../Services/Data/user.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-settoken',
  templateUrl: './settoken.component.html',
  styleUrls: ['./settoken.component.scss']
})
export class SettokenComponent implements OnInit {

    tokenGuid: string;
    isMobileApp = environment.isMobileApp;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private cookieService: InternalCookieService,
    private userService: UserService
    ) {

     }

  ngOnInit() {
      this.tokenGuid = this.route.snapshot.paramMap.get('tokenGuid');
      console.log('setToken', this.tokenGuid);
      if (this.tokenGuid) {
        this.cookieService.setCookie('token', this.tokenGuid);
        console.log('Redirection To Dashboard.');
        if (this.isMobileApp) {
          this.router.navigate(['/mobile-dashboard']);
        } else {
          this.router.navigate(['/dashboard']);
        }
      }
  }

}
