import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment as globalEnvironment} from '../../environments/environment';
import { EnvironmentVar } from '../models/environmentvar';
import { InternalCookieService } from './cookie.service';
import { IGenericObject } from 'src/app/Services/models/warp-entity';


@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {
  shouldUpdateApp = new Subject()

  // options for toggling experimental/beta/premium features and specific feature options
  featureOptions: {
    experimental: {
      // are features enabled?
      [featureName: string]: boolean
    },
    // specific options for features
    // eg. slideOutMap: { width: 100px }
    [featureName: string]: IGenericObject
  };
  _featureOptions: {
    experimental: {
      [featureName: string]: boolean
    },
    [featureName: string]: IGenericObject
  } = {
    experimental: {
      slideoutMap: globalEnvironment.isBeta,
    },
  };

  // environment: EnvironmentVar = null;
  environment: any = null;
  hasEnvFromServer = false;
  temporaryLoginEndPoint: string = '';

  // hardcoded env var for testing:
  tempEnvVar: EnvironmentVar = {
    mobileEndpoints: {
      // ------- only for testing and localhost -----------------
      resourceEndpointUrl: '',
      restEndpointUrl: '', //
      localEndpointUrl: '', // for logging into the login screen with localhost as company name
      //-------------------------------
      enableMobileUpdate: false, // set to false for development
      mobileUpdateChannel: 'development',
      mobileUpdateAppID: 'bb4c116e',
      isDefaultEnv: true
    },
    mainSubscriberId: -1,
    includeTranslator: true,
    exposeCallerNicknames: false,
    hubUrl: '',
    restEndpointUrl: '',
    appTitle: 'Izzy App',
    // appUpdateChannel: undefined, // defaults to whatever is in the environment.ts file
    notificationTitle: 'Izzy App',
    interpreterContent: '',
    isTestingEnvironment: false,
    SeeOthersShiftReservations: false,
    internalChatAvailable: false,
    userInitiatedInternalChat: false,
    internalChatAllowEndAndDelete: true,
    showReservedShiftSchedule: [],
    logoSrc: 'assets/img/izzy-dark-lg.jpg',
    logoTestSrc: 'assets/img/izzy-dark-lg.jpg',
    logoAlt: '',
    showChatTicketLogo: false,
    showExitSurvey: false,
    showExitSurveyTitle: false,
    exitSurveyDescription: '',
    ticketBottomImage: '',
    allowBookUntil: false,
    staffCreateConversation: true,
    volunteerCreateConversation: false,
    useCommunityResourceGuide: false,
    resourceEndpointUrl: '',
    isBeta: false,
    hubs: [
    ],
    resourceGuideResources: [
    ],
    avatarMenuResourcesLinks: [
    ],
    CallReportCfcs: [
    ],
    endSMSMessage: 'Thank you for chatting with us. Your chat session has now ended.',
    endChatMessage: 'Thank you for chatting with us. Your chat session has now ended.',
    features: {}
  };

  get featureEnabled() {
    return this.featureOptions.experimental;
  }

  get localEnvironment() {
    return this.environment;
  }

  get formbuilderTicketId() {
    return this.environment.callTicketFormId || -1;
  }

  get useFormbuilderTicket() {
    return this.formbuilderTicketId > 0 && this.featureEnabled.formbuilderTicket; // if the call ticket form id is set, use it
  }

  get globalEnvironment() {
    return globalEnvironment;
  }

  private isLocal = false;

  constructor(
    private http: HttpClient,
    private cookieService: InternalCookieService
  ) {
    this.isLocal = this.isLocalByUrl();

    this.setupFeatures();
  }

  public isLocalByUrl () {
    // looks like a port number or localhost
    return !!window.location.hostname.match(/keenan\.com|localhost|([0-9]{1,3}\.){3}[0-9]{1,3}/);
    // return !!window.location.hostname.match(/^[0-9\.]+$/) || window.location.hostname === 'localhost';
  }

  clearTokens() {
    this.cookieService.dropCookie('token');
    this.cookieService.dropCookie('appToken');
  }

  setupFeatures() {
    // setup getters and setters for features,
    this.featureOptions = {} as any;
    Object.defineProperty(this.featureOptions, 'experimental', { enumerable: false, value: { } });

    for (const key of Object.getOwnPropertyNames(this._featureOptions)) {
      if (key === 'experimental') {
        for (const exKey of Object.getOwnPropertyNames(this._featureOptions.experimental))
          Object.defineProperty(this.featureOptions.experimental, exKey, {
            get: () => this._featureOptions.experimental[exKey],
            set: (v) => this.enableFeature(exKey, v),
            configurable: false, enumerable: true
          });
      } else
        Object.defineProperty(this.featureOptions, key, {
          get: () => this._featureOptions[key],
          set: (v) => this._featureOptions[key] = v,
          configurable: false, enumerable: true
        });
    }
    console.log('features', this.featureOptions, this._featureOptions);
  }

  initFeaturesFromEnv(featuresFromSetting: EnvironmentVar['features'] = {}) {
    this._featureOptions.experimental = {
      ...this._featureOptions.experimental,
      ...featuresFromSetting
    };

    this.setupFeatures();
  }

  public getEnviromentVariablesFromServer(injector: Injector): Promise<boolean> {
    this.ensureCookies();

    let endPointToGetEnvironment = this.getRippleUrl();
    if (globalEnvironment.isMobileApp) {
      endPointToGetEnvironment = this.cookieService.getCookie('lastEndpoint');
    }

    const retVal = new Promise<boolean>((resolve, reject) => {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'Accept': 'application/json',
          'Access-Control-Allow-Headers': 'Content-Type'
        })
      };
      this.http.get(endPointToGetEnvironment + '/api/izzySiteSettings', httpOptions)
      .toPromise()
      .then((setting: EnvironmentVar) => {
        if (setting && setting.mainSubscriberId) {
          Object.entries(this.tempEnvVar).forEach(item => {
            if (!setting[item[0]] && setting[item[0]] !== false) {
                console.log('adding default properties', item[0]);
                setting[item[0]] = this.tempEnvVar[item[0]];
            }
          });

          setting = this.updateEnvironment(setting, endPointToGetEnvironment);
          console.log('environment swapped, new Environment', setting);
          this.environment = setting;
          this.hasEnvFromServer = true;
          resolve(true);
        } else {
          setting = this.updateEnvironment(this.tempEnvVar, endPointToGetEnvironment);
          console.log('environment error, new Environment', setting);
          this.environment = setting;
          this.hasEnvFromServer = false;
          resolve(true);
        }
      })
      .catch((error) => {
        console.log('Error: ', endPointToGetEnvironment, error)
        this.redirectToLoginPage(injector, resolve);
      });
    });
    return retVal;
  }

  public updateEnvironment(setting: EnvironmentVar, endPointToGetEnvironment: string) {
    let targetDomain = endPointToGetEnvironment.toLowerCase();
    if (!globalEnvironment.isMobileApp) {
      targetDomain = window.location.href.toLowerCase();
    }

    if (globalEnvironment.isMobileApp && setting.appUpdateChannel !== undefined) {
      [ globalEnvironment, setting, this.environment ]
        .forEach( env => {
          if (env && env.mobileEndpoints)
            env.mobileEndpoints.mobileUpdateChannel = setting.appUpdateChannel;
        });
      this.triggerAppUpdate();
    }

    setting = this.updateEndpoints(setting);
    setting = this.updateDirectNumber(setting, targetDomain);

    this.initFeaturesFromEnv(setting.features);
    return setting;
  }

  private updateDirectNumber(setting: EnvironmentVar, domain: string) {
    let directNumbers: any[] = [];
    if (domain.toLowerCase().includes('sace')) {
      directNumbers.push('+18664038000');
      directNumbers.push('+17804234121');
    } else if (domain.toLowerCase().includes('ccasa')) {
      directNumbers.push('+18664038000');
      directNumbers.push('+14032375888');
      directNumbers.push('+14032376905');
    } else {
      setting.hubs.forEach(element => {
        directNumbers.push(element.phoneNumber);
      });
    }
    setting.directNumbers = directNumbers;
    return setting;
  }

  private triggerAppUpdate() {
    console.log(` ========= requires app update [${globalEnvironment.mobileEndpoints.mobileUpdateChannel}] ========= `);
    this.shouldUpdateApp.next();
  }

  private updateEndpoints(setting: EnvironmentVar) {
    const url = this.getRippleUrl();
    if (globalEnvironment.isMobileApp) {
      setting.restEndpointUrl = this.getTemporaryLoginEndPointForMobile();
      setting.hubUrl = setting.restEndpointUrl + '/signalr';
    } else {
        if ( this.isLocalByUrl() ) {
          setting.hubUrl = `${url}/signalr`;
          setting.restEndpointUrl = `${url}`;
        } else if (window.location.href.includes("izzyplatform2")) {
          setting.hubUrl = "http://izzyplatform.ngrok.io/signalr";
          setting.restEndpointUrl = "http://izzyplatform.ngrok.io";
        } else if (window.location.href.includes("ionicframework")) {
          setting.hubUrl = "http://demo.izzyplatform.com/signalr";
          setting.restEndpointUrl = "http://demo.izzyplatform.com";
        } else {
          setting.hubUrl = '/signalr';
          setting.restEndpointUrl =  '';
        }
    }
    return setting;
  }

  public getEnviromentVariables(): BehaviorSubject<EnvironmentVar> {
    return this.environment;
  }

  public getEnviroment(): EnvironmentVar {
    return this.environment;
  }

  public destroyEnvironmentVariables(): void {
    this.environment = this.tempEnvVar;
    this.hasEnvFromServer = false;
  }

  public setTemporaryLoginEndPointForMobile(value: string): void {
    this.temporaryLoginEndPoint = value;
  }

  public getTemporaryLoginEndPointForMobile(): string {
    return this.temporaryLoginEndPoint;
  }

  public ensureCookies() {
    const NOT_LOGGED_IN_TOKEN = '0000-0000-000-0000';
    const oldToken = this.cookieService.getCookie('token');
    if (oldToken && oldToken !== NOT_LOGGED_IN_TOKEN) return;

    const tokens = window.location.pathname.match(/\/?token\/([^\/\?\#]*)(?=[\/\?#])?/);
    //const tokens = window.location.pathname.slice(window.location.pathname.indexOf("token/") + 6, window.location.pathname.indexOf("?"));
    const tokenGuid = tokens !=  null ? tokens[1] : null;

    console.log('setToken', tokenGuid);
    if (tokenGuid)
      this.cookieService.setCookie('token', tokenGuid);
    else
      this.cookieService.setCookie('token', NOT_LOGGED_IN_TOKEN);
  }

  public getRippleUrl(): string {
    let href;
    if (globalEnvironment.isMobileApp && this.getTemporaryLoginEndPointForMobile())
      return this.getTemporaryLoginEndPointForMobile();

    // i host ripple on the paid thing, and this thing on the random guid
    if (window.location.hostname.includes('appflowapp'))
      href = window.location.protocol + '//' + 'demo.izzyplatform.com'
    else if (window.location.hostname.endsWith('ngrok.io'))
      href = `${window.location.protocol}//izzyplatform.ngrok.io`;
    else if (this.isLocal && !this.isHosted)
      href = `${window.location.protocol}//${window.location.hostname}:56696`;
    else
      href = `${window.location.protocol}//${window.location.host}`;
    return href;
  }

  redirectToLoginPage(injector: Injector, resolve: (val: boolean) => void): void {
    this.clearTokens();
    if (globalEnvironment.isMobileApp) {
      this.destroyEnvironmentVariables();
      injector.get(Router).navigate(['/mobile-dashboard']).then( s => resolve(s) );
      resolve(true);
    } else if (this.isUnified)
      window.location.href = this.getRippleUrl(); // this will take us to ICM login
    else
      window.location.href = this.getRippleUrl() + '/Login.aspx';
  }

  getICMUrl(pathWithQuery: string = '') {
    const [path, qString] = pathWithQuery.split('?', 2);

    let url = this.localEnvironment.icmUrl || (document.baseURI.replace('/helpline', '') + '/caseManagement');
    url = `${url.startsWith('http') ? '' : `${window.location.protocol}//`}${url.replace(/\/+$/g, '')}`;

    return new URL(`${url}/${path}?fromHelpline=true${qString ? '&' + qString : ''}`).href.replace(/caseManagement/i, 'caseManagement');
  }

  navToICM(path: string = '') {
    const url = this.getICMUrl(path);
    console.log('Going to Case Management', url);
    window.open(url, '_self');
  }

  enableFeature(featureName: string, enable: boolean = true) {
    this._featureOptions.experimental[featureName] = enable;
  }

  get isUnified() {
    return (this.environment && this.environment.enableICM) ||
      (!this.hasEnvFromServer && window.location.pathname.startsWith('/helpline/'));
  }

  get isHosted() {
    return !window.location.host.match(/\:/);
  }

}
