import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { EmailService } from '../../Services/email.service';
import { ScheduleService } from '../../Services/Data/schedule.service';
import { ShiftBlock } from '../../models/shiftBlock';
import { EntityDataService } from '../../Services/Data/entity-data.service';

@Component({
  selector: 'app-find-shift-replacement-dialog',
  templateUrl: './find-shift-replacement-dialog.component.html',
  styleUrls: ['./find-shift-replacement-dialog.component.scss']
})
export class FindShiftReplacementDialogComponent implements OnInit {

  shiftBlock: ShiftBlock;
  currentUser: any;
  isSupervisor = false;
  dayMoment: any;
  reservation: any;
  dateString: string;
  businessUnit: string;
  allStaff: boolean;
  allSupervisors: boolean;
  allVolunteers: boolean;
  rolesAllowed: any[];
  loading: boolean;
  prevReserver: string;

  constructor( @Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<FindShiftReplacementDialogComponent>,
  public emailService: EmailService,
  public scheduleService: ScheduleService,
  public entityService: EntityDataService) {
    this.shiftBlock = data.shiftBlock;
    this.dayMoment = data.day;
    this.currentUser = data.user;
    console.log('Data:', data);
    this.isSupervisor = this.currentUser.properties.usertype === 'Supervisor';
    this.reservation = this.shiftBlock.reservation;
    this.allStaff = this.shiftBlock.shift.properties.allstaff === '1';
    this.allSupervisors = this.shiftBlock.shift.properties.allsupervisors === '1';
    this.allVolunteers = this.shiftBlock.shift.properties.allvolunteers === '1';
    this.rolesAllowed = this.shiftBlock.shift.properties.roles;
    this.dateString = this.dayMoment.format('dddd, MMMM Do, YYYY') +
    ' from ' + this.shiftBlock.start.format('h:mm A') + ' to ' + this.shiftBlock.end.format('h:mm A');
    this.businessUnit = this.shiftBlock.shiftGroupWrapper.shiftGroup.properties.schedule;
    this.prevReserver = this.findPrevReserver();
  }

  ngOnInit() {
    this.loading = false;
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  sendEmail() {
    this.loading = true;
    this.emailService.sendFindShiftReplacementEmail(this.buildSendObject()).subscribe(result => {
      this.entityService.entitySyncUpdate([{
        id: this.reservation.id,
        properties: {prevreserver: this.prevReserver}
      }]).subscribe (reservationArr => {
        console.log(reservationArr);
        this.dialogRef.close(true);
      });
    });
  }

  buildSendObject(): any {
    console.log('start: ', this.shiftBlock.start.toDate());
    console.log('end: ', this.shiftBlock.end.toDate());
    const link = 'https://' + window.location.host + '/AASASDashboard/shiftReplacement/' + this.reservation.guid;
    const retVal = {
      title: this.businessUnit + ' Shift Replacement Request',
      start: this.shiftBlock.start.toDate(),
      end: this.shiftBlock.end.toDate(),
      to: null,
      body: '<p>The person that was scheduled for a shift on ' + this.dateString + ' \
      is no longer available at that time, so we are looking for someone to fill the shift.</p>\
      <p>If you are able to take that shift, please click the link below to reserve it.</p>\
      <p><a href="' + link + '">Accept</a>\
      Reserve Shift on ' + this.dateString + ': </p>\
      <p>Thank you,</p>\
      <p>' + this.businessUnit + '</p><br/>',
      hasPhone: this.reservation.properties.shiftphonereservation !== '',
      hasSMS: this.reservation.properties.shiftsmsreservation !== '',
      allStaff: this.allStaff,
      allVolunteers: this.allVolunteers,
      rolesAllowed: this.rolesAllowed,
      reservationId: this.reservation.id
    };
    return retVal;
  }

  findPrevReserver(): string {
    let retVal = '';
    if (this.reservation.properties && this.reservation.properties.shiftphonereservation !== '') {
      retVal = this.reservation.properties.shiftphonereservation;
    }

    if (this.reservation.properties && this.reservation.properties.shiftsmsreservation !== '') {
      retVal = this.reservation.properties.shiftsmsreservation;
    }
    return retVal;
  }

}
