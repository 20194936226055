import { Component } from '@angular/core';
import { AudioService } from 'src/app/Services/audio.service';

@Component({
  selector: 'app-troubleshoot-audio',
  templateUrl: './troubleshoot-audio.component.html',
  styleUrls: ['./troubleshoot-audio.component.scss']
})
export class TroubleshootAudioComponent {
  constructor(protected audio: AudioService) {}

  scrollTo($event, element: HTMLElement) {
    $event.preventDefault();
    element.scrollIntoView({ behavior: 'smooth' });
  }

  openSettings($event) {
    $event.preventDefault();
    this.audio.openSettings();
  }
}
