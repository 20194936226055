import { SignalRConnectionService } from './../signal-r-connection.service';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { EntityDataService } from './entity-data.service';
import { BehaviorSubject, Observable, Subscription, from, of } from 'rxjs';
import { ShiftBlock } from '../../models/shiftBlock';
import * as moment from 'moment';
import { HubService } from 'ngx-signalr-hubservice';
import { EntityTypeService } from '../Hubs/entity-type.service';
import { EntityTypeMessage } from '../Hubs/entity-type.service';
import { environment } from '../../../environments/environment';
import { ShiftGroupWrapper } from '../../models/shiftGroupWrapper';
import { ShiftWrapper } from '../../models/shiftWrapper';
import { UserService } from './user.service';
import { EmailService } from '../email.service';
// tslint:disable-next-line:max-line-length
import { Component, OnInit, OnDestroy } from '@angular/core';



import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { EnvironmentService } from '../environment.service';
import { FormStyle, getLocaleMonthNames, TranslationWidth } from '@angular/common';
import { lang } from 'src/i18n';
import { IWarpEntity } from '../models/warp-entity';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';


@Injectable({
    providedIn: 'root'
})
export class ScheduleService implements OnDestroy {

    loaded$: BehaviorSubject<Boolean>;
    days$: BehaviorSubject<any[]>;
    allReservations$: BehaviorSubject<any[]>;
    shifts: any[];
    shiftGroups: ShiftGroupWrapper[];
    schedules: any;
    exceptionDaysOff: any;
    reservations: IWarpEntity[];
    reservationsThisYear: any[];
    allUsers: any[] = [];
    usersHours: any[];
    allAssigneeGroups: IWarpEntity[] = [];
    shiftBlocks: ShiftBlock[];
    yearShiftBlocks: ShiftBlock[];
    calSelectedDate: Date;
    calSelectedEndDate: Date;
    hasAvailability = false;

    scheduleStartHour = 7;
    scheduleEndHour = 20;
    scheduleHours: number[];
    private subscriptions = new Subscription();
    private subscriptionsEntityTypeService: Subscription[] = [];
    private signalRscheduleSubscription: Subscription[] = [];

    firstConnected = true;

    currentUser: any;
    isSupervisor: Boolean;

    months: string[];

    availabilityLists: any[];
    userFilterForAvailability: any[] = [];
    @Inject(LOCALE_ID)
    locale: string;

    constructor(
        private entityDataService: EntityDataService,
        // private hubService: HubService,
        private entityTypeService: EntityTypeService,
        public userService: UserService,
        public emailService: EmailService,
        public signalRConnectionService: SignalRConnectionService,
        public envService: EnvironmentService,
    ) {
        console.log('------------------ Schedule Service Constructor ---------------------');
        this.months = getLocaleMonthNames(this.locale || lang, FormStyle.Standalone, TranslationWidth.Abbreviated);

        this.loaded$ = new BehaviorSubject<Boolean>(false);
        this.days$ = new BehaviorSubject([]);
        this.allReservations$ = new BehaviorSubject([]);
        this.isSupervisor = false;
        this.subscriptions.add(userService.currentUser$.subscribe(user => {
            if (user && (!this.currentUser || (this.currentUser && this.currentUser.id !== user.id))) {
                this.currentUser = user;
                this.isSupervisor = user.properties.usertype === 'Supervisor';
                this.reload();
            }
        }));

        this.calSelectedDate = new Date();
        this.calSelectedDate.setHours(0, 0, 0, 0);
        this.calSelectedEndDate = this.calSelectedDate;

        this.scheduleHours = [];
        for (let i = this.scheduleStartHour; i <= this.scheduleEndHour; i++) {
            this.scheduleHours.push(i);
        }


        this.signalRConnectionService.signalrConnected.subscribe(connected => {
            // reservation
            if (connected && this.firstConnected) {
                if (!this.envService.globalEnvironment.isMobileApp)
                    this.firstConnected = false;
            this.entityTypeService.subscribe('613').toPromise();
            const subFor613 = this.entityTypeService.onMessageReceived.subscribe((entityTypeMessage: EntityTypeMessage) => {
                if (entityTypeMessage.entityTypeID === '613') {
                    console.log('Change!', entityTypeMessage);
                    this.loaded$.next(false);
                    console.log('Old Reservations', this.reservations.length);
                    this.reservations = [];
                    this.loadAllShiftReservations();
                }
            });
            this.subscriptionsEntityTypeService.push(subFor613);
            this.entityTypeService.subscribe('595').toPromise();
            const subFor595 = this.entityTypeService.onMessageReceived.subscribe((entityTypeMessage: EntityTypeMessage) => {
                if (entityTypeMessage.entityTypeID === '595') {
                    console.log('Change!', entityTypeMessage);
                    this.loaded$.next(false);
                    console.log('Old shiftGroups', this.shiftGroups.length);
                    this.shiftGroups = [];
                    this.loadAllShiftGroups();
                }
            });
            this.subscriptionsEntityTypeService.push(subFor595);
            this.entityTypeService.subscribe('596').toPromise();
            const subFor596 = this.entityTypeService.onMessageReceived.subscribe((entityTypeMessage: EntityTypeMessage) => {
                if (entityTypeMessage.entityTypeID === '596') {
                    console.log('Change!', entityTypeMessage);
                    this.loaded$.next(false);
                    console.log('Old shifts', this.shifts.length);
                    this.shifts = [];
                    this.loadAllShifts();
                }
            });
            this.subscriptionsEntityTypeService.push(subFor596);
            this.entityTypeService.subscribe('594').toPromise();
            const subFor594 = this.entityTypeService.onMessageReceived.subscribe((entityTypeMessage: EntityTypeMessage) => {
                if (entityTypeMessage.entityTypeID === '594') {
                    console.log('Change!', entityTypeMessage);
                    this.loaded$.next(false);
                    console.log('Old schedules', this.schedules.length);
                    this.schedules = [];
                    this.loadAllSchedules();
                }
            });
            this.subscriptionsEntityTypeService.push(subFor594);
            this.entityTypeService.subscribe('601').toPromise();
            const subFor601 = this.entityTypeService.onMessageReceived.subscribe((entityTypeMessage: EntityTypeMessage) => {
                if (entityTypeMessage.entityTypeID === '601') {
                    console.log('Change!', entityTypeMessage);
                    this.loaded$.next(false);
                    console.log('Old exceptionDaysOff', this.exceptionDaysOff.length);
                    this.exceptionDaysOff = [];
                    this.loadAllExceptionDaysOff();
                }
            });
            this.subscriptionsEntityTypeService.push(subFor601);
            // availability list
            this.entityTypeService.subscribe('666').toPromise();
            this.entityTypeService.subscribe('667').toPromise();
            const subFor666 = this.entityTypeService.onMessageReceived.subscribe((entityTypeMessage: EntityTypeMessage) => {
                if (entityTypeMessage.entityTypeID === '666' || entityTypeMessage.entityTypeID === '667') {
                    console.log('Change!', entityTypeMessage);
                    this.loaded$.next(false);
                    console.log('Old availability lists', this.availabilityLists.length);
                    this.availabilityLists = [];
                    this.loadAllShiftAvailabilityLists();
                }
            });
            this.subscriptionsEntityTypeService.push(subFor666);
        } else {
            console.log('Unsubbing!!!!!');
            this.unsubscribeFromAll();
        }
        });

        this.signalRConnectionService.signalrReconnected.subscribe(reconnected => {
            if (reconnected) {
                console.log('signalR Reconnected:');
                this.entityTypeService.subscribe('613').toPromise();
                this.entityTypeService.subscribe('595').toPromise();
                this.entityTypeService.subscribe('596').toPromise();
                this.entityTypeService.subscribe('594').toPromise();
                this.entityTypeService.subscribe('601').toPromise();
                this.entityTypeService.subscribe('666').toPromise();
                this.entityTypeService.subscribe('667').toPromise();
                }
          });




    }

    unsubscribeFromAll() {
        this.subscriptionsEntityTypeService.forEach(sub => {
            if (sub) {
                sub.unsubscribe();
            }
        });
    }


    reload() {
        this.loadAllShifts();
        this.loadAllShiftGroups();
        this.loadAllAssignees(583, this.allUsers);
        this.loadAllAssignees(768, this.allAssigneeGroups);
        this.loadAllSchedules();
        this.loadAllExceptionDaysOff();

        this.entityDataService.getStructure(666, 1).subscribe(result => {
            if (result.moduleLists[0].modules.length > 0) {
                this.hasAvailability = true;
                this.loadAllShiftAvailabilityLists();
            }
        });


    }

    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
        this.subscriptions.unsubscribe();
    }


    canShiftBlockBeReserved(shiftBlock: ShiftBlock): Boolean {
        let retVal: Boolean = false;

        //compare against this.shiftBLocks
        const newSb = this.shiftBlocks.find(sb => sb.shift.id === shiftBlock.shift.id && sb.date.toString() === shiftBlock.date.toString()
            && sb.hasPhone === shiftBlock.hasPhone && sb.hasSMS === shiftBlock.hasSMS);
        if (newSb && !newSb.reservation) {
            retVal = true;
        } else {
            retVal = false;
        }

        return retVal;
    }

    checkSimultaneous(shiftBlock: ShiftBlock, reserverId: number, reservationType: string): Boolean {
        let retVal: Boolean = false;
        let reserverName: string;
        this.allUsers.forEach(user => {
            if (user.id === reserverId) {
                reserverName = user.name;
            }
        });
        const shiftBlocks = this.shiftBlocks.filter(sb => sb.shift.id !== shiftBlock.shift.id &&
            sb.reserverName === reserverName &&
            (sb.shiftGroupWrapper.shiftGroup.properties.izzycountstowardoverlappingbooking === 'Yes'
             || !sb.shiftGroupWrapper.shiftGroup.properties.izzycountstowardoverlappingbooking));
        shiftBlocks.forEach(sb => {
            if (shiftBlock.start >= sb.start &&
                shiftBlock.start < sb.end ||
                shiftBlock.start <= sb.start &&
                shiftBlock.end >= sb.end ||
                shiftBlock.end > sb.start &&
                shiftBlock.end <= sb.end) {
                if (reservationType === 'Phone Only' && sb.hasPhone === true ||
                    reservationType === 'SMS Only' && sb.hasSMS === true ||
                    reservationType === 'All (Phone / SMS)' && (sb.hasPhone === true || sb.hasSMS === true)) {
                    retVal = true;
                }
            }
        });
        return retVal;
    }

    whichShiftBlock(dayMoment: any, shiftBlock: ShiftBlock, reservationType: string): ShiftBlock {
        let retVal: ShiftBlock = shiftBlock;
        const days = this.days$.getValue();
        const day = days.find(x => x.date.diff(dayMoment, 'days') === 0);
        let reservedType = 'hasPhone';
        let otherType = 'hasSMS';
        if (reservationType === 'SMS Only') {
            reservedType = 'hasSMS';
            otherType = 'hasPhone';
        }
        if (day != null && shiftBlock['shift'].properties.shifttype === 'All (Phone / SMS)') {
            day['shiftBlocks'].forEach(sb => {
                if (sb['shift'].id !== shiftBlock['shift'].id &&
                    sb['start'].diff(shiftBlock['start'], 'hours') === 0 &&
                    sb['end'].diff(shiftBlock['end'], 'hours') === 0) {
                    if (reservationType !== 'All (Phone / SMS)' && sb[reservedType] === true && sb[otherType] === false &&
                        sb['reservation'] === null) {
                        console.log('shift block should be: ', sb);
                        retVal = sb;
                    }
                }

            });
        }
        return retVal;
    }

    deleteReservation(reservation: any): Promise<Boolean> {
        const retVal: Promise<Boolean> = new Promise<Boolean>((resolve, reject) => {
            this.entityDataService.delete(reservation).subscribe(result => {
                this.reservations.forEach((element, index) => {
                    if (element.id === reservation.id) {
                        this.reservations.splice(index, 1);
                        // this.updateDays();
                    }
                });
                resolve(true);
            });
        });

        return retVal;
    }

    createReservation(shiftBlock: ShiftBlock, day: Date, linkedProperties: any): Promise<Boolean> {
        const retVal: Promise<Boolean> = new Promise<Boolean>((resolve, reject) => {

            this.entityDataService.createShiftReservation({
                entityTypeId: 613, ParentID: shiftBlock.shift.id,
                properties: { date: day, fromdatetime: shiftBlock.start.format("M/D/YYYY H:mm"), enddatetime: shiftBlock.end.format("M/D/YYYY H:mm") }, linkedProperties
            }).subscribe(newShiftReservation => {
                if (newShiftReservation && newShiftReservation.id > 0) {
                    this.reservations.push(newShiftReservation);
                    // this.updateDays();
                    resolve(true);
                } else {
                    reject("Unable to reserve shift.  If you feel you are getting this message in error please refresh you browser and try again, if the problem persists please contact technical support.");
                }
            });
        });

        return retVal;
    }




    updateReservation(shiftReservationId: number, linkedProperties: any): Promise<Boolean> {
        const retVal: Promise<Boolean> = new Promise<Boolean>((resolve, reject) => {
            this.entityDataService.entitySyncUpdate([{
                id: shiftReservationId,
                properties: { prevreserver: '' },
                linkedProperties
            }]).subscribe(reservationArr => {

                const reservation = reservationArr[0];

                if (reservation && reservation.id > 0) {
                    this.reservations.forEach((oldRes, index) => {
                        if (oldRes.id === reservation.id) {
                            this.reservations[index] = reservation;
                            // this.updateDays();
                        }
                    });
                    resolve(true);
                } else {
                    reject("Update Server error, please try again.");
                }

            });
        });
        return retVal;
    }

    addReservationToShiftBlock(shiftBlock: ShiftBlock, reservation: any, shiftBlocks: ShiftBlock[]) {
        let changeReserverName: Boolean = true;

        if (shiftBlock.reservation && shiftBlock.reservation.id !== reservation.id) {
            console.log('shiftblock reservation', shiftBlock);
            console.log('reservation', reservation);
            throw "Can't add reservation with id: " + reservation.id + " to shiftBlock, it already has one";
            //send us an email
        }

        shiftBlock.reservation = reservation;

        if (shiftBlock.hasPhone && shiftBlock.hasSMS) {
            //if the reservation doesn't have both, we need to split it up into 2 shiftBlocks
            if (!reservation.properties.shiftphonereservation || !reservation.properties.shiftsmsreservation) {
                changeReserverName = false;
                shiftBlock.reservation = null;
                const shiftBlockPhone: ShiftBlock = shiftBlock.createCopy();
                const shiftBlockSMS: ShiftBlock = shiftBlock.createCopy();
                shiftBlockPhone.hasSMS = false;
                shiftBlockSMS.hasPhone = false;

                if (reservation.properties.shiftphonereservation) {
                    shiftBlockPhone.reservation = reservation;
                    shiftBlockPhone.reserverName = reservation.properties.shiftphonereservation;
                } else {
                    shiftBlockSMS.reservation = reservation;
                    shiftBlockSMS.reserverName = reservation.properties.shiftsmsreservation;
                }

                this.removeShiftBlock(shiftBlock, shiftBlocks);


                const existingPhone = shiftBlocks.find(sb => sb.shift.id === shiftBlock.shift.id && sb.date === shiftBlock.date &&
                  sb.hasPhone === true);
                const existingSMS = shiftBlocks.find(sb => sb.shift.id === shiftBlock.shift.id && sb.date === shiftBlock.date &&
                  sb.hasSMS === true);
                if (existingPhone) {
                    // see if it, or us has a reservation
                    if (shiftBlockPhone.reservation) {
                        this.removeShiftBlock(existingPhone, shiftBlocks);
                        this.pushShiftBlock(shiftBlockPhone, shiftBlocks);
                    }
                } else
                    this.pushShiftBlock(shiftBlockPhone, shiftBlocks);

                if (existingSMS) {
                    // see if it, or us has a reservation
                    if (shiftBlockSMS.reservation) {
                        this.removeShiftBlock(existingSMS, shiftBlocks);
                        this.pushShiftBlock(shiftBlockSMS, shiftBlocks);
                    }
                } else
                    this.pushShiftBlock(shiftBlockSMS, shiftBlocks);
            }
        }

        if (changeReserverName) {

            if (shiftBlock.hasPhone) {
                if (reservation.properties.shiftphonereservation && reservation.properties.shiftphonereservation.length > 0) {
                  shiftBlock.reserverName = reservation.properties.shiftphonereservation;
                }

            } else {
              if (reservation.properties.shiftsmsreservation && reservation.properties.shiftsmsreservation.length > 0) {
                shiftBlock.reserverName = reservation.properties.shiftsmsreservation;
              }
            }
        }
    }

    pushShiftBlock(shiftBlock: ShiftBlock, shiftBlocks: ShiftBlock[]) {
        shiftBlocks.push(shiftBlock);
    }

    removeShiftBlock(shiftBlock: ShiftBlock, shiftBlocks: ShiftBlock[]) {
        let index = shiftBlocks.indexOf(shiftBlock);
        if (index > -1)
            shiftBlocks.splice(index, 1);
    }

    updateReservedDaysShiftBlocks() {
        this.entityDataService.getUserList().subscribe(test => {});
        this.loadReservedShifts$(this.calSelectedDate, this.calSelectedEndDate).subscribe(result => {
            this.allReservations$.next(result);
        });
    }



    updateDaysShiftBlocks() {
        this.loadAllShiftReservations$(this.calSelectedDate, this.calSelectedEndDate).subscribe(result => {
            this.reservations = result.data;
            this.loadAllShiftAvailabilityLists$(this.calSelectedDate, this.calSelectedEndDate).then(innerResult => {
                this.shiftBlocks = [];
                this.availabilityLists = innerResult;
                this.updateDaysShiftBlocksInternal(this.shiftBlocks, this.reservations, this.availabilityLists,
                    this.calSelectedDate, this.calSelectedEndDate, false);
            });

        });
    }

    updateMonthlyShiftBlocks() {
        const selectedDate = new Date(this.calSelectedDate);
        const selectedMonthFirstDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1);
        const selectedMonthLastDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 1);
        this.loadAllShiftReservations$(selectedMonthFirstDate, selectedMonthLastDate).subscribe(result => {
            this.reservations = result.data;
            this.loadAllShiftAvailabilityLists$(selectedMonthFirstDate, selectedMonthLastDate).then(innerResult => {
                this.shiftBlocks = [];
                this.availabilityLists = innerResult;
                this.updateDaysShiftBlocksInternal(this.shiftBlocks, this.reservations, this.availabilityLists,
                    selectedMonthFirstDate, selectedMonthLastDate, false);
                this.userFilterForAvailability = [];
                this.fillInUserFilterForAvailability();
            });
        });
    }

    private updateDaysShiftBlocksInternal(shiftBlocks: any[], reservations: any[], availabilityLists: any[], startDate: Date, endDate: Date, notUpdateDays: boolean) {
        const numDays = moment(endDate).diff(startDate, 'days');
        console.log('num days', numDays);
        for (let i = 0; i < numDays; i++) {
            const currDate = moment(startDate).add(i, 'd');
            const currDay = currDate.day(); //0 = Sunday

            //see which shifts apply to this day
            this.shifts.forEach(shift => {
                if (currDay == 0 && shift.properties.dayofweeksu == 1 ||
                    currDay == 1 && shift.properties.dayofweekm == 1 ||
                    currDay == 2 && shift.properties.dayofweekt == 1 ||
                    currDay == 3 && shift.properties.dayofweekw == 1 ||
                    currDay == 4 && shift.properties.dayofweekth == 1 ||
                    currDay == 5 && shift.properties.dayofweekf == 1 ||
                    currDay == 6 && shift.properties.dayofweeks == 1) {
                    // had a shift without a shift group, so prevent those from geltting in (not sure how it was even saved)
                    if (this.findShiftGroup(shift.parentIds[0])) {
                        const shiftGroupWrapper = this.findShiftGroup(shift.parentIds[0]);
                        const schedule = this.findSchedule(shiftGroupWrapper.shiftGroup.linkedProperties.schedule.id);
                        const sgStartDate = moment(new Date(shiftGroupWrapper.shiftGroup.properties.startdate));
                        const sgEndDate = shiftGroupWrapper.shiftGroup.properties.enddate ?
                            moment(new Date(shiftGroupWrapper.shiftGroup.properties.enddate)) : moment(endDate);

                        // shiftgroup start & end date check
                        if (sgStartDate.isSameOrBefore(currDate) && sgEndDate.isSameOrAfter(currDate)) {
                            // exception days off
                            let skip = false;
                            this.exceptionDaysOff.forEach(edo => {
                                //console.log("EDO", edo);
                                const edoStartDate = moment(new Date(edo.properties.startdate));
                                const edoEndDate = moment(new Date(edo.properties.enddate));
                                if (edo.linkedProperties.shiftgroup.id === shiftGroupWrapper.shiftGroup.id &&
                                    edoStartDate.isSameOrBefore(currDate) && edoEndDate.isSameOrAfter(currDate)) {
                                    skip = true;
                                }
                            });
                            if (!skip) {
                                const sbStart = moment(currDate.format("LL") + " " + shift.properties.starttime);
                                const sbEnd = moment(currDate.format("LL") + " " + shift.properties.endtime);
                                if (sbEnd.isSameOrBefore(sbStart) || shift.properties.endtime === '12:00 AM') {
                                    sbEnd.add(1, 'd');
                                }
                                const newShiftBlock: ShiftBlock = new ShiftBlock(
                                    shift,
                                    shift.properties.name,
                                    shiftGroupWrapper,
                                    currDate.toDate(),
                                    sbStart,
                                    sbEnd,
                                    null,
                                    false,
                                    this.shiftHasPhone(shift),
                                    this.shiftHasSMS(shift),
                                    '',
                                    false,
                                    false,
                                    null,
                                    shift.properties.isavailabilityenabled_cfcid && shift.properties.isavailabilityenabled_cfcid === '333'
                                );
                                let lockDownDays = 5;
                                let lockDownDaysSupervisorOnly = 2;
                                if (schedule.properties.lockdowndays != null && parseInt(schedule.properties.lockdowndays) >= 0) {
                                    lockDownDays = parseInt(schedule.properties.lockdowndays);
                                }
                                if (schedule.properties.lockdowndays2 != null && parseInt(schedule.properties.lockdowndays2) >= 0) {
                                    lockDownDaysSupervisorOnly = parseInt(schedule.properties.lockdowndays2);
                                }

                                if (lockDownDays > 0) {
                                    newShiftBlock.isLockedDown = (moment(currDate).diff(moment(), 'days') < lockDownDays);
                                } else {
                                    newShiftBlock.isLockedDown = false;
                                }

                                if (lockDownDaysSupervisorOnly > 0) {
                                    // tslint:disable-next-line:max-line-length
                                    newShiftBlock.isLockedDownSupervisorOnly = (moment(currDate).diff(moment(), 'days') < lockDownDaysSupervisorOnly);
                                } else {
                                    newShiftBlock.isLockedDownSupervisorOnly = false;
                                }




                                this.pushShiftBlock(newShiftBlock, shiftBlocks);

                                // see if there are any reservations for this shiftBlock
                                reservations.forEach(res => {
                                    if (res.properties.date) {
                                        if (res.properties.date.length > 0 && moment(res.properties.date).isSameOrAfter(currDate) &&
                                            moment(res.properties.date).isBefore(moment(currDate).add(1, 'd')) && res.parentIds.find(x => x == shift.id)) {
                                            this.addReservationToShiftBlock(newShiftBlock, res, shiftBlocks);
                                        }
                                    }
                                });

                                // see if there are any availability list for this shiftBlock
                                availabilityLists.forEach(alist => {
                                    if (alist.properties.date) {
                                        if (alist.properties.date.length > 0 && moment(alist.properties.date).isSameOrAfter(currDate) &&
                                            moment(alist.properties.date).isBefore(moment(currDate).add(1, 'd')) &&
                                            alist.linkedProperties.shift && alist.linkedProperties.shift.id === shift.id) {
                                            newShiftBlock.availabilityList = alist;
                                        }
                                    }
                                });
                            }
                        }
                    }
                }
            });
        }

        this.shiftGroups.forEach(sg => {
            sg.shifts.forEach(s => {
                s.shiftBlockBoth = null;
                s.shiftBlockPhone = null;
                s.shiftBlockSMS = null;
            });
        })

        shiftBlocks.forEach(newShiftBlock => {
            if (newShiftBlock.shiftGroupWrapper) {
                const shiftW = newShiftBlock.shiftGroupWrapper.shifts.find(s => s.shift.id == newShiftBlock.shift.id);
                if (shiftW) {
                    if (newShiftBlock.hasPhone && newShiftBlock.hasSMS)
                        shiftW.shiftBlockBoth = newShiftBlock;
                    else if (newShiftBlock.hasPhone)
                        shiftW.shiftBlockPhone = newShiftBlock;
                    else
                        shiftW.shiftBlockSMS = newShiftBlock;
                }
            }
        })

        const days = [];
        let tempDate = startDate;
        let prevEnd = null;
        while (tempDate < endDate) {
            const newDay = {
                date: moment(tempDate),
                isToday: moment(tempDate).format('DDD') === moment().format('DDD') &&
                    new Date(tempDate).getFullYear() === new Date().getFullYear(),
                shiftBlocks: [],
                shiftGroups: []
            };

            //now add any shift blocks into this day
            shiftBlocks.forEach(sb => {
                if (newDay.date.format('l') === sb.start.format('l')) {
                    newDay.shiftBlocks.push(sb);
                }
            });

            //now add any shift groups into this day
            this.shiftGroups.forEach(sg => {
                const currShiftGroup = new ShiftGroupWrapper(sg.shiftGroup, []);
                sg.shifts.forEach(s => {
                    const newShiftW = new ShiftWrapper(s.shift, null, null, null);
                    currShiftGroup.shifts.push(newShiftW);
                    newDay.shiftBlocks.forEach(sb => {
                        if (sb.shift.id == s.shift.id) {
                            if (sb.hasPhone && sb.hasSMS)
                                newShiftW.shiftBlockBoth = sb;
                            else if (sb.hasPhone)
                                newShiftW.shiftBlockPhone = sb;
                            else
                                newShiftW.shiftBlockSMS = sb;
                        }

                    })

                })
                newDay.shiftGroups.push(currShiftGroup);


            });

            this.setHoursOfDay(newDay, prevEnd);
            prevEnd = null;
            // if any shiftblocks ends on the next day
            for (let i = 0; i < newDay.shiftBlocks.length; i++) {
                const sb = newDay.shiftBlocks[i];
                if (sb.start.format('DDD') !== sb.end.format('DDD')) {
                    if (prevEnd === null || prevEnd !== null && sb.end.isAfter(prevEnd)) {
                        prevEnd = sb.end;
                    }
                }
            }

            days.push(newDay);

            tempDate = moment(tempDate).add(1, 'd').toDate();

        }

        days.forEach(day => {
            day.shiftBlocks.forEach(sb => {
                if (sb.reservation && ((sb.reservation.properties.shiftphonereservation
                    && sb.reservation.properties.shiftphonereservation === this.currentUser.name) ||
                    (sb.reservation.properties.shiftsmsreservation &&
                        sb.reservation.properties.shiftsmsreservation === this.currentUser.name))) {
                    sb.isUsersShift = true;
                } else if (sb.shift.canModify) {
                     sb.canModify = sb.shift.canModify;
                 }
            });
        });

        //  console.log(days);
        //    console.log(shiftBlocks);
        if (!notUpdateDays) {
            this.days$.next(days);
        }
    }

    shiftHasPhone(shift): Boolean {
        return shift.properties.shifttype.includes("Phone");
    }

    shiftHasSMS(shift): Boolean {
        return shift.properties.shifttype.includes("SMS");
    }

    getShiftOffset(shiftBlock: ShiftBlock, startHour: number, length: number): number {
        const start = shiftBlock.start.toDate().getHours() + shiftBlock.start.toDate().getMinutes() / 60;
        return ((start - startHour) / length) * 100;
    }

    getShiftWidth(shiftBlock: ShiftBlock, length: number): number {
        const start = shiftBlock.start.toDate().getHours() + shiftBlock.start.toDate().getMinutes() / 60;
        let end = shiftBlock.end.toDate().getHours() + shiftBlock.end.toDate().getMinutes() / 60;
        if (start >= end) {
            end += 24;
        }
        return ((end - start) / length) * 100;
    }

    loadAllShifts() {
        return this.entityDataService.getAll(596).subscribe(
            result => {
                console.log('Shifts', result);
                // determine what shifts to show.
                if (!this.isSupervisor) {
                    // filter out based on the current users type and the shift type.
                    console.log("-----------------User", this.currentUser);

                    // removed, using security on Ripple instead.  //ej 06-12-2019

                    // if (this.currentUser.properties.usertype === "Volunteer") {
                    //     // tslint:disable-next-line:max-line-length
                    //     result = result.filter(item => item.properties.allvolunteers === "1" || (item.linkedProperties && item.linkedProperties.users && item.linkedProperties.users.id === this.currentUser.id));
                    // } else if (this.currentUser.properties.usertype === "Staff") {
                    //     // tslint:disable-next-line:max-line-length
                    //     result = result.filter(item => item.properties.allstaff === "1" || (item.linkedProperties && item.linkedProperties.users && item.linkedProperties.users.id === this.currentUser.id));
                    // }

                    //result = this.filterShifts(this.currentUser.roles, result);
                }
                this.shifts = result;
                this.checkIfLoaded();
            });
    }

    filterShifts(roles, shifts) {
        return shifts.filter(shift => {
            console.log("Shift...", shift);
            if (shift.properties.roles && shift.properties.roles.length > 0) {
                let ok = false;
                if (shift.linkedProperties && shift.linkedProperties.users && shift.linkedProperties.users.id === this.currentUser.id) {
                    ok = true;
                } else {
                    const shiftRolesAllowed = JSON.parse(shift.properties.roles);
                    console.log('Shift Role', shiftRolesAllowed);
                    for (let j = 0; j < roles.length; j++) {
                        for (let i = 0; i < shiftRolesAllowed.length; i++) {
                            if (shiftRolesAllowed[i].checked === 1 && shiftRolesAllowed[i].ID === roles[j].ID) {
                                ok = true;
                                break;
                            }
                        }
                        if (ok) {
                            break;
                        }
                    }
                    return ok;
                }
            } else {
                if (this.currentUser.properties.usertype === "Volunteer" && shift.properties.allvolunteers === "1") {
                    return true;
                } else if (this.currentUser.properties.usertype === "Staff" && shift.properties.allstaff === "1") {
                    return true;
                }
                return false; // may need to change this later but for now leave.
            }
        });
    }


    loadAllShiftGroups() {
        return this.entityDataService.getAll(595).subscribe(
            result => {
                result.sort(this.sortByOrderIndex);
                this.shiftGroups = result.map(shiftGroup => new ShiftGroupWrapper(shiftGroup, []));
                console.log('shiftgroups: ', this.shiftGroups);
                this.checkIfLoaded();
            });
    }

    loadAllSchedules() {
        return this.entityDataService.getAll(594).subscribe(
            result => {
                this.schedules = result;
                this.checkIfLoaded();
                console.log('schedule: ', result);
            });
    }

    loadAllExceptionDaysOff() {
        return this.entityDataService.getAll(601).subscribe(
            result => {
                this.exceptionDaysOff = result;
                this.checkIfLoaded();
                console.log('exceptionDaysOff: ', result);
            });
    }

    loadAllShiftReservations() {
        this.loadAllShiftReservations$(this.calSelectedDate, this.calSelectedEndDate).subscribe(
            result => {
                this.reservations = result.data;
                this.checkIfLoaded();
            });
    }


    loadReservedShifts$(startDate: Date, endDate: Date): Observable<any> {
        const start = moment(startDate).add(-1, 'd').format("M/D/YYYY");
        const end = moment(endDate).add(1, 'd').format("M/D/YYYY");
        return this.entityDataService.getAllReservations(start, end);
    }


    loadAllShiftReservations$(startDate: Date, endDate: Date): Observable<any> {
        const start = moment(startDate).add(-1, 'd').format("M/D/YYYY");
        const end = moment(endDate).add(1, 'd').format("M/D/YYYY");

        return this.entityDataService.getPageAll(613, 0, 9999999, '', `date>=${start},date<=${end}`, '');


        //return this.entityDataService.getAllWithFilter(613, `date>=${start},date<=${end}`);
    }

    loadAllAssignees(entityTypeID: number, arr: any[]) {
        return this.entityDataService.getAll(entityTypeID).subscribe(
            result => {
                arr.push(...result)
                arr.sort(function (a, b) {
                    if (a.name.toLowerCase() < b.name.toLowerCase())
                        return -1;
                    else if (b.name.toLowerCase() < a.name.toLowerCase())
                        return 1;
                    else
                        return 0;
                });
                this.checkIfLoaded();
            });
    }

    sortByOrderIndex(n1, n2) {

        if (n1.properties.orderindex > n2.properties.orderindex) {
            return 1;
        }
        if (n1.properties.orderindex < n2.properties.orderindex) {
            return -1;
        }
        return 0;
    }

    private findShiftGroup(id) {
        return this.shiftGroups.find(x => x.shiftGroup.id === id);
    }

    private findSchedule(id) {
        return this.schedules.find(x => x.id === id);
    }

    private checkIfLoaded() {
        if (this.shifts !== undefined && this.shiftGroups !== undefined && this.allUsers !== undefined
            && this.exceptionDaysOff !== undefined && this.schedules !== undefined) {
            for (var i = 0; i < this.shiftGroups.length; i++) {
                const sg = this.shiftGroups[i];
                sg.shifts = [];
                this.shifts.sort(this.sortByOrderIndex); //sort the shift that are going to the shiftgroups.
                this.shifts.forEach(shift => {
                    if (shift.parentIds.find(x => x == sg.shiftGroup.id)) {
                        sg.shifts.push(new ShiftWrapper(shift, null, null, null));
                        shift.properties.orderindex += i * 100; //order shifts based on shift groups
                    }
                });
            }

            //sort the shift again now that we have multiplied them by their shift groups index.
            this.shifts.sort(this.sortByOrderIndex);
            this.loaded$.next(true);
        }
    }

    // after reserve a reservation
    // not used any more
    // private updateDays() {
    //     const days = this.days$.getValue();
    //     if (days.length > 10) {
    //         // monthly
    //         const selectedDate = new Date(this.calSelectedDate);
    //         const selectedMonthFirstDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1);
    //         const selectedMonthLastDate = new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 1);
    //         this.shiftBlocks = [];
    //         this.updateDaysShiftBlocksInternal(this.shiftBlocks, this.reservations, selectedMonthFirstDate,
    //             selectedMonthLastDate, false);
    //     } else {
    //         // weekly
    //         this.shiftBlocks = [];
    //         this.updateDaysShiftBlocksInternal(this.shiftBlocks, this.reservations, this.calSelectedDate, this.calSelectedEndDate, false);
    //     }
    // }

    exportHoursAsExcel(startDate: Date): Promise<boolean> {
        const endDate = new Date(startDate.getFullYear() + 1, startDate.getMonth(), 1);
        const retVal: Promise<boolean> = new Promise<boolean>((resolve, reject) => {
            this.loadAllShiftReservations$(startDate, endDate).subscribe(
                result => {
                    this.reservationsThisYear = result.data;
                    this.checkIfLoaded();
                    console.log("all the reservations: ", this.reservationsThisYear);
                    this.yearShiftBlocks = [];
                    this.updateDaysShiftBlocksInternal(this.yearShiftBlocks, this.reservationsThisYear, [], startDate, endDate, true);
                    console.log("all the users: ", this.allUsers);
                    this.toJsonForExcel();
                    this.exportAsExcelFile(this.usersHours, 'Support_&_Information_Line_Volunteer_Hours', startDate);
                    resolve(true);
                });
            // reject("Create Server error, please try again.");
        });
        return retVal;
    }


    public toJsonForExcel() {
        this.usersHours = [];
        this.allUsers.forEach(user => {
            if (user['name'] !== '') {
                const userHours = {
                    Name: user['name'],
                    Jan: 0,
                    Feb: 0,
                    Mar: 0,
                    Apr: 0,
                    May: 0,
                    June: 0,
                    July: 0,
                    Aug: 0,
                    Sept: 0,
                    Oct: 0,
                    Nov: 0,
                    Dec: 0,
                    Total: 0
                };
                this.yearShiftBlocks.forEach(sb => {
                    if (sb['reserverName'] === user['name']) {
                        const month = sb['date'].getMonth();
                        userHours[this.months[month]] += sb['end'].diff(sb['start'], 'hours');
                    }
                });
                this.months.forEach(month => {
                    userHours['Total'] += userHours[month];
                });

                this.usersHours.push(userHours);
            }
        });
        const totalJson = {
            Name: 'Total',
            Jan: 0,
            Feb: 0,
            Mar: 0,
            Apr: 0,
            May: 0,
            June: 0,
            July: 0,
            Aug: 0,
            Sept: 0,
            Oct: 0,
            Nov: 0,
            Dec: 0,
            Total: 0
        };
        this.months.forEach(month => {
            this.usersHours.forEach(userHours => {
                totalJson[month] += userHours[month];
            });
        });
        this.usersHours.forEach(userHours => {
            totalJson['Total'] += userHours['Total'];
        });
        this.usersHours.push(totalJson);
        console.log(this.usersHours);
    }


    public exportAsExcelFile(json: any[], excelFileName: string, startDate: Date): void {
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
        const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, excelFileName, startDate);
    }

    private saveAsExcelFile(buffer: any, fileName: string, startDate: Date): void {
        const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
        let endDate = new Date(startDate.getFullYear() + 1, startDate.getMonth(), 1);
        endDate = moment(endDate).isAfter(moment(new Date())) ? new Date() : endDate;
        const dateString = 'from_' + startDate.toLocaleDateString() + '_to_' + endDate.toLocaleDateString();
        FileSaver.saveAs(data, fileName + '_export_' + dateString + EXCEL_EXTENSION);
    }

    ifSendReplacementEmail(shiftBlock: ShiftBlock): boolean {
        let retVal = false;
        if (shiftBlock.reservation) {
            const reservation = shiftBlock.reservation;

            const prevReserver = reservation.properties.prevreserver;
            const currentReserver = shiftBlock.reserverName;
            if (prevReserver === currentReserver) {
                retVal = true;
            }
        }
        return retVal;
    }

    setHoursOfDay(day: any, prevEnd: any) {
        const shiftBlocks = day.shiftBlocks;
        let startHour = 0;
        let endHour = 0;
        if (shiftBlocks.length <= 0 && prevEnd === null) {
            day['hours'] = this.scheduleHours;
            return;
        } else if (shiftBlocks.length <= 0 && prevEnd !== null) {
            startHour = 0;
            endHour = prevEnd.toDate().getHours() + prevEnd.toDate().getMinutes() / 60;
        } else {
            let start = shiftBlocks[0].start;
            let end = shiftBlocks[0].end;
            for (let i = 1; i < shiftBlocks.length; i++) {
                const sb = shiftBlocks[i];
                if (sb.start.isBefore(start)) {
                    start = sb.start;
                }
                if (sb.end.isAfter(end)) {
                    end = sb.end;
                }
            }
            if (prevEnd !== null) {
                end = end.isAfter(prevEnd) ? end : prevEnd;
            }
            startHour = start.toDate().getHours() + start.toDate().getMinutes() / 60;
            endHour = end.toDate().getHours() + end.toDate().getMinutes() / 60;
            endHour = Math.ceil(endHour);
            startHour = Math.floor(startHour);
            if (end.format('DDD') !== start.format('DDD')) {
                endHour = 24;
            }
            if (prevEnd !== null) {
                startHour = 0;
            }
        }

        const hours: number[] = [];
        for (let i = startHour; i < endHour; i++) {
            hours.push(i);
        }
        day['hours'] = hours;

    }

    shortenName(name: string, max: number): string {
        let retVal = name;
        if (name && name.length > max) {
            const firstName = name.split(' ')[0];
            if (firstName.length > max) {
                retVal = firstName.slice(0, max - 3) + '...';
            } else {
                retVal = firstName;
            }
        }
        return retVal;
    }

    getFutureShiftBlocks(shiftBlock: ShiftBlock, endDate: Date): Promise<any[]> {
        const retVal: Promise<any[]> = new Promise<any[]>((resolve, reject) => {
            const retArr = [];
            const startDate = new Date(shiftBlock.start.format('M/D/YYYY'));
            endDate = new Date(moment(endDate).add(1, 'days').format('M/D/YYYY'));
            this.loadAllShiftReservations$(startDate, endDate).subscribe(result => {
                const allReservations = result.data;
                this.loadAllShiftAvailabilityLists$(startDate, endDate).then(innerResult => {
                    const allAvailabilityList = innerResult;
                    const allShiftBlocks = [];
                    this.updateDaysShiftBlocksInternal(allShiftBlocks, allReservations, allAvailabilityList, startDate, endDate, true);
                    allShiftBlocks.forEach(sb => {
                        if (shiftBlock.shift.id === sb.shift.id && !this.isAvailabilityMode(sb)) {
                            retArr.push(sb);
                        }
                    });
                    resolve(retArr);
                });
            });
        });
        return retVal;
    }

    // shift availability list
    loadAllShiftAvailabilityLists() {
        this.loadAllShiftAvailabilityLists$(this.calSelectedDate, this.calSelectedEndDate).then(result => {
            if (result) {
                this.availabilityLists = result;
                this.checkIfLoaded();
            }
        });
    }

    loadAllShiftAvailabilityLists$(startDate: Date, endDate: Date): Promise<any> {
        const start = moment(startDate).add(-1, 'd').format('M/D/YYYY');
        const end = moment(endDate).add(1, 'd').format('M/D/YYYY');

        return new Promise((resolve, reject) => {
            if (!this.hasAvailability)
                resolve([]);
            else {
            this.entityDataService.getPageAll(666, 0, 9999999, '', `date>=${start},date<=${end}`, '').subscribe(result => {
                if (!result.data) {
                    resolve([]);
                    return;
                }
                const availabilityLists = result.data;
                this.loadAllShiftAvailabilityListRows$(availabilityLists).subscribe(innerResult => {
                    const availabilityListRows = innerResult.data;
                    for (const row of availabilityListRows) {
                        const alist = availabilityLists.find(a => a.id === row.linkedProperties.shiftavailabilitylist.id);
                        if (alist) {
                            if (!alist.rows) {
                                alist.rows = [];
                            }
                            alist.rows.push(row);
                        }
                    }
                    console.log('availability lists: ', availabilityLists);
                    resolve(availabilityLists);
                });
            });
        }
        });
    }

    loadAllShiftAvailabilityListRows$(availabilityLists: any[]) {
        const advancedFilter = this.isSupervisor ? '' : `user_lid=${this.currentUser.id}`;
        const advancedFilterUnion = availabilityLists.map(a => `shiftavailabilitylist_lid=${a.id}`).join(',');
        return this.entityDataService.getPageAll(667, 0, 9999999, '', advancedFilter, '', advancedFilterUnion);
    }

    createAvailabilityList(shiftBlock: ShiftBlock, day: Date, ishandled_cfcid = 334) {
        const linkedProperties = {
            shift: {id: shiftBlock.shift.id},
            shiftgroup: {id: shiftBlock.shiftGroupWrapper.shiftGroup.id}
        };
        return this.entityDataService.create({
            entityTypeId: 666,
            parentID: shiftBlock.shift.id,
            properties: {
                date: day,
                ishandled_cfcid
            },
            linkedProperties
        });
    }

    updateAvailabilityList(alistId, properties, linkedProperties) {
        return this.entityDataService.entitySyncUpdate([{
            id: alistId,
            properties,
            linkedProperties
        }]);
    }

    createAvailabilityListRow(shiftBlock: ShiftBlock, day: Date, user = null): Promise<any> {
        const linkedProperties = {
            user: {id: user ? user.id : this.currentUser.id}
        };
        if (shiftBlock.availabilityList) {
            // availability list exists
            linkedProperties['shiftavailabilitylist'] = {id: shiftBlock.availabilityList.id};
            return this.entityDataService.create({
                entityTypeId: 667,
                parentID: shiftBlock.availabilityList.id,
                linkedProperties
            }).toPromise();
        } else {
            // first create availability list
            return new Promise<any>((resolve, reject) => {
                this.createAvailabilityList(shiftBlock, day).subscribe(alist => {
                    if (alist && alist.id > 0) {
                        shiftBlock.availabilityList = alist;
                        linkedProperties['shiftavailabilitylist'] = {id: alist.id};
                        this.entityDataService.create({
                            entityTypeId: 667,
                            parentID: shiftBlock.availabilityList.id,
                            linkedProperties
                        }).subscribe(alistrow => {
                            resolve(alistrow);
                        });
                    } else {
                        reject('Fail to create new availability list!');
                    }
                });
            });
        }
    }

    deleteAvailabilityListRow(row) {
        return this.entityDataService.delete(row);
    }

    isAvailabilityMode(shiftBlock: ShiftBlock) {
        return shiftBlock.isAvailabilityEnabled && !this.ifHandled(shiftBlock);
    }

    isAvailabilityListFilledAndNotAssigned(shiftBlock: ShiftBlock) {
        return shiftBlock.isAvailabilityEnabled &&
            shiftBlock.availabilityList &&
            shiftBlock.availabilityList.rows &&
            shiftBlock.availabilityList.rows.length > 0 &&
            !shiftBlock.reservation;
    }

    calculateNumOfShiftsReserved(today: Date, days: number): Promise<any> {
        const start = moment(today).subtract(days, 'd').toDate();
        return this.loadAllShiftReservations$(start, today).toPromise().then(result => {
            const reservations = result.data;
            console.log('reservations', reservations);
            for (const reservation of reservations) {
                let shiftphonereservation = -1;
                if (reservation.linkedProperties && reservation.linkedProperties.shiftphonereservation) {
                    shiftphonereservation = reservation.linkedProperties.shiftphonereservation.id;
                    const user = this.allUsers.find(u => u.id === shiftphonereservation);
                    if (user) {
                        if (!user.numOfShifts) {
                            user.numOfShifts = 0;
                        }
                        user.numOfShifts ++;
                    }
                }

                if (reservation.linkedProperties && reservation.linkedProperties.shiftsmsreservation
                    && reservation.linkedProperties.shiftsmsreservation.id !== shiftphonereservation) {
                    const user = this.allUsers.find(u => u.id === reservation.linkedProperties.shiftsmsreservation.id);
                    if (user) {
                        if (!user.numOfShifts) {
                            user.numOfShifts = 0;
                        }
                        user.numOfShifts ++;
                    }
                }
            }
            console.log('all users after calculation', this.allUsers);
        });
    }

    async autoAssign(start: Date, end: Date) {
        console.log('auto assign start');
        const shiftBlocks = [];
        let reservations = [];
        let availabilityLists = [];
        await this.loadAllShiftReservations$(start, end).toPromise().then(result => {
            reservations = result.data;
        });

        console.log('all the reservations: ', reservations);

        await this.loadAllShiftAvailabilityLists$(start, end).then(result => {
            availabilityLists = result;
        });

        console.log('all the availabilityLists: ', availabilityLists);

        this.updateDaysShiftBlocksInternal(shiftBlocks, reservations, availabilityLists, start, end, true);

        console.log('all shiftblocks', shiftBlocks);


        for (const sb of shiftBlocks) {
            if (sb.isAvailabilityEnabled && !sb.reservation &&
                sb.availabilityList && sb.availabilityList.rows && sb.availabilityList.rows.length === 1) {
                const reserverId = sb.availabilityList.rows[0].linkedProperties.user.id;
                let reservationType = '';
                if (sb.hasPhone && sb.hasSMS) {
                    reservationType = 'All (Phone / SMS)';
                } else if (sb.hasPhone) {
                    reservationType = 'Phone Only';
                } else if (sb.hasSMS) {
                    reservationType = 'SMS Only';
                }
                const promise = await new Promise((resolve, reject) => {
                    if (!this.checkSimultaneous(sb, reserverId, reservationType)) {
                        this.createReservation(
                        this.whichShiftBlock(moment(sb.date), sb, reservationType),
                        sb.date, this.createLinkedProperties(reserverId, sb, reservationType)).then(success => {
                            this.notifyAvailabilityShiftAssigned(reserverId, sb).subscribe(result => {
                                if (this.isAvailabilityMode(sb)) {
                                    this.updateAvailabilityList(
                                        sb.availabilityList.id,
                                        { ishandled_cfcid: 333 },
                                        { }
                                    ).subscribe(innerResult => {
                                        resolve(true);
                                    });
                                } else {
                                    resolve(true);
                                }
                            });
                        });
                    }
                    // else {
                    //     this.deleteAvailabilityListRow(sb.availabilityList.rows[0]).subscribe(result => {
                    //         resolve(result);
                    //     });
                    // }
                });
            }
        }
    }

    createLinkedProperties(reserverId: number, shiftBlock: ShiftBlock, reservationType: string) {
        const linkedProperties = {
          shift: {id: shiftBlock.shift.id},
          shiftgroup: {id: shiftBlock.shiftGroupWrapper.shiftGroup.id}
        };

        const phoneCfim = 'shiftphonereservation';
        const smsCfim =  'shiftsmsreservation';

        if (reservationType === 'All (Phone / SMS)') {
          linkedProperties[phoneCfim] = { name: '', id: reserverId };
          linkedProperties[smsCfim] = { name: '', id: reserverId };

        } else if (reservationType === 'Phone Only') {
          linkedProperties[phoneCfim] = { name: '', id: reserverId };
          linkedProperties[smsCfim] = { name: '', id: NaN };
        } else if (reservationType === 'SMS Only') {
          linkedProperties[phoneCfim] = { name: '', id: NaN };
          linkedProperties[smsCfim] = { name: '', id: reserverId };
        }


        return linkedProperties;
    }
    notifyAvailabilityShiftAssigned(reserverId: number, shiftBlock: ShiftBlock) {
        const businessUnit = shiftBlock.shiftGroupWrapper.shiftGroup.properties.schedule;
        const dateString = moment(shiftBlock.date).format('dddd, MMMM Do, YYYY') +
          ' from ' + shiftBlock.start.format('h:mm A') + ' to ' + shiftBlock.end.format('h:mm A');
        const emailItem = {
          title: businessUnit + ' Shift Assignment',
          body: '<p>You are assigned to the following shift: </p>\
          <p>' + dateString + '</p>\
          <p> Type: ' + shiftBlock.shift.properties.shifttype + '</p>\
          <p>Thank you,</p>\
          <p>' + businessUnit + '</p><br/>',
          reserverId
        };
        return this.emailService.notifyAvailabilityShiftAssigned(emailItem);
    }

    notifyIfNoEnoughFilledAvailabilityShift() {
        this.emailService.notifyIfNoEnoughFilledAvailabilityShift().subscribe(result => {
            console.log('enough check', result);
        });
    }

    isScheduleAvailabilityEnabled() {
        if (!this.shiftBlocks) {
            return false;
        }
        const index = this.shiftBlocks.findIndex(sb => sb.isAvailabilityEnabled === true);
        return index >= 0;
    }

    addToUserFilterForAvailability(user: any) {
        if (!this.userFilterForAvailability.find(u => u.id === user.id)) {
            this.userFilterForAvailability.push(user);
        }
    }

    fillInUserFilterForAvailability() {
        if (!this.isSupervisor) {
            return;
        }
        // get availability lists and reservations from shiftBlocks
        // as the start date and end date and exception days off of shift groups may be changed
        // there may be some availability lists or reservations whose date is no more in the range
        const availabilityLists = this.shiftBlocks.map(sb => sb.availabilityList).filter(a => a);
        const reservations = this.shiftBlocks.map(sb => sb.reservation).filter(r => r);
        const idArrs = availabilityLists.map(alist => alist.rows ? alist.rows.map(row => row.linkedProperties.user.id) : []);
        let userIds = [];
        for (const arr of idArrs) {
            userIds = userIds.concat(arr);
        }
        // remove duplicate
        const uniqueUserIds = userIds.filter((elem, index, self) => index === self.indexOf(elem));

        for (const userId of uniqueUserIds) {
            const user = this.allUsers.find(u => u.id === userId);
            if (user) {
                // generate user color
                user.color = '#' + this.colorComponentToHex((user.id * 127) % 255) +
                    this.colorComponentToHex(((user.id + user.subscriberid) * 123) % 255) +
                    this.colorComponentToHex((user.id * 125 * user.subscriberid) % 255);

                // calculate alist
                user.numOfAvailability = userIds.filter(u => u === userId).length;

                // calculate reservation
                user.numOfReservation = reservations.filter(r =>
                    r.linkedProperties.shiftphonereservation && r.linkedProperties.shiftphonereservation.id === userId ||
                    r.linkedProperties.shiftsmsreservation && r.linkedProperties.shiftsmsreservation.id === userId).length;

                // checked
                user.isChecked = false;

                this.userFilterForAvailability.push(user);
            }

        }
        console.log('user filter for availability', this.userFilterForAvailability);
    }

    getUserColor(availabilityListRow) {
        const user = this.allUsers.find(u => u.id === availabilityListRow.linkedProperties.user.id);
        return user ? user.color : 'white';
    }

    ifUserColorPointShow(availabilityListRow, shiftBlock) {
        const user = this.allUsers.find(u => u.id === availabilityListRow.linkedProperties.user.id);
        let retVal = user ? user.isChecked : false;
        if (shiftBlock.reservation) {
            retVal = retVal && this.ifReservedByUser(user, shiftBlock);
        }

        return retVal;
    }

    sortUserFilterForAvailability() {
        this.userFilterForAvailability.sort((a, b) => {
            const aIsChecked = a.isChecked ? 1 : 0;
            const bIsChecked = b.isChecked ? 1 : 0;
            return bIsChecked - aIsChecked;
        });
    }


    ifReservedByUser(user, shiftBlock: ShiftBlock) {
        return user && shiftBlock.reservation && (
            (shiftBlock.reservation.linkedProperties.shiftphonereservation &&
            shiftBlock.reservation.linkedProperties.shiftphonereservation.id === user.id) ||
            (shiftBlock.reservation.linkedProperties.shiftsmsreservation &&
            shiftBlock.reservation.linkedProperties.shiftsmsreservation.id === user.id));
    }

    ifUserInAvailabilityList(user, shiftBlock: ShiftBlock) {
        return user && shiftBlock.availabilityList && shiftBlock.availabilityList.rows &&
            shiftBlock.availabilityList.rows.findIndex(r => r.linkedProperties.user.id === user.id) >= 0;
    }

    ifInAvailabilityFilter(shiftBlock: ShiftBlock) {
        const userIds = this.userFilterForAvailability.map(u => u.id);
        return shiftBlock.availabilityList && shiftBlock.availabilityList.rows &&
            shiftBlock.availabilityList.rows.findIndex(r => userIds.includes(r.linkedProperties.user.id)) >= 0;
    }

    ifHandled(shiftBlock: ShiftBlock) {
        return shiftBlock.availabilityList &&
            shiftBlock.availabilityList.properties.ishandled_cfcid &&
            shiftBlock.availabilityList.properties.ishandled_cfcid === '333';
    }

    getAvailabilityRowNum(shiftBlock: ShiftBlock) {
        return shiftBlock.availabilityList && shiftBlock.availabilityList.rows ?
            shiftBlock.availabilityList.rows.length : 0;
    }

    colorComponentToHex(c: number) {
        const hex = c.toString(16);
        return hex.length === 1 ? '0' + hex : hex;
    }

    isReserverChecked(reservation) {
        if (!reservation) {
            return false;
        }
        const user = this.allUsers.find(u =>
            (reservation.linkedProperties.shiftphonereservation &&
                reservation.linkedProperties.shiftphonereservation.id === u.id) ||
            (reservation.linkedProperties.shiftsmsreservation &&
                reservation.linkedProperties.shiftsmsreservation.id === u.id));
        return user ? user.isChecked : false;
    }

    isReservationMine(reservation) {
        if (!reservation || !this.currentUser) {
            return false;
        }
        return (reservation.linkedProperties.shiftphonereservation &&
            reservation.linkedProperties.shiftphonereservation.id === this.currentUser.id) ||
            (reservation.linkedProperties.shiftsmsreservation &&
                reservation.linkedProperties.shiftsmsreservation.id === this.currentUser.id);
    }



}

