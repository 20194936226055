import { Injectable, OnInit, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MessageService } from './message.service';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { catchError, map, tap, windowCount } from 'rxjs/operators';
import { environment as globalEnvironment} from '../../../environments/environment';
import { InternalCookieService } from '../cookie.service';
import { nextTick } from 'q';
import { EntityDataService } from './entity-data.service';
import { _getComponentHostLElementNode } from '@angular/core/src/render3/instructions';
import { Router } from '@angular/router';
import { AuthService } from '../Hubs/auth.service';
import { RouterService } from '../Hubs/router.service';
import { EnvironmentService } from 'src/app/Services/environment.service';
//todo: IzzyIonic Inspection

@Injectable({
  providedIn: "root",
})
export class UserService implements OnInit, OnDestroy {
  currentUser$: BehaviorSubject<any>;
  isMobileApp = globalEnvironment.isMobileApp;
  localEndpointForUserCheck = '';

  constructor(
    private http: HttpClient,
    private env: EnvironmentService,
    private messageService: MessageService,
    private cookieService: InternalCookieService,
    private entityDataService: EntityDataService,
    private router: Router,
    private authService: AuthService,
    private routerService: RouterService
  ) {
    this.info(
      "------------------ UserService Service Constructor ---------------------"
    );


    this.currentUser$ = new BehaviorSubject(null);
    // this.get().subscribe(user => {
    //     this.info('User', user);
    // });
    // if (this.isMobileApp) {
      this.authService.loggedIn.subscribe((isLoggedIn) => {
        if (isLoggedIn) {
          let subscribable = this.get();
          if (subscribable) {
            subscribable.subscribe((user) => {});
          }
        } else {
          // this.removeCurrentAppTokenFromUserTokens();
          // this.currentUser$.next(null);
        }
      });
    // }
  }

  getEndPointForUserCheck(): string {
    // let value = '';
    // if (!globalEnvironment.isMobileApp) {
    //   value = 'https://' + window.location.hostname;
    //   if (this.env.isLocalByUrl()) {
    //     value = `http://${window.location.hostname}:56696`;
    //   }
    // } else {
    //   value = this.cookieService.getCookie('lastEndpoint');
    // }
    return this.env.getRippleUrl();
  }

  ngOnInit() {}

  ngOnDestroy() {}

  IsTesting() {
    if (
      window.location.hostname.includes("testing") ||
      window.location.hostname.includes("localhost")
    ) {
      return true;
    }
    return false;
  }

  setStatus(user: any) {
    this.info("syncing user", user);
    this.entityDataService
      .entitySyncUpdate([
        {
          id: user.id,
          properties: { userinactive: user.properties.userinactive },
        },
      ])
      .subscribe((resp) => {});
    this.currentUser$.next(user);
  }

  setDefaultAudioDevices({id, input, output}) {
    this.info('syncing user defaults', {id, input, output});
    this.entityDataService
      .entitySyncUpdate([
        {
          id: id,
          properties: {
            defaultinputdevice: input,
            defaultoutputdevice: output,
          },
        },
      ])
      .subscribe((resp) => {});
  }

  removeCurrentAppTokenFromUserTokens() {
    const user = this.currentUser$.getValue();
    // this.info('user user user', user);
    if (user) {
      let userCurrentTokens = user.properties["json"]
        ? JSON.parse(user.properties["json"])
        : [];
      const currentAppToken = this.cookieService.getCookie("appToken");
      this.info("user service user current: " + JSON.stringify(user));
      this.info(
        "user service currentAppToken: " + JSON.stringify(currentAppToken)
      );
      this.info(
        "user service userCurrentTokens: " + JSON.stringify(userCurrentTokens)
      );
      if (userCurrentTokens.includes(currentAppToken)) {
        this.info(
          "user current tokens before change: " +
            JSON.stringify(userCurrentTokens)
        );
        userCurrentTokens.splice(userCurrentTokens.indexOf(currentAppToken), 1);
        this.info(
          "user current tokens after change: " +
            JSON.stringify(userCurrentTokens)
        );
        // tslint:disable-next-line: max-line-length
        this.entityDataService
          .entitySyncUpdate([
            {
              id: user.id,
              // tslint:disable-next-line: max-line-length
              properties: { json: JSON.stringify(userCurrentTokens) },
            },
          ])
          .subscribe((resp) => {
            this.info("json update result", resp);
          });
      }

    }
  }

  setActiveUser(user: any) {
        this.info('User Service: ', user);
        if (user.properties.usertype === 'Supervisor')
            user.playNotificationsForOthers = true;
        this.currentUser$.next(user);
  }

    get(): Observable<any> {
        this.info('----------- GET USER ----------------');
        if (this.cookieService.getCookie('token')) {
        return this.http.get<any[]>(this.getEndPointForUserCheck() + '/api/rippleuser/' + this.cookieService.getCookie('token'),
            {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Access-Control-Allow-Headers': 'Content-Type',
                    'token': this.cookieService.getCookie('token')
                })
            }).pipe(
                tap(user => {
                    this.log('fetched User');
                    this.setActiveUser(user);
                }),
                catchError(this.handleError <any[]>('get', []))
            );

        } else {
            this.info('redirect');
            // if (window.location.pathname.includes('resource-dashboard') ) {
            //   // don't do anything.  No need for user in this case.
            // } else if (window.location.pathname.includes('shiftReplacement')) {
            //     window.location.href = window.location.protocol + '//' + window.location.hostname + (this.IsTesting() ? ':56696' : '') + '/Login.aspx?ReturnURL=' + window.location.href;
            // } else {
            //     if (window.location.hostname.includes('ccasa')) {
            //         window.location.href = window.location.protocol + '//' + window.location.hostname + '/Login.aspx';
            //     } else if (window.location.hostname.includes('sace')) {
            //         window.location.href = window.location.protocol + '//' + window.location.hostname + '/Login.aspx';
            //     } else
            //         window.location.href = window.location.protocol + '//' + window.location.hostname + '/Login.aspx';
            //         // window.location.href = environment.restEndpointUrl;
            // }
            this.routerService.redirectToLoginPage();
        }
    }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = "operation", result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead
      if (error.status === 401) {
        this.routerService.redirectToLoginPage();
      } else {
        // on mobile app don't log out user if they have a token stored
        if (this.isMobileApp && this.cookieService.getCookie('token')) {
          return;
        }
        this.routerService.redirectToLoginPage();
        //window.location.href = window.location.protocol + '//' + window.location.hostname + '/Login.aspx';
      }

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  // /**
  //  * Handle Http operation that failed.
  //  * Let the app continue.
  //  * @param operation - name of the operation that failed
  //  * @param result - optional value to return as the observable result
  //  */
  // private handleError<T>(operation = 'operation', result?: T) {
  //     this.info('UserService Error');

  //     return (error: any): Observable<T> => {

  //         if (error.status === 401) {
  //             if (window.location.hostname === 'ccasa.aasasoneline.ca') {
  //                 window.location.href = "https://ccasa.aasasoneline.ca/Login.aspx";
  //             } else if (window.location.hostname === 'sace.aasasoneline.ca') {
  //                 window.location.href = "https://sace.aasasoneline.ca/Login.aspx";
  //             } else
  //                 window.location.href = window.location.protocol + '//' + window.location.hostname + '/Login.aspx';
  //         } else {
  //             window.location.href = window.location.protocol + '//' + window.location.hostname + '/Login.aspx';
  //         }

  //         // TODO: send the error to remote logging infrastructure
  //         this.info('error ', error);
  //         console.error(error); // log to console instead

  //         // TODO: better job of transforming error for user consumption
  //         this.log(`${operation} failed: ${error.message}`);

  //         // Let the app keep running by returning an empty result.
  //         return of(result as T);
  //     };
  // }

  /** Log a HeroService message with the MessageService */
  private log(message: string) {
    this.messageService.add(`Conversation Service: ${message}`);
  }
  public info(...args) {
    console.log('User Service', '|', ...args);
  }
}
