import { environment } from '../../../environments/environment';
import { CallerService } from '../../Services/Data/caller.service';
import { UserService } from '../../Services/Data/user.service';
import { ConversationsService, Conversation } from '../../Services/Data/conversations.service';
import { EntityDataService } from '../../Services/Data/entity-data.service';
import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import * as moment from 'moment';
import { startTimeRange } from '@angular/core/src/profile/wtf_impl';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { EnvironmentService } from '../../Services/environment.service';
import { EnvironmentVar } from '../../models/environmentvar';

@Component({
  selector: 'app-new-conversation-dialog',
  templateUrl: './new-conversation-dialog.component.html',
  styleUrls: ['./new-conversation-dialog.component.scss']
})
export class NewConversationDialogComponent implements OnInit {
    user: any;
    error: any;
    conversation: any;
    users: any[];
    locations: any[];
    selectedUser: any;
    selectedLocation: any;

    caller: any;

    callStart: any;
    callEndDate: any;
    conversationDate: any;
    conversationTime: any;
    startTimeOfCall: any;
    endTimeOfCall: any;
    // directNumber.add = '+14032375888';
    okToSave = false;

    isProcessing = false;

  constructor(public dialogRef: MatDialogRef<NewConversationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private conversationService: ConversationsService,
    private callerService: CallerService,
    private userService: UserService,
    private entityDataService: EntityDataService,
    private router: Router,
    private location: Location,
    public envService: EnvironmentService
    ) {
      this.user = data.user;
      this.selectedUser = this.user.id;
      if (data.conversation) {
          console.log('data: ', data);
          this.conversation = data.conversation;
          const temp = this.conversation.properties.callstart.split(' ');
          this.callStart = new Date(temp[0]);
          this.startTimeOfCall = temp[1] + ' ' + temp[2];

          if (this.conversation.properties.conversationend) {
            const endtemp = this.conversation.properties.conversationend.split(' ');
            this.callEndDate = new Date(endtemp[0]);
            this.endTimeOfCall = endtemp[1] + ' ' + endtemp[2];
          }

      } else {
          const temp = new Date();
          this.callStart = temp;
          this.startTimeOfCall = moment(temp).format('hh:mm a');
          this.endTimeOfCall = '';
          this.conversation = {
              entityTypeId: 591,
              properties:
              {
                  calltype_cfcid: '1063',
                  connectiontime: '0',
                  conversationstatus_cfcid: '1067',
                  conversationstatus: 'Active',
                  callsource: '',
                  manuallycreated_cfcid: '1166'
              }
          };
      }
    }

  ngOnInit() {

        // get the users and locations for the pulldowns.
        this.entityDataService.getAll(583).subscribe(
            results => {
                this.users = results;
            });
        this.entityDataService.getAll(587).subscribe(
            results => {
                // console.log('Locations', results);
                // order the results.
                let temp = results.sort((n1, n2) => {
                    if (n1.name > n2.name) {
                        return 1;
                    }
                    if (n1.name < n2.name) {
                        return -1;
                    }
                    return 0;
                });

                this.locations = temp;

                
                if (this.conversation.linkedProperties && this.conversation.linkedProperties.caller) {
                    // set the location of the caller based on the caller name.
                    this.entityDataService.get(this.conversation.linkedProperties.caller.id).subscribe(callerItem => {
                        this.caller = callerItem;

                        if (callerItem && callerItem.properties && callerItem.properties.userselectedcommunity) {
                            const loc = this.locations.find(c => c.name === callerItem.properties.userselectedcommunity);
                            this.selectedLocation = loc.id;
                        }
                    });
                }
            });
    }


    checkOkToSave() {
        console.log('ok to save. ', this.okToSave);
        if (!this.datesOK() || !this.selectedLocation || !this.conversation.properties.numbercalled) {
            this.okToSave = false;
        } else {
            this.okToSave = true;
        }
    }

    datesOK() {
        const startDateTime = moment(moment(this.callStart).format('MM/DD/YYYY') + ' ' + this.startTimeOfCall);
        const endDateTime = moment((moment(this.callEndDate).format('MM/DD/YYYY') + ' ' + this.endTimeOfCall).trim());
        const currentTime = moment();
        
        if (!this.callStart || !this.startTimeOfCall || (this.conversation.properties.conversationstatus_cfcid === '1069' && ((!this.callEndDate || !this.endTimeOfCall) || ( startDateTime > endDateTime || endDateTime > currentTime))))  {
            return false;
        }
        return true;
    }


  onNoClick(): void {
    this.dialogRef.close(null);
  }

  Save() {
    this.UpdateDateAndTimesOfCall();
    
    // const loc = this.locations.find(c => c.name === this.conversation.properties.caller);
    
    this.isProcessing = true;
    if (this.caller && this.caller.linkedProperties && this.caller.linkedProperties.userselectedcommunity.id !== this.selectedLocation) { // the caller's location changed so update the caller
        this.entityDataService.entitySyncUpdate([{
                id: this.conversation.linkedProperties.caller.id,
                linkedProperties: { userselectedcommunity: {id: this.selectedLocation} }
            }]).subscribe(resp => {
                this.entityDataService.entitySyncUpdate([this.conversation]).subscribe(another => {
                    this.isProcessing = false;
                });
            });
    } else {
        this.entityDataService.entitySyncUpdate([this.conversation]).subscribe(resp => {
            this.isProcessing = false;
        });
    }
    this.dialogRef.close(this.conversation);
  }


  private UpdateDateAndTimesOfCall() {
    
    var startDateTimeStr = moment(this.callStart).format('MM/DD/YYYY') + ' ' + this.startTimeOfCall;
    this.conversation.properties.callstart = startDateTimeStr;
    this.conversation.properties.callaccepted = startDateTimeStr;

    if (this.conversation.properties.conversationstatus_cfcid === '1069') { // completed status

        // shouldn't need to do this --- datesOK() function checks to ensure the startdatetime is > enddatetime if conversation status is set to 'complete'
        // if (moment(moment(this.callStart).format('MM/DD/YYYY') + ' ' + this.startTimeOfCall) > moment(moment(this.callStart).format('MM/DD/YYYY') + ' ' + this.endTimeOfCall)) {
        //     const temp = this.startTimeOfCall;
        //     this.startTimeOfCall = this.endTimeOfCall;
        //     this.endTimeOfCall = temp;
        // }

        var endDateTimeStr = moment(this.callEndDate).format('MM/DD/YYYY') + ' ' + this.endTimeOfCall;
        this.conversation.properties.callend =  endDateTimeStr;
        this.conversation.properties.conversationend =  endDateTimeStr;

    } else {
        this.conversation.properties.callend = '';
        this.conversation.properties.conversationend = '';
    }

    // console.log('start time',  moment(this.callStart).format('MM/DD/YYYY') + ' ' + this.startTimeOfCall);
    // // take the conversation and update the end Call and End Conversation pieces.
    // this.conversation.properties.callstart = moment(this.callStart).format('MM/DD/YYYY') + ' ' + this.startTimeOfCall;

    // if (this.callEndDate && this.endTimeOfCall) {
    //     this.conversation.properties.callend =  moment(this.callStart).format('MM/DD/YYYY') + ' ' + this.endTimeOfCall;
    //     this.conversation.properties.conversationend =  moment(this.callStart).format('MM/DD/YYYY') + ' ' + this.endTimeOfCall;
    // }
  }

  CreateConversation() {
    // moment.tz('America/Los_Angeles').format('DD-MM-YYYY HH:mm:ss').split(' ');
    this.UpdateDateAndTimesOfCall();

    this.isProcessing = true;

    /// first create a caller. then associate that caller with the conversation we are
    this.callerService.createCaller().subscribe( caller => {
        console.log('Caller', caller);
        caller.linkedProperties.userselectedcommunity = {id: this.selectedLocation};

        this.conversation.linkedProperties = {user: { id: this.selectedUser }, caller: { id: caller.id }};

        console.log('Conversation To Be Created: ', this.conversation);
        this.entityDataService.entitySyncUpdate([caller]).subscribe(resp => {
            this.entityDataService.create(this.conversation).subscribe(newConversation => {
                this.isProcessing = false;
                this.dialogRef.close(newConversation);
            });
        });
    });
  }
}
