import { Injectable } from '@angular/core';
import { HubService } from 'ngx-signalr-hubservice';
import { BehaviorSubject, Subscription } from 'rxjs';
import { environment as globalEnvironment } from 'src/environments/environment';
import { EnvironmentVar } from '../models/environmentvar';
import { EnvironmentService } from './environment.service';
import { AuthService } from './Hubs/auth.service';
import { SignalrConnectionCheckerService } from './Hubs/signalr-connection-checker.service';


// import * as signalR from '@aspnet/signalr';

export enum SignalRState {
    initializing,
    reconnecting,
    connected,
    disconnected,
  }


@Injectable({
    providedIn: 'root'
})
export class SignalRConnectionService {
    private hubService: HubService;

    public connectionChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public signalrConnected: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public signalrReconnected: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public signalrDisconnected: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public signalrConnectionProblem: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public signalrState: BehaviorSubject<SignalRState> = new BehaviorSubject<SignalRState>(SignalRState.initializing);

    hubUrl = '';
    signalRConnectionState = SignalRState.disconnected;

    consecutiveDisconnectMessages = 0;

    private currentlyChecking = false;
    private testMessageReceived = false;
    private pingInterval: any = null;
    private serverHasPingServiceUpdated = true;
    private pings: any = { count: 0, lastPingCount: 0 };
    timeDisconnected = Date.now();
    count = 0;
    public pingConnectionProblem = false;
    loginSubscription: Subscription  = null;
    signalrSubscriptions: Subscription[] = [];


    constructor(
        private authService: AuthService,
        private envService: EnvironmentService,
    ) {
            if (globalEnvironment.isMobileApp) {
                this.authService.loggedIn.subscribe(loggedIn => {
                this.hubUrl = this.envService.localEnvironment.hubUrl;
                    // try to connect if the user is logged in
                    if (loggedIn) {
                        this.configureForMobile();
                    } else {
                        // try to disconnect if the environment is default one
                        this.disconnectFromHubService().then(() => {
                            console.log("Disconnected from Hub Service");
                            delete this.hubService;
                            this.hubService = null;
                        }).catch((error) => {
                            console.error(error);
                        });
                    }
                });
            }
            else {
                this.hubService = new HubService();
                // let everyone know we are currently disconnected.
                this.signalrState.next(this.signalRConnectionState);
        
                if (!this.hubService.connection ||
                    (this.hubService.connection && this.hubService.connection._originalUrl !== this.hubUrl)
                ) {
                    this.setupConnection();
                }
        
                setTimeout(() => {
                    this.SignalRCheckConnectionTimeout();
                }, 2000);
            }
    }

    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
        
    }

    SignalRCheckConnectionTimeout() {
        let timer = 70000;
        // console.log('SignalRCheckConnectionTimeout Checker, user status: ', this.authService.userLoggedIn);
        // console.log('SignalRCheckConnectionTimeout Checker, connection status: ',this.signalRConnectionState);
        if (this.signalRConnectionState === SignalRState.disconnected) {
            if (globalEnvironment.isMobileApp && this.authService.userLoggedIn === false) {
                this.count = 0;
                this.timeDisconnected = 0; // reset the timeDisconnected mark and count if user is logged out
                return; // don't count this as a problem if user is not logged in on mobile app
            }
            timer = 5000;
            if (this.timeDisconnected !== 0 && Date.now() - this.timeDisconnected > 5000) {
                this.signalrConnectionProblem.next(true);
            }
        }
        // const audio = new Audio('assets/audio/NewMessage.mp3');
        // audio.play();
        console.log('Count Timer Main: ' + this.count++);
        setTimeout(() => {
            this.SignalRCheckConnectionTimeout();
        }, timer);
    }

    getHubService() {
        return this.hubService;
    }

    async reconnectToHubService(): Promise<boolean> {

        console.log('RECONNECTING SOMWHERE');

        await this.disconnectFromHubService();
        if (globalEnvironment.isMobileApp && this.authService.userLoggedIn === false)
            return; // don't reconnect if user is logged out on mobile app

        let tempSub: Subscription = null;
        tempSub = this.hubService.onConnected(s => {
            console.log('SignalR Connected', s);
            this.signalRConnectionState = SignalRState.connected;
            this.signalrState.next(this.signalRConnectionState);
            this.connectionChanged.next(true);
            this.signalrConnected.next(true);
            console.log('ConnectionHub: ' + (this.hubService ? this.hubService.connection.id : null));
            this.consecutiveDisconnectMessages = 0;

        });
        this.signalrSubscriptions.push(tempSub);
        tempSub = this.hubService.onDisconnected(s => {
            console.log('SignalR Disconnected', s);
            this.signalRConnectionState = SignalRState.disconnected;
            this.signalrState.next(this.signalRConnectionState);
            this.timeDisconnected = Date.now();

            if (this.consecutiveDisconnectMessages > 0 && this.consecutiveDisconnectMessages % 5 === 0) {
                this.signalrConnectionProblem.next(true);
            }
            this.consecutiveDisconnectMessages++;
           // this.connectionChanged.next(false);
           // this.signalrDisconnected.next(true);
        });
        this.signalrSubscriptions.push(tempSub);
        tempSub = this.hubService.onReconnecting(s => {
            console.log('SignalR Trying to Reconnect', s);
            this.signalRConnectionState = SignalRState.reconnecting;
            this.signalrState.next(this.signalRConnectionState);
        });
        this.signalrSubscriptions.push(tempSub);
        tempSub = this.hubService.onReconnected(s => {
            console.log('!!!! SignalR Reconnected !!!!', s);
            // this.setupConnection();
            this.signalRConnectionState = SignalRState.connected;
            this.signalrState.next(this.signalRConnectionState);
            console.log('ConnectionHub: ' + (this.hubService? this.hubService.connection.id : null));
            this.consecutiveDisconnectMessages = 0;
            this.pingConnectionProblem = false;
           // this.connectionChanged.next(true);
            this.signalrReconnected.next(true);
          //  this.signalrDisconnected.next(false);
        });
        this.signalrSubscriptions.push(tempSub);

        console.log('environment.hubUrl in signalR connection 2: ' + this.envService.localEnvironment.hubUrl);
        return this.hubService.connect({ url: this.envService.localEnvironment.hubUrl, attemptReconnects: true }).toPromise();
    }

    async disconnectFromHubService() {
        console.log('Disconnecting From Hub Service???')
        if (this.hubService && this.hubService.connected) {
            console.log("Disconnecting")
            await this.hubService.connection.stop();
        }
        if (this.hubService) {
            delete this.hubService;
            this.hubService = null;
        }
        this.signalrSubscriptions.forEach(sub => sub.unsubscribe());
        if (globalEnvironment.isMobileApp && this.authService.userLoggedIn === false) {
            this.connectionChanged.next(false);
            this.signalrConnected.next(false);
            return;
        }
        this.hubService = new HubService();
    }

    AlertPingProblem(error) {
        this.pingConnectionProblem = error;
        if (error) {
            console.log('Ping Problem!!!');
            this.signalrConnectionProblem.next(true);
        }
    }

    setupConnection() {
        this.reconnectToHubService().then(result => {
            console.log('Reconnect and Inform Connection Status Changed', result);
            if (result) {
                this.signalRConnectionState = SignalRState.connected;
            }
            this.connectionChanged.next(result);
            if (this.envService.globalEnvironment.isMobileApp) {
                this.signalrReconnected.next(true);
            }
        })
        .catch(err => {
            console.error(err);
        });
    }

/**
 * ------------------------------------------------ FOR MOBILE VERSION -------------------------------------------------------
 */

    /**
     * On mobile version, every time the user logs in using the login page, the hubURL has to be updated
     * and the constructor shouldn't try to connect to signalR yet until the user has logged in
     * that's why there is a configure function for that use case
     */
    configureForMobile() {
        this.hubService = new HubService();
        // let everyone know we are currently disconnected.
        this.signalrState.next(this.signalRConnectionState);

        if (!this.hubService.connection ||
            (this.hubService.connection && this.hubService.connection._originalUrl !== this.hubUrl)
        ) {
            this.setupConnection();
        }

        setTimeout(() => {
            this.SignalRCheckConnectionTimeout();
        }, 2000);
    }
}


