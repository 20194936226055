import { Injectable, NgZone, Injector } from '@angular/core';
import { UserService } from '../Data/user.service';
import { InternalCookieService } from '../cookie.service';
import { EntityDataService } from '../Data/entity-data.service';

import {
  Plugins,
  PushNotification,
  PushNotificationToken,
  PushNotificationActionPerformed
} from '@capacitor/core';
import { Router } from '@angular/router';
import { ConversationsService } from '../Data/conversations.service';
import { MobileInAppNotificationService } from './mobile-in-app-notification.service';


const { PushNotifications } = Plugins;

@Injectable({
  providedIn: 'root',
})
// for mobile - outside of the app (Push Notification)
export class NotificationService {
  constructor(
    private userService: UserService,
    private cookieService: InternalCookieService,
    private entityDataService: EntityDataService,
    private conversationService: ConversationsService,
    private router: Router,
    private ngZone: NgZone,
    private inAppNotificationService: MobileInAppNotificationService
  ) {}

  registerNotificationMobile(injector: Injector) {
    this.conversationService = injector.get(ConversationsService);
    this.cookieService = injector.get(InternalCookieService);
    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermission().then((result) => {
      if (result.granted) {
        console.log("Push requestPermission success, result: " + JSON.stringify(result));
        // Register with Apple / Google to receive push via APNS/FCM
        // alert('Push requestPermission success, result: ' + JSON.stringify(result));
        PushNotifications.register();
      } else {
        // Show some error
        // alert("Push requestPermission failed, result: " + result);
      }
    });

    // On success, we should be able to receive notifications
    PushNotifications.addListener(
      "registration",
      (token: PushNotificationToken) => {
        console.log("Token value: " + token.value);
        // alert('Push registration success, token: ' + token.value); // this is the token used to identify the device
        this.registerAppToken(token.value);
        // registers token here to the backend
      }
    );

    // Some issue with our setup and push will not work
    PushNotifications.addListener("registrationError", (error: any) => {
      // alert("Error on registration: " + JSON.stringify(error));
    });

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener(
      'pushNotificationReceived',
      (notification: PushNotification) => {
        console.log('Push received: ' + JSON.stringify(notification));
        console.log('convoid: ' + parseInt(notification.data.convoid, 10));
        const payload = {
          message: notification.title + ': ' + notification.body,
          actionStr: 'Go',
          direction: notification.data.direction,
          convoID: parseInt(notification.data.convoid, 10),
          source: notification.data.source
        };
        this.ngZone.run(() => {
          this.inAppNotificationService.openNewMessageNotification(payload, injector);
        });
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification: PushNotificationActionPerformed) => {
        console.log('Push action performed: ' + JSON.stringify(notification));
        if (
          notification &&
          notification.notification.data &&
          notification.notification.data.source.toLowerCase() === "newmessage"
        ) {
          // TODO: switches the current conversation to the one in the one with the ID in data
          this.ngZone.run(() => {
            const activeOne = this.conversationService.getActiveConversation();
            // console.log('notification service, activeOne: ' + JSON.stringify(activeOne));
            // console.log('notification service, notification.notification: ' + JSON.stringify(notification.notification));
            this.conversationService
              .setConversation(
                parseInt(notification.notification.data.convoid, 10)
              )
              .then((ret) => {
                if (ret === 'not found') {
                  this.inAppNotificationService.openGeneralNotification(
                    {actionStr: 'OK', message: 'Conversation not found. It might have ended.'}
                  );
                  this.router.navigate(['mobile-dashboard']);
                } else {
                  this.router.navigate(['mobile-conversation']);
                }
              });
            // const activeOne2 = this.conversationService.getActiveConversation();
            // console.log('notification service, current activeOne2: ' + JSON.stringify(activeOne2));
            // console.log('notification service, navigating to mobile-conversation: ' + ret);
          });
        } else if (
          notification.notification.data &&
          notification.notification.data.source.toLowerCase() ===
            'newconversation'
        ) {
          this.router.navigate(["/mobile-dashboard"]);
        }
      }
    );
  }

  registerAppToken(token: string) {
    let subscribable = this.userService.get();
    if (subscribable) {
      subscribable.subscribe((user) => {
        // console.log('user user user', user);
        const userCurrentTokens = user.properties["json"]
          ? JSON.parse(user.properties["json"])
          : [];
        const currentAppToken = this.cookieService.getCookie("appToken");

        if (!currentAppToken || currentAppToken !== token) {
          this.cookieService.setCookie("appToken", token);
        }

        if (!userCurrentTokens.includes(token)) {
          userCurrentTokens.push(token);
          // tslint:disable-next-line: max-line-length
          this.entityDataService
            .entitySyncUpdate([
              {
                id: user.id,
                // tslint:disable-next-line: max-line-length
                properties: { json: JSON.stringify(userCurrentTokens) },
              },
            ])
            .subscribe((resp) => {
              console.log("json update result", resp);
            });
        }
      });
    }
  }




}
