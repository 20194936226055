import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Subject, Subscription, merge, interval, EMPTY, combineLatest } from 'rxjs';
import { filter, first, map, mapTo, switchMap, take } from 'rxjs/operators';
import { AudioService, OpenAudioSettingsOptions, SoundContext } from 'src/app/Services/audio.service';
import { UserService } from 'src/app/Services/Data/user.service';
import { AudioOptionsDialogComponent } from '../audio-options-dialog/audio-options-dialog.component';
import { VoiceService, wait } from '../voice-service/voice.service';
import { IAudioControls } from './audio-controls.interface';
import { EnvironmentService } from 'src/app/Services/environment.service';
import { DialOutDialogComponent } from '../dial-out-dialog/dial-out-dialog.component';

@Component({
  selector: 'app-audio-controls',
  templateUrl: './audio-controls.component.html',
  styleUrls: ['./audio-controls.component.scss']
})
export class AudioControlsComponent implements OnInit, OnDestroy, IAudioControls {
  isVoip = false;
  readonly DELAY_VOIP_INTRO_POPUP = 333;

  graphicEq = {
    name: 'view',
    selected: 'graphic_eq',
    options: [ 'graphic_eq' ],
  };

  muteBtn = {
    click: () => this.voice.mute(),
    selected: () => this.voice.isMuted ? 'mic_off' : 'mic',
  };

  settingsBtn = {
    name: 'settings',
    selected: 'settings',
    options: [ 'settings', ],
  };

  options = [
    {
      name: 'notifications',
      selected: 'notifications_active',
      options: [ 'notifications_active', 'notifications_off' ]
    },
    {
      name: 'input',
      selected: 'mic',
      options: [ 'mic_off', 'mic', ] // 'headset_mic', 'mic_external_on' ]
    },
    {
      name: 'output',
      selected: 'headphones',
      options: [ 'headset', 'headset_off', /*'speaker', 'headset_mic', 'volume_off'*/ ]
    },
  ];

  eqReady = false;
  polling = false;
  audioSub: Subscription;
  viewGraphicEq = false;
  viewSettings = false;
  stream = new Subject<number>();
  openedSettingsByClick = false;

  inputDevices$ = new Subject<MediaDeviceInfo[]>();
  inputDevices: MediaDeviceInfo[] = [];
  outputDevices$ = new Subject<MediaDeviceInfo[]>();
  outputDevices: MediaDeviceInfo[] = [];

  selectedInput: MediaDeviceInfo;
  selectedOutput: MediaDeviceInfo;

  // @ViewChild('inputSelect')
  // inputSelect: MatSelect;

  // @ViewChild('outputSelect')
  // outputSelect: MatSelect;

  subs: Subscription[] = [];
  set nextSub(s: Subscription) { this.subs.push(s); }

  get onActiveCall() {
    return this.voice.onActiveCall;
  }

  constructor(
    private user: UserService,
    private voice: VoiceService,
    public audio: AudioService,
    public dialog: MatDialog,
    protected envService: EnvironmentService,
  ) {
    this.nextSub = this.audio.onSettingsOpen().subscribe(this._openSettings.bind(this));
  }

  ngOnInit() {
    this.user.currentUser$
      .pipe(filter(_ => !!_))
      .subscribe(u => { // tslint:disable-next-line: triple-equals
        this.isVoip = u.properties.onelineisvoip == '1';
        if (this.isVoip) {
          this.openSettings(true);
          // Keenans way of oping settings - happens 3 seconds after user clicks on the screen
          // this.audio.initialized()
          //   .then(wait(this.DELAY_VOIP_INTRO_POPUP))
          //   .then(this.openSettings.bind(this));
        }
      });

    const streamEnd = new Subject<boolean>();
    this.audio.isMuted().subscribe(streamEnd); // on a mute flag, send some zeros

    this.nextSub = merge(
      streamEnd.pipe(switchMap( muted => muted ? interval(this.audio.streamInterval).pipe(take(60), mapTo(0)) : EMPTY)),
      this.audio.inputStream())
    .pipe(map( v => Math.pow(v, 1.25) ))
    .subscribe(this.stream);

    this.nextSub = this.audio.availableDevices().subscribe(({inputs, outputs}) => {
      this.inputDevices$.next(this.inputDevices.splice( 0, this.inputDevices.length, ...inputs));
      this.outputDevices$.next(this.outputDevices.splice( 0, this.outputDevices.length, ...outputs));
      this.eqReady =  this.inputDevices.length > 0;

      if (this.eqReady) {
        if (this.polling && (this.viewGraphicEq || this.viewSettings))
          this.checkMicAccess().then(success => success && ( this.audio.muteInput(false), this.audio.startInputStream()) );
      } else
        streamEnd.next(true);

      this.log('loaded audio devices:', this.inputDevices.length, 'input devices', this.outputDevices.length, 'output devices,');
    });

    this.nextSub = combineLatest([
      this.audio.inputDevice().pipe(filter( d => !this.selectedInput || this.selectedInput.deviceId === d )),
      this.inputDevices$,
    ]).subscribe(([d, devices]) => this.selectedInput = this.inputDevices.find( dInfo => dInfo.deviceId === d));

    this.nextSub = combineLatest([
      this.audio.outputDevice().pipe(filter(
        d => (d.context === SoundContext.All || SoundContext.Voice) && !this.selectedOutput || this.selectedOutput.deviceId === d.id)),
      this.outputDevices$,
    ]).subscribe(([d, devices]) => this.selectedOutput = this.outputDevices.find(dInfo => dInfo.deviceId === d.id) );
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  optionClick(option) {
    option.selected = option.options[(option.options.indexOf(option.selected) + 1) % option.options.length];
  }

  fakeCall(bool) {
    this.voice.fakeCall(bool);
  }

  openSettings(automatic = false) {
    this.audio.openSettings({wasAutomatic: automatic});
  }

  openDialPad() {
    const dialogRef = this.dialog.open(DialOutDialogComponent, {
      hasBackdrop: false,
      minHeight: '200',
      data: {
          number: undefined,
          dialTones: false,
      },
      panelClass: 'dialog-responsive'
    });
  }

  _openSettings(options: OpenAudioSettingsOptions) {
    this.openedSettingsByClick = !options.wasAutomatic;

    if (this.viewSettings) return;

    const dialogRef = this.dialog.open(AudioOptionsDialogComponent, { width: '50vw', minWidth: '375px', data: this });
    this.eq(true);
    this.viewGraphicEq = false;
    this.viewSettings = true;

    dialogRef.afterClosed()
      .pipe(first())
      .subscribe(d => {
        this.eq(false);
        this.viewSettings = false;
      });
  }

  checkMicAccess() {
    return this.audio.getInputPermission();
  }

  eq(startPolling?: boolean) {
    if (startPolling !== undefined && startPolling === this.polling) return;

    if (this.polling) {
      this.audio.stopInputStream();
      this.viewGraphicEq = false;
      this.audio.removeInputListener(this);
    } else {
      this.checkMicAccess().then(success => success && ( this.audio.muteInput(false), this.audio.startInputStream()) );
      this.viewGraphicEq = true;
      this.audio.addInputListener(this);
    }
    this.polling = !this.polling;
  }

  log(...args) {
    this.voice.log('Keenan - Audio Controls', ...args);
  }
}
