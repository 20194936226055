import { SignalRConnectionService } from './../signal-r-connection.service';
import { environment } from './../../../environments/environment';
import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';


import { map } from 'rxjs/operators';

import {
  Hub,
  HubService,
  HubWrapper,
  HubSubscription
} from 'ngx-signalr-hubservice';
import { EnvironmentService } from '../environment.service';
import { EnvironmentVar } from '../../models/environmentvar';
import { AuthService } from './auth.service';

// More information can be found here - https://github.com/steveadoo32/ngx-signalr-hubservice


// Define any messages that are coming from the server.
export interface EntityTypeMessage { entityTypeID: string; entityID: string; message: string; }

@Injectable()
@Hub({ hubName: 'entityTypeHub' })
export class EntityTypeService {

  private hubWrapper: HubWrapper;
  private subbedIDs: string[] = [];
  // private hubs = [];
  //environment.hubs;
  

  public onMessageReceived = new EventEmitter<EntityTypeMessage>();
  localEnvironment: EnvironmentVar;

  constructor(
      // private hubService: HubService,
      private signalRConnectionService: SignalRConnectionService,
      private envService: EnvironmentService,
      private authService: AuthService
      ) {
        if (environment.isMobileApp) {
          this.signalRConnectionService.connectionChanged.subscribe((connectionChanged: boolean) => {
            const hubService = this.signalRConnectionService.getHubService();
            if (connectionChanged && hubService) {
              console.log('entity-type.service, new hub wrapper');
              this.hubWrapper = hubService.register(this);
            } else {
              this.unSubscribeAll();
              if (this.hubWrapper) {
                this.hubWrapper.unregister();
              }
            }
          });
        } else {
          this.hubWrapper = this.signalRConnectionService.getHubService().register(this);      
        }
  }


  // Client => Server the function name is the same as the name of the function in the c# hub
  public sendMessage(entityTypeID: string, entityID: string, message: string): Observable<EntityTypeMessage> {
    return this.hubWrapper.invoke<boolean>('sendMessage', entityTypeID, entityID, message).pipe(map(_ => {
      return {
        entityTypeID,
        entityID,
        message
      };
    }));
  }

  // Client => Server the function name is the same as the name of the function in the c# hub
  public subscribe(entityTypeID: string): Observable<string> {
    return this.hubWrapper.invoke<boolean>('subscribe', entityTypeID).pipe(map(_ => {
      this.subbedIDs.push(entityTypeID);
      return entityTypeID;
    }));  
  }

  public unSubscribe(entityTypeID: string): Observable<string> {
    return this.hubWrapper.invoke<boolean>('unSubscribe', entityTypeID).pipe(map(_ => {
      return entityTypeID;
    }));
  }

  private unSubscribeAll() {
    this.subbedIDs.forEach(subbedID => {
      this.unSubscribe(subbedID);
    });
    this.subbedIDs = [];
  }

   // Server => Client
  @HubSubscription()
  private receiveMessage(subscriberID: string, entityTypeID: string, entityID: string, message: string) {
    // tslint:disable-next-line:max-line-length
    console.log('Entity Type Received Message SubscriberID: ' + subscriberID + ', EntityTypeId: ' + entityTypeID + ', EntityId: ' + entityID + ', message: ' + message);

    // ensure that this update is for the domain we are looking at. Ignore others.
    // tslint:disable-next-line:max-line-length
    this.onMessageReceived.emit({
        entityTypeID,
        entityID,
        message
    });
  }

/**
 * ------------------------------------------------ FOR MOBILE VERSION -------------------------------------------------------
 */

    /**
     * On mobile version, every time the user logs in using the login page, the signalR hub has to be updated
     * and the constructor shouldn't try to register for hub connection yet until user has logged in
     * that's why there is a configure function that use case
     */
    configureForMobile() {
      this.signalRConnectionService.connectionChanged.subscribe((connectionChanged: boolean) => {
        const hubService = this.signalRConnectionService.getHubService();
        if (connectionChanged && hubService) {
          this.hubWrapper = hubService.register(this);
        }
      });
    }


}
