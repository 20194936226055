import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { SignalRConnectionService } from '../Services/signal-r-connection.service';
// import { HubConnectionService } from '../Services/Hubs/hub-connection.service';

@Component({
  selector: 'app-diagnostics',
  templateUrl: './diagnostics.component.html',
  styleUrls: ['./diagnostics.component.scss']
})
export class DiagnosticsComponent implements OnInit {

  isMobileApp = environment.isMobileApp;

  constructor(
    // private hubConnectionService: HubConnectionService
    private signalRConnectionService: SignalRConnectionService
    ) { }

  ngOnInit() {


    //@ts-ignore
    if (typeof console.olog == 'undefined')
    {
        if (typeof console  != "undefined") {
          if (typeof console.log != 'undefined')
          {
              //@ts-ignore
              console.olog = console.log;
          }
          else
          {
              //@ts-ignore
              console.olog = function() {};
          }
        }
      }

      console.log = function(message) {
          //@ts-ignore
          console.olog(message);
          $('#debugDiv').append('<p>' + message + '</p>');
      };
      console.error = console.debug = console.info =  console.log
    }

    reconnectButton() {
      // this.hubConnectionService.reconnectAndInform();
      this.signalRConnectionService.setupConnection();
   }



}
