import { Injectable, Injector } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef, SimpleSnackBar } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { SoundContext, AudioService } from 'src/app/Services/audio.service';
import { ConversationsService } from '../Data/conversations.service';
import { MobileConversationTabService } from '../Data/mobile-conversation-tab.service';

@Injectable({
  providedIn: 'root'
})
// for mobile - inside of the app (kinda like Local Notification)
export class MobileInAppNotificationService {

  listOfSnackBar: { ref: MatSnackBarRef<SimpleSnackBar>, data: string} [] = [];

  numberOfUnread = 0;

  constructor(
    private snackBar: MatSnackBar,
    private audio: AudioService,
    private router: Router,
    private conversationsService: ConversationsService,
    private route: ActivatedRoute
  ) { }

  openNewMessageNotification(payload: { message: string, actionStr: string, convoID: number, direction: string, source: string },
                             injector: Injector) {
    const config: MatSnackBarConfig = {
      verticalPosition: 'top',
      horizontalPosition: 'center',
      duration: 10000
    };

    this.conversationsService = injector.get(ConversationsService);
    this.route = injector.get(ActivatedRoute);
    const selectedMobileTab = injector.get(MobileConversationTabService).selectedConversationTab;
    const convoID = payload.convoID;
    const currentConvo = this.conversationsService.getActiveConversation();
    const isCurrent = (currentConvo && currentConvo.id === convoID);
    const source = payload.source;
    console.log('data in mian: ' + JSON.stringify({ selectedMobileTab: selectedMobileTab, routerUrl: this.router.url}));


    this.playAudio(payload.direction, isCurrent);
    if (!isCurrent || (this.router.url !== '/mobile-conversation' || selectedMobileTab.toLowerCase() !== 'chat')) {
      const notifiRef = this.snackBar.open(payload.message, payload.actionStr, config);
      notifiRef.onAction().subscribe(() => {
        this.conversationsService.setConversation(convoID).then(
          () => {
            if (source.toLowerCase() === 'newmessage') {
              this.router.navigate(['mobile-conversation']);
            } else {
              this.router.navigate(['mobile-dashboard']);
            }
          }
        );
      });
    }
  }

  openGeneralNotification(payload: { message: string, actionStr: string }) {
    const config: MatSnackBarConfig = {
      verticalPosition: 'top',
      horizontalPosition: 'center',
      duration: 7000
    };
    const notifiRef = this.snackBar.open(payload.message, payload.actionStr, config);
    notifiRef.onAction().subscribe(() => {
      notifiRef.dismiss();
    });
  }

  countUnread(injector: Injector): number {
    this.conversationsService = injector.get(ConversationsService);
    return this.conversationsService.countUnread();
  }

  playAudio(audioType: string = 'default', isCurrent: boolean) {
    console.log('Playing New message on mobile sound.');
    if (isCurrent) {
      switch (audioType.toLowerCase()) {
        case 'outgoing':
          this.audio.playAudio(SoundContext.UISound, 'assets/audio/OutgoingMessage.wav');
          break;
        case 'incoming':
          this.audio.playAudio(SoundContext.Notification, 'assets/audio/IncomingMessage.wav');
          break;
        default:
          this.audio.playAudio(SoundContext.Notification, 'assets/audio/NewMessage.mp3');
          break;
      }
    } else {
        this.audio.playAudio(SoundContext.Notification, 'assets/audio/NewMessage.mp3');
    }
  }
}
