import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AuthService } from '../../Services/Hubs/auth.service';
import { Router } from '@angular/router';
import { InternalCookieService } from '../../Services/cookie.service';
import { environment as globalEnvironment } from '../../../environments/environment';
import { endPointUrl } from '../../../environments/restEndPointUrl.constant';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { EnvironmentService } from 'src/app/Services/environment.service';
import { EnvironmentVar } from 'src/app/models/environmentvar';

@Component({
  selector: 'app-mobile-login',
  templateUrl: './mobile-login.component.html',
  styleUrls: ['./mobile-login.component.scss']
})
export class MobileLoginComponent implements OnInit {

  MINUTES_UNTIL_LOGGED_OUT = '525600';
  LOGIN_BUTTON_LABEL_LOGIN = 'Log In';
  LOGIN_BUTTON_LABEL_LOGGING_IN = 'Logging In...';

  company: string;
  companyChanged: Subject<string> = new Subject<string>();
  username: string;
  password: string;
  errorMessage: string;
  submitting: boolean;
  rememberUsername: boolean;
  validCompany: boolean;
  isError = true;

  domain = '.izzyplatform.com';
  loginButtonLabel = this.LOGIN_BUTTON_LABEL_LOGIN;
  disableLoginButton = false;

  isMobileApp = globalEnvironment.isMobileApp;


  constructor(
    private authService: AuthService,
    private router: Router,
    private cookieService: InternalCookieService,
    public envService: EnvironmentService
  ) {
  }

  ngOnInit() {
    this.authService.loggedIn.next(false);
    this.loginButtonLabel = this.LOGIN_BUTTON_LABEL_LOGIN;
    this.disableLoginButton = false;
    // console.log('remember username? =>' + this.cookieService.getInternalStorageValue('rememberUsername') + '<=');
    // console.log('lastCompany? =>' + this.cookieService.getInternalStorageValue('lastCompany') + '<=');
    // console.log('lastUsername? =>' + this.cookieService.getInternalStorageValue('lastUsername') + '<=');
    if (this.cookieService.getInternalStorageValue('rememberUsername') === 'yes') {
      this.rememberUsername = true;
      this.company = this.cookieService.getInternalStorageValue('lastCompany');
      this.username = this.cookieService.getInternalStorageValue('lastUsername');
      // console.log('updatedCompany? =>' + this.company + '<=');
      // console.log('updatedUsername? =>' + this.username + '<=');
      this.checkCompany(this.company);
    }

  }

  toggleRememberUsername() {
    this.rememberUsername = !this.rememberUsername;
  }

  submitForm() {
    if (!this.checkFields()) {
      this.isError = true;
      this.errorMessage = 'You must fill in all fields.';
      return;
    }
    this.disableLoginButton = true;
    this.loginButtonLabel = this.LOGIN_BUTTON_LABEL_LOGGING_IN;
    this.submitting = true;
    this.errorMessage = '';

    const loginObj = {
      mfaToken: '',
      mfaCode: '',
      userName: this.username,
      password: this.password,
      rememberUsername: true,
      company: this.company,
      timeLoggedIn: this.MINUTES_UNTIL_LOGGED_OUT,
    };


    this.authService.validateLogin(loginObj).then(response => {
      console.log('returned token from login server', response);
        // console.log('returned data from server', response);
        if (response.statusText) {
          this.errorMessage = response.statusText;
          this.isError = true;
        } else {
          this.authService.setToken(response.token);
          if (!this.rememberUsername) {
            this.username = '';
            this.company = '';
          }
          this.password = '';
          if (this.isMobileApp) {
            this.authService.hasLoggedIn();
            this.loginButtonLabel = this.LOGIN_BUTTON_LABEL_LOGIN;
            this.disableLoginButton = false;
            this.router.navigate(['mobile-dashboard']);
          } else {
            this.router.navigate(['dashboard']);
          }
        }
    }).catch(err => {
      console.error('MobileLogin - error returned from login server', err);
      this.disableLoginButton = false;
      this.loginButtonLabel = this.LOGIN_BUTTON_LABEL_LOGIN; 
      if (err.message.includes('unknown url')) {
        this.errorMessage = 'Domain not found.';
        this.isError = true;
      } else if (err.status === 401) {
        this.errorMessage = 'Wrong email or password for this domain. Please try again.';
        this.isError = true;
      }
      this.authService.logOut();
    });
  }

  checkFields(): boolean {
    if (!this.company || !this.username || !this.password) {
      return false;
    }
    return true;
  }

  updateEnvironmentName(companyName: string) {
    this.company = companyName.toLowerCase();
    let temporaryLoginEndpoint = '';
    if (companyName === 'aasas' || companyName === 'ccasa' || companyName === 'sace') {
      this.domain = '.aasasoneline.com';
      this.envService.localEnvironment.restEndpointUrl = 'https://' + companyName + this.domain + '/';
      temporaryLoginEndpoint = 'https://' + companyName + this.domain + '/';
    } else if (companyName === 'localhost') {
      // environment.restEndpointUrl = 'http://bb61ff21a432.ngrok.io/';
      // this.domain = '.localhost:56696';
      this.envService.localEnvironment.restEndpointUrl =
      (this.envService.localEnvironment.mobileEndpoints && this.envService.localEnvironment.mobileEndpoints.localEndpointUrl)
      ? this.envService.localEnvironment.mobileEndpoints.localEndpointUrl
      : 'https://izzyvoip.ngrok.io';
      temporaryLoginEndpoint = 'https://izzyvoip.ngrok.io';
      this.domain = '.izzyvoip.ngrok.io';
    } else if (companyName === 'swapenv') {
      this.envService.localEnvironment.restEndpointUrl = 'http://localhost:56696';
      temporaryLoginEndpoint = 'http://localhost:56696';
      this.domain = '.56696';
    } else {
      this.domain = '.izzyplatform.com';
      this.envService.localEnvironment.restEndpointUrl = 'https://' + companyName + this.domain + '/';
      temporaryLoginEndpoint = 'https://' + companyName + this.domain + '/';
    }
    this.envService.localEnvironment.mainSubscriberId = this.getSubscriberIDFromCompanyName(this.company);
    this.envService.localEnvironment.hubUrl = this.envService.localEnvironment.restEndpointUrl + '/signalr';
    this.envService.setTemporaryLoginEndPointForMobile(temporaryLoginEndpoint);
    this.cookieService.setCookie('lastEndpoint', this.envService.getTemporaryLoginEndPointForMobile());
  }

  getSubscriberIDFromCompanyName(companyName: string) {
    let retVal = 124;
    switch (companyName) {
      case 'sagesse':
        retVal = 121;
        break;
      case 'aasas':
        retVal = 103;
        break;
      case 'eva':
        retVal = 122;
        break;
      case 'sace':
        retVal = 105;
        break;
      case 'nlsacpc':
        retVal = 126;
        break;
      case 'demo':
        retVal = 124;
        break;
      default:
        retVal = -1;
        break;
    }
    return retVal;
  }

  checkCompany(companyName: string): void {
    console.log('checkCompany', companyName);
    this.updateEnvironmentName(companyName);
  }
}
