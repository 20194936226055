import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';

import { AsyncSubject, BehaviorSubject, combineLatest, interval, Observable, Subject } from 'rxjs';
import { catchError, concatMap, filter, first, map, mapTo, scan, skipWhile, startWith, take } from 'rxjs/operators';

import { Call, Device } from '@twilio/voice-sdk';
import { TwilioError } from '@twilio/voice-errors';

import { EnvironmentService } from '../../Services/environment.service';
import { UserService } from '../../Services/Data/user.service';
import { AudioService } from '../../Services/audio.service';
import { Conversation, getCallerName } from '../../Services/Data/conversations.service';
import { IWarpEntity } from 'src/app/Services/models/warp-entity';
import { CallHelper } from 'src/app/voice/voice-notification/call-notification-data';
import { PushNotificationsService } from 'src/app/Services/Notifcations/push-notifications.service';
import * as moment from 'moment';


export function wait<T>(ms: number) {
  return (input: T) => new Promise<T>( resolve => setTimeout(resolve, ms, input));
}

function callEventAsPromise(call: Call, command: string): Promise<any> {
  const then =  new Promise( resolve => {
    call.once(command, resolve);
  });
  console.log('calling', command);
  call[command]();
  return then;
}


interface RippleIdentity {
  identity: string;
  token: string;
  expires: Date;
}

export interface OutgoingCallEvent {
  user: IWarpEntity;
  isVoip: boolean;

  dialOutType?: DialOutType;
  outgoingNumber?: string;

  conversation?: Conversation;
  isMuted?: boolean;
  isCoach?: boolean;
}

enum CallResponseDigits {
  Accept = '1#',
  Reject = '2#',
  Delay = '3#'
}

export enum DialOutType {
  Sms = 'sms',
  Phone = 'phone',
}

/**
 * Handles calls, but lets {@link AudioService} manage audio
 */
@Injectable({
  providedIn: 'root'
})
export class VoiceService implements OnDestroy  {
  private readonly GATHER_DELAY = 700;
  private readonly tokenRefreshMs: number = 1 * (60000 /* ms per min */); // 1 minute
  private identity: BehaviorSubject<RippleIdentity> = new BehaviorSubject(undefined);
  private tokenTimeout: NodeJS.Timer;
  private twilioDevice: Device;
  private _activeCall: BehaviorSubject<Call> = new BehaviorSubject(undefined);

  private get activeCall(): Call {
    return this._activeCall.value;
  }

  private set activeCall(value: Call) {
    this._activeCall.next(value);
  }

  public get onActiveCall() { return this.activeCall !== undefined; }

  private _isMuted = true;
  get isMuted() { return this._isMuted || (this.activeCall && this.activeCall.isMuted()); }

  private onCallOutgoing: Subject<OutgoingCallEvent> = new Subject();
  private onCallIncoming: Subject<Call> = new Subject();
  private onDeviceError: Subject<TwilioError> = new Subject();
  private onDeviceRegistered: Subject<boolean> = new Subject();
  private onDeviceRegistering: Subject<boolean> = new Subject();

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
    }),
  };

  previousRequests = [];

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private env: EnvironmentService,
    private audio: AudioService,
    private notificationService: PushNotificationsService,
  ) {
    // on first user that exists, get token for registering twilio device when audio is initialized
    combineLatest([this.userService.currentUser$, this.audio.initialized()])
      .pipe(
        filter(([_1, _2]) => !!_1),
        first())
      .subscribe(_ => this.handleToken());

    // every time we get a new twilio token, register the device with it
    this.identity
      .pipe(
        filter(ri => !!ri),
      )
      .subscribe( rippleIdentity =>
        this.audio.initialized().then( () => {
          if (this.twilioDevice === undefined) {
            this.twilioDevice = new Device(rippleIdentity.token, { allowIncomingWhileBusy: false, tokenRefreshMs: this.tokenRefreshMs });
            this.twilioDevice.register();
            this.log(`Twilio Device registered ${rippleIdentity.identity}, expires ${moment(rippleIdentity.expires).fromNow()}`);

            this.audio.setTwilioAudioHelper(this.twilioDevice.audio);
            this.bindDeviceEvents();
          } else {
            this.log(`Twilio Device updated ${rippleIdentity.identity}, expires ${moment(rippleIdentity.expires).fromNow()}`);
            this.twilioDevice.updateToken(rippleIdentity.token);

            if (this.twilioDevice.state === 'unregistered')
              this.twilioDevice.register();
          }
        }));

    this._activeCall.subscribe( call => {
      if (call) {
        window.addEventListener('beforeunload', this.beforeUnload);
        this.log(`Active Call ${call.parameters.CallSid} ${call.status()}`);
      } else {
        window.removeEventListener('beforeunload', this.beforeUnload);
      }
    });

    this.audio.isMuted().subscribe(m => this._isMuted = m);
    this.log('Initialized.');
  }

  beforeUnload(evt: BeforeUnloadEvent) {
    const message = 'Closing the window will end your current call, are you sure ou would like to leave this page?';
    console.log('Keenan - beforeunload', evt, this.activeCall);
    evt.preventDefault();
    return evt.returnValue = message;
  }

  /** wrapper for {@link AudioService.setInputDevice} */
  setInputDevice(deviceId: string) {
    this.audio.setInputDevice(deviceId);
    this.audio.muteInput(true);
  }

  ngOnDestroy(): void {
    this.log('Destroying...');
    if (this.twilioDevice !== undefined) this.twilioDevice.destroy();
    if (this.tokenTimeout !== undefined) clearTimeout(this.tokenTimeout);
    this.onCallIncoming.complete();
    this.onCallOutgoing.complete();
    this.onDeviceError.complete();
    this.onDeviceRegistered.complete();
    this.onDeviceRegistering.complete();
    this.identity.complete();
    this._activeCall.complete();
  }

  private handleToken() {
    const id = this.userService.currentUser$.value.id;
    this.http.get( `${this.env.localEnvironment.restEndpointUrl}/api/voice/token/${id}`, this.httpOptions )
      .pipe( map( data => data as RippleIdentity ))
      .subscribe( _identity => this.identity.next(_identity));
  }

  private bindDeviceEvents() {
    this.twilioDevice.on('incoming', this._callIncoming.bind(this));
    this.twilioDevice.on('error', o => this.onDeviceError.next(o));
    this.twilioDevice.on('registered', o => this.onDeviceRegistered.next(!!o));
    this.twilioDevice.on('registering', o => this.onDeviceRegistering.next(!!o));
    const deviceTokenEvents = ['tokenAboutToExpire', 'tokenWillExpire', 'unregistered'];
    deviceTokenEvents.forEach( e => this.twilioDevice.on(e, _ => this.handleToken()));
  }

  private bindCallEvents() {
    this.activeCall.on('accept', o => this.log('Call accepted - VoiceService'));
    this.activeCall.on('cancel',     _ => this.onCallEnd('cancel') );
    this.activeCall.on('error',      _ => this.onCallEnd('error') );
    this.activeCall.on('disconnect', _ => this.onCallEnd('end') );
    this.activeCall.on('mute', _ => (this.syncMuteWithCall(), this.log('syncing mute', _, this.activeCall.isMuted(), this._isMuted)));
  }

  private onCallEnd(reason: string) {
    this.log(`Call Ended: ${this.activeCall.status()}, ${reason}`);
    this.activeCall = undefined;
    this.syncMuteWithCall();
  }

  public callOutgoing(): Observable<OutgoingCallEvent> { return this.onCallOutgoing; }
  public callIncoming(): Observable<Call> { return this.onCallIncoming; }
  public deviceError(): Observable<TwilioError> { return this.onDeviceError; }
  public deviceRegistered(): Observable<boolean> { return this.onDeviceRegistered; }
  public deviceRegistering(): Observable<TwilioError> { return this.onDeviceRegistering; }

  private _callIncoming(call: Call) {
    this.log('matching incoming call with existing requests');
    const callData = new CallHelper(call);

    // for barges, calls come in for existing conversations

    // for dialOuts, calls come in for new conversations

    // for regular calls, calls come in for new conversations, identified by conversationId

    // regular calls should notify
    if (callData.isNormal())
      this.notificationService.notify(this.env.localEnvironment.notificationTitle, `Incoming Call ${callData.callName()}`);

    this.onCallIncoming.next(call);
  }

  fakeCall(barge: boolean = true) {
    const call = {
      parameters: { From: `+1${Array.from({length: 9}).map(_ => Math.floor(Math.random() * 10)).join('')}` },
      customParameters: new Map(),
      on: (a, _) => this.log('fake call on', a),
      accept: () => this.log('fake call accepted'),
      reject: () => this.log('fake call rejected'),
      isMuted: () => this.audio.inputIsMuted,
      disconnect: () => this.log('fake call disconnected'),
    } as any as Call;
    call.customParameters.set('isTest', 'true');
    if (barge) {
      call.customParameters.set('isBarge', 'true');
      setTimeout(_ => call.customParameters.set('from', '123456 - Test Call'), 3000);
    }
    this.onCallIncoming.next(call);
  }

  sendDigits(key: string) {
    if (this.activeCall)
      this.activeCall.sendDigits(key);
  }
  acceptCall(call: Call) {
    this.audio.addInputListener(this);

    this.activeCall = call;
    this.bindCallEvents();

    const _call = new CallHelper(call);

    let acceptCall = callEventAsPromise(call, 'accept');
    if (_call.isNormal() || _call.isDialOut())
      acceptCall = acceptCall.then(
          _ => callEventAsPromise(call, 'mute'))
        .then( wait(this.GATHER_DELAY) )
        .then( _ => {
          call.sendDigits(CallResponseDigits.Accept);
          call.mute(false);
        });

    acceptCall.then(_ => this.syncMuteWithCall());

    this.log('accept call');
  }

  deferCall(call: Call) {
    // when barging, the call that comes in cant be deferred, so we count it as a rejection
    if (!(new CallHelper(call)).isNormal())
      call.reject();

    callEventAsPromise(call, 'accept')
      .then( _ => callEventAsPromise(call, 'mute'))
      .then( wait(this.GATHER_DELAY) )
      .then( _ => {
        call.sendDigits(CallResponseDigits.Delay);
        call.mute(false);
        this.syncMuteWithCall();
      });

    this.log('defer call');
  }

  rejectCall(call: Call) {
    call.reject();
    call.disconnect();
    this.audio.removeInputListener(this);
    this.log('reject call');
  }

  ignoreCall(call: Call) {
    // should only happen when a user doesn't answer
    this.audio.removeInputListener(this);
    this.log('ignore call');
  }

  joinCall(conversation: Conversation, isMuted = false, isCoach = false) {
    const user = this.userService.currentUser$.value;
    // tslint:disable-next-line: triple-equals
    const isVoip = user.properties.onelineisvoip == '1';
    interface JoinResponse { success: boolean; supervisorSid: string; conferenceSid: string; }

    combineLatest([
      this.onCallIncoming.pipe<Call, Call[]>(
        startWith(null as Call),
        scan( (callsSinceStart, call) => callsSinceStart.concat(call), [])),
      this.http.post<JoinResponse>( `${this.env.localEnvironment.restEndpointUrl}/api/callcentre/call/join/`, {
        callId: conversation.id, from: user.id, isMuted, isCoach
      }, this.httpOptions)
    ]).pipe(first())
    .subscribe( ([callsSinceStart, result]) => {
        if (result.success) {
          const call = callsSinceStart.find( _call => _call && _call.parameters.CallSid === result.supervisorSid);
          this.setCallFromConversation(call, conversation);
          this.log('Successfully joined call.', call);
        }
      });

    this.onCallOutgoing.next({ conversation, user, isMuted, isCoach, isVoip });
  }

  noComplete<T>(observable: Observable<T>): Observable<T> {
    const out = new Subject<T>();
    observable.subscribe( r => out.next(r), e => out.error(e), () => {});
    return out;
  }

  dialOut(outgoingNumber: string, dialOutType: DialOutType) {
    interface DialOutResponse { success: boolean; callSid?: string; errors?: string[]; }

    const requestResponse = new AsyncSubject<DialOutResponse>();
    const resolveResponse = (resp: DialOutResponse) => {
      requestResponse.next(resp);
      requestResponse.complete();
    };
    const user = this.userService.currentUser$.value;
    // tslint:disable-next-line: triple-equals
    const isVoip = user.properties.onelineisvoip == '1';

    combineLatest([
      this.onCallIncoming
        .pipe<Call, Call[]>(
          startWith(null as Call),
          scan( (callsSinceStart, call) => callsSinceStart.concat(call), [])),
      this.http.post<DialOutResponse>(
        `${this.env.localEnvironment.restEndpointUrl}/api/callcentre/call/dialout/`,
        { dialOutType, outgoingNumber }, this.httpOptions)
        .pipe(
          // tap( resp => resolveResponse(resp)),
          catchError( e => { resolveResponse({success: false}); throw e; }),
          concatMap( resp => interval(100).pipe(take(30), mapTo(resp)) ),
          // this.noComplete
        )
    ]).pipe(
      // when we get a call that matches, set it and complete
      skipWhile(([callsSinceStart, result]) => {
        this.log('testing calls', result, callsSinceStart);
        if (result.success) {
          // check if we have a call that matches the callSid yet
          const call = callsSinceStart.find( _call => _call && _call.parameters.CallSid === result.callSid);
          if (call) {
            this.log('Successfully made outgoing call.', call);
            this.setCallFromDialOut(call, dialOutType, outgoingNumber);
            this.acceptCall(call);
            resolveResponse({success: true, callSid: result.callSid});
          } else
            return false; // wait for next call
        } else
          return true; // request failed, no call will be coming
      }),
      first())
    .subscribe( ([_, result]) => resolveResponse(result));

    this.onCallOutgoing.next({ user, isVoip, outgoingNumber, dialOutType });
    return requestResponse.asObservable();
  }

  startTestCall() {
    const user = this.userService.currentUser$.value;
    // tslint:disable-next-line: triple-equals
    const isVoip = user.properties.onelineisvoip == '1';
    interface TestCallResponse { success: boolean; callSid: string; }

    this.http.get<TestCallResponse>(`${this.env.localEnvironment.restEndpointUrl}/api/callcentre/call/test/${user.id}`, this.httpOptions)
      .pipe(first())
      .subscribe( );

    this.onCallOutgoing.next({ user, isVoip });
  }

  setCallFromConversation(call: Call, conversation: Conversation) {
    if (call) {
      call.customParameters.set('isBarge', 'true');
      const convName = conversation.linkedProperties.caller && conversation.linkedProperties.caller.name;
      const userName = conversation.linkedProperties.user && conversation.linkedProperties.user.name;
      call.customParameters.set('from', `<em>${getCallerName(convName) || conversation.id}</em> - ${userName}`);
    }
  }

  setCallFromDialOut(call: Call, type: DialOutType, number: string) {
    if (call) {
      call.customParameters.set('isDialOut', 'true');
      call.customParameters.set('to', `<em>${this.formatNumber(number)}</em>`);
    }
  }

  validateNumber(number: string) {
    const re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}(#\d{1,6})?$/im;
    return re.test(number);
  }

  formatNumber(fullNumber: string) {
    // makes number look like: +1 (800) 555-4444 ext. 767
    const [number, ext] = fullNumber.split('#', 2);
    const onlyNumbers = number.replace(/[^\d]/g, '').padStart(10, '0');
    const n = onlyNumbers.slice(-10).match(/(\d{3})(\d{3})(\d{4})/); // last 10 digits
    const cc = onlyNumbers.substring(0, onlyNumbers.length - 10); // any digits in front

    return `${cc ? `+${cc} ` : ''}(${n[1]}) ${n[2]}-${n[3]}${ext ? ` ext. ${ext}` : ''}`;
  }

  unsetCallFromConversation(call: Call) {
    if (call) {
      call.customParameters.set('isBarge', '');
      call.customParameters.set('from', '');
    }
  }

  mute(shouldMute?: boolean) {
    this.syncMuteWithCall();

    if (typeof shouldMute === 'undefined') shouldMute = !this._isMuted;
    this._isMuted = shouldMute;
    this.audio.muteInput(shouldMute);
    if (this.activeCall)
      this.activeCall.mute(shouldMute);
  }

  private syncMuteWithCall() {
    if (this.activeCall && this._isMuted !== this.activeCall.isMuted())
      this.audio.muteInput(this._isMuted = !this._isMuted);
  }

  public log(...args) {
    console.log('Keenan - Voice Service', ...args);
  }
}
