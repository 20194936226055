import { ShiftGroupWrapper } from "./shiftGroupWrapper";

export class ShiftBlock {

    public canModify = false;
    constructor(
        public shift: any,
        public name: String,
        public shiftGroupWrapper: ShiftGroupWrapper,
        public date: Date,
        public start: any,
        public end: any,
        public reservation: any,
        public isUsersShift: Boolean,
        public hasPhone: Boolean,
        public hasSMS: Boolean,
        public reserverName: String,
        public isLockedDown: Boolean,
        public isLockedDownSupervisorOnly: Boolean,
        public availabilityList: any,
        public isAvailabilityEnabled: Boolean
    )
    {

    }

    public createCopy() : ShiftBlock {
        return new ShiftBlock(
                this.shift,
                this.name,
                this.shiftGroupWrapper,
                this.date,
                this.start,
                this.end,
                this.reservation,
                this.isUsersShift,
                this.hasPhone,
                this.hasSMS,
                this.reserverName,
                this.isLockedDown,
                this.isLockedDownSupervisorOnly,
                this.availabilityList,
                this.isAvailabilityEnabled
        );
    }
}
