import { EnvironmentService } from 'src/app/Services/environment.service';
import { Component, OnInit, Inject, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';


@Component({
    selector: 'app-interpreter',
    templateUrl: './interpreter.component.html',
    styleUrls: ['./interpreter.component.scss']
  })
  export class InterpreterComponent implements OnInit {

    @ViewChild('details') divElementRef: ElementRef;
    divElement: HTMLElement;

    constructor(public dialogRef: MatDialogRef<InterpreterComponent>,
        public envService: EnvironmentService,
        public changeDetector: ChangeDetectorRef,
        @Inject(MAT_DIALOG_DATA) public data: any) {

        }

    interpreterHtml = `  <ul>
                            <li>CCASA - 1341</li>
                            <li>CASASC - 4055</li>
                            <li>UASAC - 3299</li>
                            <li>SACE - 2007</li>
                        </ul>
                        `;
    ngOnInit() {
        this.divElement = this.divElementRef.nativeElement;
        if (this.envService.localEnvironment.interpreterContent && this.envService.localEnvironment.interpreterContent !== '') {
            this.divElement.innerHTML = this.envService.localEnvironment.interpreterContent;
        } else
            this.divElement.innerHTML = this.interpreterHtml;

    }



}

