import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MessageService } from './message.service';
import { forkJoin, from, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { InternalCookieService } from '../cookie.service';
import { RouterService } from '../Hubs/router.service';
import { EnvironmentService } from '../environment.service';
import { EnvironmentVar } from './../../models/environmentvar';
import { BackendPagingOptions, IEntityPage, IWarpEntity, IWarpEntityType } from '../models/warp-entity';
import { CallReport } from 'src/app/Services/models/reports';
import { ITemplateBuilderTemplate } from 'src/app/Services/models/template-builder';


@Injectable({
  providedIn: 'root'
})


export class EntityDataService {
  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private cookieService: InternalCookieService,
    private routerService: RouterService,
    public envService: EnvironmentService
  ) {
    console.log('------------------ Entity Data Service Constructor ---------------------');


   }
// ./ngrok http -host-header="localhost:56696" 56696

 httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type'
    })
  };

/** GET heroes from the server */
getAll (entityTypeID: number): Observable<IWarpEntity[]> {
    console.log('getAll' + this.envService.localEnvironment.restEndpointUrl + entityTypeID);

  return this.http.get<any[]>(this.envService.localEnvironment.restEndpointUrl + '/api/v2/entityTypes/' + entityTypeID + '/', this.httpOptions).pipe(
        tap(conversations => this.log('fetched conversations')),
        catchError(this.handleError('getAll', []))
      );
}

getAllWithFilter (entityTypeID: number, filter: string, matchAll = true, otherParams = ''): Observable<any[]> {
  console.log('getAll' + this.envService.localEnvironment.restEndpointUrl + entityTypeID, this.httpOptions);

  // tslint:disable-next-line:max-line-length
  return this.http.get<any[]>(this.envService.localEnvironment.restEndpointUrl + '/api/v2/entityTypes/' + entityTypeID + '/?matchAll=' + matchAll + "&" + (otherParams === '' ? '' : (otherParams + '&')) + 'advancedFilter=' + filter, this.httpOptions).pipe(
        tap(conversations => this.log('fetched conversations')),
        catchError(this.handleError('getAll', []))
      );
}

getAllWithFilterNew (entityTypeID: number, filter: string, matchAll = true, otherParams = ''): Observable<any[]> {
    console.log('getAll' + this.envService.localEnvironment.restEndpointUrl + entityTypeID);

  // tslint:disable-next-line:max-line-length
  return this.http.get<any[]>(this.envService.localEnvironment.restEndpointUrl + '/api/aasasreporting/entitytype/' + entityTypeID + '/?matchAll=' + matchAll + "&" + (otherParams === '' ? '' : (otherParams + '&')) + 'advancedFilter=' + filter, this.httpOptions).pipe(
        tap(conversations => this.log('fetched conversations')),
        catchError(this.handleError('getAll', []))
      );
}

getRoles (): Observable<any[]> {
 console.log('Get Roles');
  // tslint:disable-next-line:max-line-length
  return this.http.get<any[]>(this.envService.localEnvironment.restEndpointUrl + '/api/roles/', this.httpOptions).pipe(
    tap(roles => this.log('fetched roles')),
    catchError(this.handleError('getRoles', []))
  );
}


getCallReport (filter: string, matchAll = true, otherParams = ''): Observable<CallReport> {
  otherParams = otherParams ? otherParams + '&' : '';
  const v2 = this.envService.useFormbuilderTicket ? '/v2' : '';
  // tslint:disable-next-line:max-line-length
  const url = `${this.envService.localEnvironment.restEndpointUrl}/api/aasasreporting${v2}/calls/?matchAll=${matchAll}&${otherParams}advancedFilter=${filter}`;

  console.log('get call report', url);
  return this.http.get<any>(url, this.httpOptions).pipe(
        tap(_ => this.log('fetched call report. ')),
        catchError(this.handleError('getAll', {}))
      );
}


getReportCFCs (): Observable<any[]> {
    console.log('get cfcs' + this.envService.localEnvironment.restEndpointUrl);
  // tslint:disable-next-line:max-line-length
  return this.http.get<any[]>(this.envService.localEnvironment.restEndpointUrl + '/api/aasasreporting/reportcfcs/', this.httpOptions).pipe(
        tap(conversations => this.log('fetched report cfcs.')),
        catchError(this.handleError('getAll', []))
      );
}

getAllConnectedToParentOfType (parentId: number, entityTypeID: number, loadChildren: number[] = null): Observable<IWarpEntity[]> {

    // only use this to get items that are linked to this parent... Not to find an item link from this parent!!
    // example a Conversation has links to caller,
    // user  don't use this to get those!!  Use the get command and the linked id of the caller as you already have it.
    // a Ticket is linked to a Conversation - we can use this with the conversation Id to get the ticket.

  const q = (loadChildren ? `?loadChildren=${loadChildren.join(',')}&` : '?') + 'sort=entityid';
  const url = `${this.envService.localEnvironment.restEndpointUrl}/api/v2/entityTypes/9999/${parentId}/${entityTypeID}${q}`;
  // tslint:disable-next-line:max-line-length
  console.log('getAllConnectedToParentOfType:', url);

  // tslint:disable-next-line:max-line-length
  return this.http.get<any[]>(url, this.httpOptions).pipe(
        tap(conversations => this.log(`fetched entities of type ${entityTypeID}`)),
        catchError(this.handleError('getAllConnectedToParentOfType', []))
      );
}


GetEntityWithChildren (entityID: number, childrenEntityTypes: string): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.http.get<any[]>(this.envService.localEnvironment.restEndpointUrl + '/api/v2/entityTypes/999/' + entityID + '/?loadChildren=' + childrenEntityTypes, this.httpOptions).pipe(
          tap(conversations => this.log('fetched conversations')),
          catchError(this.handleError('getConversations', []))
        );
  }

get (entityID: number): Observable<any> {
  // tslint:disable-next-line:max-line-length
  return this.http.get<any[]>(this.envService.localEnvironment.restEndpointUrl + '/api/v2/entityTypes/999/' + entityID + '/?loadChildren=-1', this.httpOptions).pipe(
        tap(conversations => this.log('fetched conversations')),
        catchError(this.handleError('getConversations', []))
      );
}

getByGuid (guid: string): Observable<any> {
  return this.http.get<any[]>(this.envService.localEnvironment.restEndpointUrl + '/api/v2/entityTypes/999/' + guid + '/', this.httpOptions).pipe(
        tap(conversations => this.log('fetched conversations')),
        catchError(this.handleError('getConversations', []))
      );
}

// tslint:disable-next-line:max-line-length
getPage(entityTypeId: number, page: number, pageSize: number, simpleFilter: string, advancedFilter: string, excludeAdvancedFilter: string ): Observable<any> {
    const otherParams = `draw=10&length=${pageSize}&start=${pageSize * page}${simpleFilter}`;
    // tslint:disable-next-line:max-line-length
    return this.http.get<any[]>(this.envService.localEnvironment.restEndpointUrl + '/api/v2/entitytypes/page/' + entityTypeId + '/?' + otherParams + '&advancedFilter=' + advancedFilter + '&excludeAdvancedFilter=' + excludeAdvancedFilter, this.httpOptions).pipe(
        tap(conversations => console.log(`Paged - ${entityTypeId}` , conversations)),
        catchError(this.handleError('getConversations', []))
      );
}



// tslint:disable-next-line:max-line-length
getAllReservations(startDate, endDate): Observable<any[]> {
    // tslint:disable-next-line:max-line-length //
    return this.http.get<any[]>(this.envService.localEnvironment.restEndpointUrl + `/api/aasas/getreservations/?startDate=${startDate}&endDate=${endDate}`, this.httpOptions).pipe(
            tap(conversations => console.log(`resrevaions`)),
        catchError(this.handleError('getConversations', []))
      );
}


// tslint:disable-next-line:max-line-length
getPageAll(entityTypeId: number, page: number, pageSize: number, simpleFilter: string, advancedFilter: string, excludeAdvancedFilter: string, advancedFilterUnion = '' ): Observable<any> {
    const otherParams = `draw=10&length=${pageSize}&start=${pageSize * page}${simpleFilter}`;
    // tslint:disable-next-line:max-line-length
    return this.http.get<any>(this.envService.localEnvironment.restEndpointUrl + '/api/v2/entitytypes/page/' + entityTypeId + '/?' + otherParams + '&advancedFilter=' + advancedFilter + '&excludeAdvancedFilter=' + excludeAdvancedFilter + '&advancedFilterUnion=' + advancedFilterUnion, this.httpOptions).pipe(
        tap(conversations => console.log(`Paged - ${entityTypeId}` , conversations.data)),
        catchError(this.handleError('getConversations', []))
      );
}




getStructure (entityTypeID: number, templateID: number): Observable<ITemplateBuilderTemplate> {
  const reqObj = {
    'subscriberId': this.envService.localEnvironment.mainSubscriberId,
    'entityTypeId': entityTypeID,
    'templateId': templateID
  };
  // tslint:disable-next-line:max-line-length
  return this.http.post<any>(this.envService.localEnvironment.restEndpointUrl + '/api/TemplateBuilder/TemplateBuilder/LoadTemplate', reqObj, this.httpOptions)
    .pipe(
      tap(_ => this.log('fetched template builder structure')),
      catchError(this.handleError('getStructure', {}))
    );
}

getFormBuilderStructure (entityTypeId: number): Observable<IWarpEntityType> {
  return this.http.get<any>(`${this.envService.localEnvironment.restEndpointUrl}/api/formBuilder/${entityTypeId}`, this.httpOptions).pipe(
    tap( _ => this.log(`fetched formbuilder structure ${entityTypeId}`)),
    catchError(this.handleError(`getStructure <${entityTypeId}>`, {}))
  );
}

GetStructureCustomFieldInModuleList(structure) {
    const retVal = {};
    structure.moduleLists.forEach(moduleList => {
        moduleList.modules.forEach(module => {
            module.cfims.forEach(cfim => {
                retVal[cfim.unchangeableName.toLowerCase()] = cfim;
            });
        });
    });
    return retVal;
}


// update (item: any): Observable<any> {
//   console.log('Updating', item);
//   return this.http.put<any>(environment.restEndpointUrl + '/api/v2/entityTypes/', item, this.httpOptions).pipe(
//         tap(conversations => this.log('fetched conversations')),
//         catchError(this.handleError('getConversations', []))
//       );
// }


acceptConversation(items: any[]): Observable<any> {
    console.log('Update', items);
  items = items.map(obj => {return {
    type: 'patch',
    entity: obj
  }; });
  // tslint:disable-next-line:max-line-length
  return this.http.post<any>(this.envService.localEnvironment.restEndpointUrl + '/api/callcentre/textandchat/accept/', items, this.httpOptions).pipe(
        tap(conversations => this.log('fetched conversations')),
        catchError(() => {
            items = items.map(obj => {return  obj.entity;
               });
            return this.entitySyncUpdate(items);
        })
      );
}

entitySyncUpdateNoCatch (items: any[]): Observable<any> {
  console.log('Update', items);
  items = items.map(obj => {return {
    type: 'patch',
    entity: obj
  }; });
  return this.http.post<any>(this.envService.localEnvironment.restEndpointUrl + '/api/v2/entitySync/', items, this.httpOptions);
}

entitySyncUpdate (items: any[]): Observable<any> {
  return this.entitySyncUpdateNoCatch(items).pipe(
      tap(conversations => this.log('fetched conversations')),
      catchError(this.handleError('entitySyncUpdate', []))
    );
}

entitySyncCreate (items: (Partial<IWarpEntity> & Pick<IWarpEntity, 'offlineguid' | 'entityTypeId'>)[]): Observable<IWarpEntity[]> {
  console.log('Create', items);
  const toSend = items
    .map((obj) => ({
      type: 'post',
      entity: obj,
    }));
  return this.http.post<any>(this.envService.localEnvironment.restEndpointUrl + '/api/v2/entitySync/', toSend, this.httpOptions).pipe(
        tap(_ => this.log('Create Succeeded')),
        catchError(this.handleError('entitySyncCreate', []))
      );
}

create (item: any): Observable<any> {
  console.log('Creating', item);
  return this.http.post<any>(this.envService.localEnvironment.restEndpointUrl + '/api/v2/entityTypes/', item, this.httpOptions).pipe(
        tap(conversations => this.log('fetched conversations')),
        catchError(this.handleError('getConversations', []))
      );
}

createShiftReservation (item: any): Observable<any> {
    console.log('Creating a reservation', item);
    return this.http.post<any>(this.envService.localEnvironment.restEndpointUrl + '/api/aasas/ReserveShift/', item, this.httpOptions).pipe(
          tap(conversations => this.log('fetched conversations')),
          catchError(this.handleError('getConversations', []))
        );
  }


delete (item: any): Observable<any> {
  console.log('Deleting', item);
  return this.http.delete<any>(this.envService.localEnvironment.restEndpointUrl + '/api/v2/entityTypes/' + item.id, this.httpOptions).pipe(
        tap(conversations => this.log('delete ' + item.id)),
        catchError(this.handleError('delete', []))
      );
}


getUserList(): Observable<any[]> {
    console.log('getUserList');

  return this.http.get<any[]>(this.envService.localEnvironment.restEndpointUrl + '/api/aasas/users/', this.httpOptions).pipe(
        tap(users => this.log('fetched Users list')),
        catchError(this.handleError('getAll', []))
      );
}


getCrisisClients(search: string): Observable<IEntityPage> {
    if ( !search || (typeof(search) !== 'string'))
      return from([{ draw: 0, recordsTotal: -1, recordsFiltered: -1, columnFilters: {}, data: [], ids: [] }]);
      // for phoneNumbers like (403) 831-0694


    const finalSearch = search.split(/\s+/).filter(x => x.length > 0).map(x => `%${x.trim()}%`).join('|');

    const url = this.envService.localEnvironment.restEndpointUrl + `/api/aasas/getCrisisClients/`;
    const body = this.getPostPageObj([], {
      AdvancedFilterStringIntersect: 'CCASAClientType[]CRISIS',
      AdvancedFilterStringUnion: [
          `First Name=${finalSearch}`,
          `Last Name=${finalSearch}`,
          `Mnemonic=${finalSearch}`,
          `Email=${finalSearch}`
        ].join(','),
      EntityTypeId: 627,
      OrderByColumn: 'orderednametext'
    });
    body['phoneNumber'] = search;
    return this.http.post<IEntityPage>(url, body, this.httpOptions);

}




searchFilterLikeUnion(search: string, entityId: number, key: {name: string, type: string}[], intersectString: string = '') {
  if (!search)
    return of({data: []});

    search = search.toString();
    const searchArr = search.split(' ');
    const searchArrModified = searchArr.map(x => `${x.trim()}`).filter(x => x.length > 0);
    const url = `${this.envService.localEnvironment.restEndpointUrl}/api/v2/entityTypes/page/${entityId}`;
    const searchKeyArr = key.map((x) => {
      if (x.type === 'string') {
      return `${x.name}=${searchArrModified.map(y => `%${y}%` ).join('|')}`;
      } else if (x.type === 'number' && !isNaN(+search)) {
        return `${x.name}=${searchArrModified.map(y => `${y}` ).join('|')}`;
      }
      else {
        return null;
      }
    }

    );
    const searchString = searchKeyArr.join(',');
    const body = {
      entityIds: [],
      pagingOptions: {
        AdditionalEntityTypes: null,
        AdvancedFilterStringUnion: searchString,
        AdvancedFilterStringIntersect: intersectString,
        Descending: false,
        DontShowAllRecordsUpAndDownTree: false,
        EntityTypeId: entityId,
        ExcludeAdvancedFilterString: '',
        FilterSearchDate: false,
        FilterSearchDateTime: false,
        PageIndex: 0,
        PageSize: 100,
        SearchTerm: null,
        parentId: null,
        sendOnlyIds: false
      }

    };
    return this.http.post<IEntityPage>(url, body, this.httpOptions);

}

searchAnhcFilesPhoneNumbers(searchNumber: string) {

  if (!searchNumber)
    return null;

    searchNumber = searchNumber.toString();
    const searchArr = searchNumber.split(';');
    const searchArrModified = searchArr.map(x => `${x.trim()}`);
    const finalSearch = searchArrModified.join('|');
    const url = `${this.envService.localEnvironment.restEndpointUrl}/api/v2/entityTypes/page/769`;
    const body = {
      entityIds: [],
      pagingOptions: {
        AdditionalEntityTypes: null,
        AdvancedFilterStringIntersect: 'FileStatus=Open',
        AdvancedFilterStringUnion: [
          `cellphone=${finalSearch}`,
          `homephone=${finalSearch}`,
          `workphone=${finalSearch}`,
        ].join(','),
        Descending: false,
        DontShowAllRecordsUpAndDownTree: false,
        EntityTypeId: 769,
        ExcludeAdvancedFilterString: '',
        FilterSearchDate: false,
        FilterSearchDateTime: false,
        PageIndex: 0,
        PageSize: 100,
        SearchTerm: null,
        parentId: null,
        sendOnlyIds: false
      }

    };
    return this.http.post<IEntityPage>(url, body, this.httpOptions);

}


  getPossibleClientMatches(caller: IWarpEntity): Observable<IEntityPage['data']> {
    let number = caller.properties.callerphone;
    const clients = caller.linkedPropertiesList.filter(lp => lp.unchangeablename === 'clients').map(lp => lp.id);
    if (!number || number.length === 0) {
      return from([]);
    }

    number = number.replace("+1", "");
    const bodyIds = this.getPostPageObj(clients, {});
    const bodySearch = this.getPostPageObj([], {
      AdvancedFilterStringIntersect: 'CCASAClientType[]CRISIS',
      AdvancedFilterStringUnion: [
          `Telephone1=${number}`,
          `Telephone2=${number}`,
        ].join(','),
      EntityTypeId: 627,
    });

    const url = this.envService.localEnvironment.restEndpointUrl + '/api/v2/entityTypes/page/627/';

    return forkJoin([
      clients.length > 0 ? this.http.post<IEntityPage>(url, bodyIds, this.httpOptions) : from([{data: []}]),
      this.http.post<IEntityPage>(url, bodySearch, this.httpOptions),
    ]).pipe(
      map(([matching, filtered]) => matching.data.concat(filtered.data))
    );

  }

  getPostPageObj(entityIds: number[], obj: Partial<BackendPagingOptions>) {
    const defaultObj: BackendPagingOptions = {
      AdvancedFilterStringIntersect: '',
      AdvancedFilterStringUnion: '',
      AdditionalEntityTypes: null,
      AllMustMatch: false,
      Descending: false,
      DontShowAllRecordsUpAndDownTree: false,
      EntityTypeId: -1,
      ExcludeAdvancedFilterString: '',
      FilterSearchDate: false,
      FilterSearchDateTime: false,
      OrderByColumn: '',
      PageIndex: 0,
      PageSize: 100,
      SearchTerm: null,
      parentId: null,
      sendOnlyIds: false
    };

    return { entityIds, pagingOptions: Object.assign(defaultObj, obj) };
  }

//ordered by most recent
getSessions(pageSize: number, clientId: number){
  const url = this.envService.localEnvironment.restEndpointUrl + '/api/v2/entityTypes/page/633/?order[0].column=0&order[0].dir=asc&columns[0].data=orderednametext&loadChildren=&sendOnlyIds=true/';
  const body = {
    sendMinimum: false,
    entityIds: [],
    pagingOptions: {
      PageIndex: 0,
      PageSize: 3,
      AllMustMatch: false,
      OrderByColumn: "date",
      Descending: true,
      FilterSearchDate: false,
      FilterSearchDateTime: false,
      DontShowAllRecordsUpAndDownTree: false,
      SearchTerm: null,
      AdditionalEntityTypes: null,
      parentId: null,
      AdvancedFilterStringIntersect: `clients_lid=${clientId}`,
      AdvancedFilterStringUnion: "",
      ExcludeAdvancedFilterString: "",
      EntityTypeId: 633,
      sendOnlyIds: false
    }
  };
  return this.http.post<any>(url, body, this.httpOptions);
}


getClientFiles(clientId: number, templates: string) {


  const AdvancedFilterStringUnion = templates.length > 0 ? `clientfiletemplate=${templates}` : "";

  const url = this.envService.localEnvironment.restEndpointUrl + '/api/v2/entityTypes/page/643/?order[0].column=0&order[0].dir=asc&columns[0].data=orderednametext&loadChildren=&sendOnlyIds=true/';
  const body = {
    sendMinimum: false,
    entityIds: [],
    pagingOptions: {
      PageIndex: 0,
      PageSize: 100,
      AllMustMatch: false,
      Descending: true,
      FilterSearchDate: false,
      FilterSearchDateTime: false,
      DontShowAllRecordsUpAndDownTree: false,
      SearchTerm: null,
      AdditionalEntityTypes: null,
      parentId: null,
      AdvancedFilterStringIntersect: `clients_lid=${clientId}`,
      AdvancedFilterStringUnion,
      ExcludeAdvancedFilterString: "",
      EntityTypeId: 643,
      sendOnlyIds: false
    }
  };
  return this.http.post<any>(url, body, this.httpOptions);

}

linkClient(clientId: number, conversationId: number, linkAll: boolean, unlink: boolean){
  const path = unlink ? 'unlink-client/' : 'link-client/';
  const url = this.envService.localEnvironment.restEndpointUrl + '/api/aasas/' + path;
  const body = {
    clientId,
    conversationId,
    linkAll
  };

  return this.http.post(url, body, this.httpOptions);
}

getAnhcFileNumber() {
  const url = this.envService.localEnvironment.restEndpointUrl + '/api/anhc/getNextNumber';
  return this.http.get<number>(url, this.httpOptions);
}



getRandomConversation(startDate, endDate, ticketType, hub, numberCalled, callMethod): Promise<any> {

    const retVal: Promise<any> = new Promise<any>((resolve, reject) => {
        // tslint:disable-next-line:max-line-length
        this.post('/api/aasas/randomTicket/', { type: ticketType, startDate: startDate , endDate: endDate, hub: hub, numberCalled: numberCalled, callMethod: callMethod })
        .subscribe(result => {
            if (result) {
                console.log('getRandmon: ', result);
                if (result) {
                this.get(result.entityId).subscribe(
                     result2 => {
                         resolve (result2);
                     });
                    } else {
                        resolve (result);
                    }
            } else {
                reject('Create Server error, please try again.');
            }
        });
    });

    return retVal;
}

post(location: string, payload: any): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.http.post(this.envService.localEnvironment.restEndpointUrl + location, payload, this.httpOptions);
}


/**
 * Handle Http operation that failed.
 * Let the app continue.
 * @param operation - name of the operation that failed
 * @param result - optional value to return as the observable result
 */
private handleError<T> (operation = 'operation', result?: T) {
  return (error: any): Observable<T> => {

    if (error.status === 401) {
      this.routerService.redirectToLoginPage();
    } else {
        //window.location.href = window.location.protocol + '//' + window.location.hostname + '/Login.aspx';
        if (error.status === 500 && operation === 'getStructure')
            window.location.href = this.envService.localEnvironment.restEndpointUrl + "/users/";
        // else
        //   this.routerService.redirectToLoginPage();
    }

    // TODO: send the error to remote logging infrastructure
    console.error('Entity Data', error);
    // console.error(error); // log to console instead

    // TODO: better job of transforming error for user consumption
    this.log(`${operation} failed: ${error.message}`);

    // Let the app keep running by returning an empty result.
    return of(result as T);
  };
}

/** Log a HeroService message with the MessageService */
private log(message: string) {
  this.messageService.add(`Conversation Service: ${message}`);
}

}
