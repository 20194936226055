import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-typing-indicator',
  templateUrl: './typing-indicator.component.html',
  styleUrls: ['./typing-indicator.component.scss']
})
export class TypingIndicatorComponent implements OnInit {

  @Input() callerName: string;

  isTyping:Boolean = false;
  
  constructor() { }

  ngOnInit() {
    this.isTyping = true;
  }

}
