export const lang = 'en';

declare const require;
const translations = {
  en: () => require(`raw-loader!./locales/messages.en.xlf`),
  fr: () => require(`raw-loader!./locales/messages.fr.xlf`),
};

export function getTranslations() {
    return translations[lang]();
}
