import { SignalRConnectionService, SignalRState } from './Services/signal-r-connection.service';
import { environment as globalEnvironment } from './../environments/environment';
// import { CallerService } from './Services/Data/caller.service';
import { NgZone, Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter,
  OnDestroy, ViewChild, Injector, PlatformRef } from '@angular/core';
// import { EntityService, EntityMessage } from './Services/Hubs/entity.service';
// import { EntityTypeService, EntityTypeMessage } from './Services/Hubs/entity-type.service';
import { UserService } from './Services/Data/user.service';
import { InternalCookieService } from './Services/cookie.service';
import { Router, RoutesRecognized } from '@angular/router';
import { ConversationsService, Conversation } from './Services/Data/conversations.service';
// import { EntityDataService } from './Services/Data/entity-data.service';
import { Subscription, BehaviorSubject } from 'rxjs';
import { PushNotificationsService } from './Services/Notifcations/push-notifications.service';
import * as $ from 'jquery';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
// import { ChromeRequiredDialogComponent } from './dialogs/chrome-required-dialog/chrome-required-dialog.component';
import { SignalrDisconnectDialogComponent } from './dialogs/signalr-disconnect-dialog/signalr-disconnect-dialog.component';

import { SignalrConnectionCheckerService } from './Services/Hubs/signalr-connection-checker.service';

// Phone app imports
import { Deploy } from 'cordova-plugin-ionic/dist/ngx';
import { NotificationService } from './Services/Notifcations/notification.service';
import { AuthService } from './Services/Hubs/auth.service';
import { Plugins, AppState } from '@capacitor/core';

import { Platform } from '@ionic/angular';
import { MobileInAppNotificationService } from './Services/Notifcations/mobile-in-app-notification.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { MobileConversationTabService } from './Services/Data/mobile-conversation-tab.service';
import { EnvironmentService } from './Services/environment.service';
import { EnvironmentVar } from './models/environmentvar';
import { SoundContext, AudioService } from 'src/app/Services/audio.service';
import { ICMDialogComponent } from './dialogs/icm-client-dialog/icm-dialog.component';
import { debounceTime } from 'rxjs/operators';
import { NoVoipDialogComponent } from './no-voip-dialog/no-voip-dialog.component';
// import { HubConnectionService } from './Services/Hubs/hub-connection.service';

const { SplashScreen, Network, App  } = Plugins;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    // providers: [ConversationsService]
})
export class AppComponent implements OnInit, OnDestroy {
    // title = '';
    user: any;
    active = true;
    isVoip = false;
    acknowledgeChrome = false;
    userSubscription: Subscription;
    routeSubscription: Subscription;
    showMenu = true;

    // chromeRequiredDialogRef: MatDialogRef<ChromeRequiredDialogComponent>;
    SignalrDisconnectDialogRef: MatDialogRef<SignalrDisconnectDialogComponent>;

  @BlockUI() blockUI: NgBlockUI;

  firstConnected = false;
  disconnectDialogOpen = false;
  disconnectDialogRef: any;
  // platformName = '';
  noAuthorizeRoutes = ["/resource-dashboard"];
  isMobileApp = globalEnvironment.isMobileApp;
  updateSubscription: Subscription;
  iOSApp = false;
  // logoSrc = '';
  // logoAlt = '';
  // avatarMenuResourcesLinks = [];

  // mobile only
  userLoggedIn = false;
  updateFound = false;
  updateProgress = 0;
  currentStatus = '';
  showSplash = false;

  hasInternetConnection = true;
  hasConnectionMonitor = new BehaviorSubject<boolean>(true);
  timerTillReload: any;
  uiblocked = false;
  uiblockedBecauseOfHub = false;

  connected = false;
//  firstConnected = true;
  count = 0;
  signalList: any[] = [];
  signalRConnectionState = SignalRState.initializing;
  timeDisconnected = 0;

  constructor(
    private ngZone: NgZone,
    // private chatService: ChatService,
    // private entityService: EntityService,
    // private entityTypeService: EntityTypeService,
    // private entityDataService: EntityDataService,
    private audio: AudioService,
    private userService: UserService,
    private authService: AuthService,
    private pushNotifcationService: PushNotificationsService,
    private cookieService: InternalCookieService,
    // private route: ActivatedRoute,
    public router: Router,
    private chromeRequiredDialog: MatDialog,
    // public hubService: HubService,
    private notificationService: NotificationService,
    private injector: Injector,
    private deploy: Deploy,
    private mobileInAppNotificationServer: MobileInAppNotificationService,
    private platform: Platform,
    private mobileConversationTabService: MobileConversationTabService,
    // private hubConnectionServer: HubConnectionService,
    private signalrDisconnectDialog: MatDialog,
    private signalRService: SignalRConnectionService,
    private signalRCheck: SignalrConnectionCheckerService,
    // modalCtrl: ModalController
    public envService: EnvironmentService,
    public dialog: MatDialog
  ) {
    this.user = {
      name: "N",
      properties: { email: "", userinactive: "true" },
      id: 0,
    };
    console.log('---------------------Environment Var--------------------', this.envService.localEnvironment);

    this.showSplash = false;
    document.getElementById('splashPage').style.display = 'none';
    document.getElementById('thebody').style.display = 'block';
    document.getElementById('thebody').style.textAlign = 'inherit';

    console.log(`---------- isMobile: ${this.isMobileApp} ----------`)

    if (this.isMobileApp) {
      this.initMobileApp();

      console.log('---------- Subscribing to should update app ----------');
      this.updateSubscription = this.envService.shouldUpdateApp.subscribe(_ => this.tryDoUpdates());
    }

    if (this.ifRouterNeedAuthorize(router.url)) {
      this.userSubscription = userService.currentUser$.subscribe((u) => {
        this.isVoip = u && u.properties && u.properties.onelineisvoip == '1';
        // only open dialog for no voip support for mobile app if it's a different user or there's no user set currently and user has voip enabled
        if  (this.isVoip && this.isMobileApp && (!this.user || (this.user.id !== u.id))) {
          this.dialog.open(NoVoipDialogComponent, {
            hasBackdrop: false,
            minHeight: '200',
            panelClass: 'dialog-responsive'
        } );
        };
        this.user = u;
        // tslint:disable-next-line: triple-equals
        // display no voip dialog if user is on mobile app and has voip enabled.
      });
      this.pushNotifcationService.requestPermission();
    }

  }


    ngOnInit() {

        $(document).bind('keyup keydown', function (e) {
            if (e.ctrlKey && e.keyCode === 80) {
                if (window.location.pathname === '/scheduling') {
                    $('#weeklyPrint').click();
                    return false;
                } else if (window.location.pathname === '/scheduling-monthly') {
                    $('#monthlyPrint').click();
                    return false;
                } else {
                    return true;
                }
            }
        });
        // this.routeSubscription = this.route.queryParams.subscribe(params => {
        //     if (params.token) {
        //         this.cookieService.setCookie('token', params.token);
        //         console.log('Query Strings', params);
        //     }
        //     if (params.username) {
        //         this.cookieService.setCookie('username', params.username);
        //     }
        this.router.events.subscribe(val => {
            if (val instanceof RoutesRecognized) {
                const conversationID = val.state.root.firstChild.params.conversationID;
                if (conversationID) {
                    this.showMenu = false;
                } else {
                    this.showMenu = true;
                }
            }
        });

        if (this.ifRouterNeedAuthorize(window.location.pathname)) {
            let subscribable = this.userService.get();
            if (subscribable) {
              subscribable.subscribe(user => {
                if (user.subscriberid === 115) {
                    console.log('App component redirect. ');
                    this.router.navigate(['/scheduling']);
                }
              });
            }

        }

        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////
        ///  Signal R Connection State Management
        ///////////////////////////////////////////////////////////////////////////////////////////////////////////////

        // // this.signalRService.connectionChanged.subscribe(connected => {
        // //     console.log('receiving signalR');
        // //     this.connected = connected;
        // //     if (connected) {
        // //         console.log('signalR Connected - conversations:', this.signalRService.getHubService().connection);
        // //         if (this.firstConnected) {
        // //             this.firstConnected = false;
        // //         }
        // //     }
        // //   });

        this.signalRService.signalrReconnected.subscribe(reconnected => {
            if (reconnected) {
                console.log('signalR Reconnected:');
                this.signalRReconnected();
            }
        });

        this.signalRService.signalrState.subscribe(state => {
            console.log('State Notified', state);
            this.signalRConnectionState = state;
            if (this.signalRConnectionState === SignalRState.disconnected) {
                this.timeDisconnected = Date.now();
            } else if (this.signalRConnectionState === SignalRState.connected) {
                this.timeDisconnected = 0;
                this.signalRReconnected();
            }

        });
        if (!this.isMobileApp) {
        this.signalRService.signalrConnectionProblem.subscribe(problem => {
            if (problem) {
                console.log('SignalrConnection Problem');
                if (!this.disconnectDialogOpen && !this.isMobileApp) {
                    this.PlayAudio();
                }
                this.signalRDisconnected();
            }
        });
      }
    }

    PlayAudio() {
        // console.log('Playing Audio', this.signalRConnectionState);
        this.audio.playAudio(SoundContext.Alert, 'assets/audio/NewMessage.mp3');
    }

    GetSignalRState() {
        switch (this.signalRConnectionState) {
            case SignalRState.initializing:
                return 'Initializing';
            case SignalRState.connected:
                return 'Connected';
            case SignalRState.reconnecting:
                return 'Reconnecting';
            case SignalRState.disconnected:
                return 'Disconnected';
            default:
                return 'Unknown';
        }
    }




  async configureChannel() {
    // alert('configure channel complete');
    console.log(`--------- Configuring channel: ${globalEnvironment.mobileEndpoints.mobileUpdateChannel} --------`);
    await this.deploy.configure({ appId: globalEnvironment.mobileEndpoints.mobileUpdateAppID, channel: globalEnvironment.mobileEndpoints.mobileUpdateChannel });
  }

  async performUpdate() {
    const update = await this.deploy.checkForUpdate();
    console.log("update avalible: ", update.available)
    console.log("update: ", update)
    if (update.available) {
      this.updateFound = true;
      await this.deploy.downloadUpdate((progress) => {
        this.currentStatus = 'Downloading Update';
        this.updateProgress = progress;
      });
      await this.deploy.extractUpdate((progress) => {
        this.currentStatus = 'Extracting Update';
        this.updateProgress = progress;
      });
      alert('Update Complete!\nPress OK to restart the app.');
      await this.deploy.reloadApp();
    } else {
      let updateChecker = this.router.events.pipe(debounceTime(2000)).subscribe(async (event) => {
        const update = await this.deploy.checkForUpdate();
        console.log("Checking for update", update);
        console.log("Checking for update is avalible? ", update.available)
        if (update.available) {
          if (confirm('A new update is available. Would you like to update now?')) {
            this.updateFound = true;
            await this.deploy.downloadUpdate((progress) => {
              this.currentStatus = 'Downloading Update';
              this.updateProgress = progress;
            });
            await this.deploy.extractUpdate((progress) => {
              this.currentStatus = 'Extracting Update';
              this.updateProgress = progress;
            });
            alert('Update Complete!\nPress OK to restart the app.');
            await this.deploy.reloadApp();
          } else {
            updateChecker.unsubscribe();
            alert('Please restart the app to update later.');
          }
        }
      });
    }
  }

  getNumUnreadTotal() {
    return this.mobileInAppNotificationServer.countUnread(this.injector);
  }

  getCurrentRouteUrl() {
    return this.router.url;
  }

  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    try {
      if (this.updateSubscription && !this.updateSubscription.closed)
        this.updateSubscription.unsubscribe();

      this.userSubscription.unsubscribe();
      this.routeSubscription.unsubscribe();
    } catch (e) {
      console.error('Error in app destroy', e);
    }

  }

  ngAfterViewInit() {
    // if (!this.acknowledgeChrome) {
    //   setTimeout(() => this.chromeDetect(), 800);
    // }
  }

  onConversationSelected(selected: any) {
    console.log("App Conversation selected: ", selected);
  }

  navToIcm() {
    this.envService.navToICM();
  }

  AudioSettingsClick() {
    this.audio.openSettings();
  }

  openFormAggregateDialog() {

    const dialogRef = this.dialog.open(ICMDialogComponent, {
      height: '90vh',
      width: '100vw',

      data: {
        clientUrl: this.envService.getICMUrl(`/clinical/reporting/formsaggregate`),
        title: 'Forms Aggregate'
      },
    });
  }

  SettingsClick() {
    console.log(
      "Settings: " +
      this.envService.localEnvironment.restEndpointUrl +
        "/Users/details/id/" +
        this.user.id
    );
    window.location.href =
      this.envService.localEnvironment.restEndpointUrl + "/Users/details/id/" + this.user.id;
  }

  TestNotification() {
    //send a notification to the screan.
    // tslint:disable-next-line:max-line-length
    this.pushNotifcationService.notify(
      this.envService.localEnvironment.notificationTitle,
      "This is a test of the system notifications."
    );
  }

  ScheduleOnly() {
    if (this.user && this.user.subscriberid)
      return this.user.subscriberid === 115;
    else return false;
  }
  Logout() {
    console.log('Logout: ', this.envService.localEnvironment.restEndpointUrl, `/Users/details/id/${this.user.id}`);

    // KG: I dont know why this wasnt here, but it probably should be
    this.authService.logOut();

    if (this.envService.isUnified)
      window.location.href = window.location.origin;
    else
      window.location.href = this.envService.localEnvironment.restEndpointUrl + "/logout.aspx";
  }

  OpenResourceLink(url: string) {
    window.open(url, "_blank");
  }

  // private chromeDetect() {
  //   // console.log("chrome", window['chrome']);
  //   var isChrome = !!window["chrome"]; // && (!!window['chrome']['webstore'] || !!window['chrome']['runtime']);

  //   if (!isChrome && !globalEnvironment.isMobileApp) {
  //     this.chromeRequiredDialog.open(ChromeRequiredDialogComponent, {
  //       minWidth: "25vw",
  //       disableClose: true,
  //       hasBackdrop: true,
  //     });
  //     this.acknowledgeChrome = true;
  //   }
  // }

  public ifRouterNeedAuthorize(router) {
    return (
      this.noAuthorizeRoutes.findIndex(
        (item) => item === router || router.includes(item)
      ) === -1
    );
  }

  public signalRDisconnected() {
    console.log('signalRDisconnected');
    if (!this.disconnectDialogOpen) {
        // play audio to wake up the system.
        this.disconnectDialogOpen = true;
        console.log('signalRDisconnected Open Dialog');
        this.disconnectDialogRef = this.signalrDisconnectDialog.open(SignalrDisconnectDialogComponent, {
            minWidth: '25vw',
            disableClose: true,
            hasBackdrop: true
        });
        this.disconnectDialogRef.afterClosed().subscribe(result => {
            this.disconnectDialogOpen = false;
        });
    }
    // if (this.disconnectDialogOpen === false) {
    //     // play audio to wake up the system.
    //     this.PlayAudio();
    //   this.disconnectDialogRef = this.signalrDisconnectDialog.open(SignalrDisconnectDialogComponent, {
    //     minWidth: '25vw',
    //     disableClose: true,
    //     hasBackdrop: true
    //   });
    //   this.disconnectDialogOpen = true;
    //   this.disconnectDialogRef.afterClosed().subscribe(result => {
    //     this.disconnectDialogOpen = false;
    // });
    // } else {
        // this.hubConnectionServer.reconnectToHubService().then(reconnected => {
        //     if (reconnected) {
        //       console.log('signalR reconnection at: ', this.hubConnectionServer.getHubService().connection);
        //       this.blockUI.stop();
        //       clearTimeout(this.timerTillReload);
        //       this.uiblockedBecauseOfHub = false;
        //       if (this.disconnectDialogRef) {
        //         this.disconnectDialogRef.close();
        //         this.disconnectDialogOpen = false;
        //     }
        //     }
        //   });
    // }
  }

/*
 *  <------------------------------------------------- IZZY APP SPECIFIC FUNCTIONS ---------------------------------------->
 * These functions are and should be used for the app version only as managing it is different from the web version
 *
 */

  tryDoUpdates() {
    console.log(`---------- Should do updates? ${globalEnvironment && globalEnvironment.mobileEndpoints && globalEnvironment.mobileEndpoints.enableMobileUpdate} ----------`);
    if (globalEnvironment && globalEnvironment.mobileEndpoints && globalEnvironment.mobileEndpoints.enableMobileUpdate === true) {
      this.configureChannel().then(val => {
        console.log(`---------- Channel Configured ----------`);
        this.performUpdate().then(doit => {
          console.log(` ========= Update done [${globalEnvironment.mobileEndpoints.mobileUpdateChannel}] ========= `);
        });
      });
    }
  }

  initMobileApp() {
    if (this.cookieService.isiOS()) {
      this.iOSApp = true;
    }

    this.tryDoUpdates();

    this.mobileConversationTabService.setConversationService(this.injector);

    this.hasConnectionMonitor.subscribe((connected) => {
      console.log('hasConnectionMonitor triggered' + connected);
      if (connected) {
        this.blockUI.stop();
        this.uiblocked = false;
        this.hasInternetConnection = true;
        if (this.timerTillReload) {
          clearTimeout(this.timerTillReload);
          this.timerTillReload = null;
        }
      } else {
        this.blockUI.start('No internet connection.');
        this.uiblocked = true;
        this.hasInternetConnection = false;
        if (!this.timerTillReload) {
          this.timerTillReload = setTimeout(() => { window.location.reload(); }, 10000);
        }
      }
    });

    App.addListener('appStateChange', (state: AppState) => {
      // state.isActive contains the active state
      console.log('App state changed. Is active: ' + state.isActive);
      // For future use
      if (state.isActive) {
        // Check if signalR is still alive
        this.signalRCheck.SendPing();
        if (this.isMobileApp) {
          // Update convos for the mobile app off pause
          this.ngOnInit();
          //this.mobileConversationTabService.setConversationService(this.injector);
        }
      }
    });
    SplashScreen.hide();

    this.notificationService = this.injector.get(NotificationService);
    this.authService.loggedIn.subscribe((loggedIn) => {
      this.userLoggedIn = loggedIn;
      console.log('this.userLoggedIn', this.userLoggedIn);
      if (loggedIn) {
        if (!this.cookieService.isWeb()) {
          this.notificationService.registerNotificationMobile(this.injector);
        }
      } else {
        this.firstConnected = false;
      }
    });
    Network.addListener('networkStatusChange', (status) => {
      this.ngZone.run(() => {
        this.hasConnectionMonitor.next(status ? status.connected : true);
        console.log('Network status changed', this.hasConnectionMonitor.getValue());
      });

    });
  }



  public signalRReconnected() {
      console.log('closing the signalR dialog');
      if (this.disconnectDialogRef) {
          console.log('closing the signalR dialog 1', this.disconnectDialogRef);
          this.disconnectDialogRef.close();
          this.disconnectDialogOpen = false;
      }
  }


    ShowResource(item) {
        if (item.subscriberSpecific) {
            if (item.subscriberSpecific.some(e => e === this.user.subscriberid)) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

}

