import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { ScheduleService } from 'src/app/Services/Data/schedule.service';
import * as moment from 'moment';

@Component({
  selector: 'app-auto-assign-dialog',
  templateUrl: './auto-assign-dialog.component.html',
  styleUrls: ['./auto-assign-dialog.component.scss']
})
export class AutoAssignDialogComponent implements OnInit {

  public start: Date;
  public end: Date;

  public loading = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AutoAssignDialogComponent>,
    public scheduleService: ScheduleService,
    public router: Router) {
    this.start = new Date(this.scheduleService.calSelectedDate);
    this.end = new Date(this.scheduleService.calSelectedEndDate);
    const month = moment(new Date(this.scheduleService.calSelectedDate)).format('MMMM, YYYY');
    if (this.router.url === '/scheduling-monthly') {
      this.start = moment(new Date(this.scheduleService.calSelectedDate)).startOf('month').toDate();
      this.end = moment(new Date(this.scheduleService.calSelectedDate)).endOf('month').toDate();
    }
   }

  ngOnInit() {
  }

  confirm() {
    this.loading = true;
    this.scheduleService.autoAssign(this.start, this.end).then(result => {
      this.loading = false;
      this.dialogRef.close(true);
    });
  }

}
