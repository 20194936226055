import { Observable, Subscription } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatAutocompleteTrigger } from '@angular/material';
import { UserService } from '../../Services/Data/user.service';
import { ScheduleService } from '../../Services/Data/schedule.service';
import * as moment from 'moment';
import { ShiftBlock } from '../../models/shiftBlock';
import { FindShiftReplacementDialogComponent } from '../find-shift-replacement-dialog/find-shift-replacement-dialog.component';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { MatSnackBar } from '@angular/material';
import { map, startWith, debounceTime } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { EnvironmentService } from '../../Services/environment.service';
import { EnvironmentVar } from '../../models/environmentvar';
interface optionGroup {
  optionName: string;
  options: any[];
}
@Component({
  selector: 'app-shift-select-dialog',
  templateUrl: './shift-select-dialog.component.html',
  styleUrls: ['./shift-select-dialog.component.scss']
})
export class ShiftSelectDialogComponent implements OnInit {
  shiftBlock: ShiftBlock;
  shift: any;
  user: any;
  reservationType: string;
  shiftReservation: any;
  allHidden: boolean;
  phoneHidden: boolean;
  smsHidden: boolean;
  isSupervisor: boolean;
  assigneeId: number;
  dayMoment: any;
  loading: boolean;
  error: any;
  header: string;
  acceptButtonText: string;
  confirmDialogRef: MatDialogRef<ConfirmDialogComponent>;
  replacementDialogRef: MatDialogRef<FindShiftReplacementDialogComponent>;
  untilDate: Date;

  userControl = new FormControl();
  assigneeGroup = null;

  filteredOptions: Observable<optionGroup[]>;


  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<ShiftSelectDialogComponent>,
    private userService: UserService,
    public scheduleService: ScheduleService,
    public replacementDialog: MatDialog,
    public confirmDialog: MatDialog,
    public envService: EnvironmentService
  ) {
    this.isSupervisor = false;
    this.shiftBlock = data.shiftBlock;
    this.shift = data.shiftBlock.shift;
    this.dayMoment = data.day;
    this.user = this.data.user;
    this.isSupervisor = this.user.properties.usertype == 'Supervisor';
    this.shiftReservation = this.data.shiftBlock.reservation;
    this.acceptButtonText = 'Accept';
  }

    ngOnInit() {
        this.loading = false;
        // initialize choices
        if (this.shiftBlock.hasPhone && this.shiftBlock.hasSMS) {
            this.reservationType = 'All (Phone / SMS)';
            this.allHidden = false;
            this.phoneHidden = false;
            this.smsHidden = false;
        } else if (this.shiftBlock.hasPhone) {
            this.reservationType = 'Phone Only';
            this.allHidden = true;
            this.phoneHidden = false;
            this.smsHidden = true;
        } else if (this.shiftBlock.hasSMS) {
            this.reservationType = 'SMS Only';
            this.smsHidden = false;
            this.allHidden = true;
            this.phoneHidden = true;
        }

        // tslint:disable-next-line:max-line-length
        if (!this.isSupervisor && this.shiftReservation && !this.shiftBlock.canModify && (!this.shiftBlock.isUsersShift || this.shiftBlock.isLockedDown)) {
            this.header = 'View Shift';
            this.allHidden = true;
            this.smsHidden = true;
            this.phoneHidden = true;
        }

        // initialize default value
        if (this.shiftReservation) {
            if (this.shiftReservation.properties.shiftsmsreservation
                && this.shiftReservation.properties.shiftphonereservation) {
                this.reservationType = 'All (Phone / SMS)';
            } else if (this.shiftReservation.properties.shiftphonereservation) {
                this.reservationType = 'Phone Only';
            } else if (this.shiftReservation.properties.shiftsmsreservation) {
                this.reservationType = 'SMS Only';
            }


            if (this.shiftReservation.linkedProperties.shiftphonereservation) {
                this.assigneeId = this.shiftReservation.linkedProperties.shiftphonereservation.id;
            } else if (this.shiftReservation.linkedProperties.shiftsmsreservation) {
                this.assigneeId = this.shiftReservation.linkedProperties.shiftsmsreservation.id;
            }


            this.assigneeGroup = this.scheduleService.allAssigneeGroups.find(e => e.id === this.assigneeId);


            if (this.assigneeId) {
                this.SetUser(this.assigneeId);
            }

            if (this.shiftBlock.isUsersShift && !this.shiftBlock.isLockedDown || this.isSupervisor || this.shiftBlock.canModify) {
                this.header = 'Edit Shift';
                this.acceptButtonText = 'Save';
            }

        } else {
            if (!this.shiftBlock.isLockedDown) {
                this.header = 'Accept Shift';
            }
            if (this.isSupervisor) {
                this.header = 'Assign Shift';
            }
        }

        this.filteredOptions = this.userControl.valueChanges.pipe(
            startWith<string | any>(''),
            map(value => typeof value === 'string' ? value : (value && value.name ? value.name : '')),
            map(name => name ? this._filter(name) :
            [
              ...this.scheduleService.allUsers.length ?
              [{
                optionName: 'Individuals',
                options: this.scheduleService.allUsers
              }] : [],
              ...this.scheduleService.allAssigneeGroups.length ?
              [{
                optionName: 'Groups',
                options: this.scheduleService.allAssigneeGroups
              }] : []
            ])
        );
    }

    private _filter(value: any): optionGroup[] {
        try {

            const filterValue = value ? value.toLowerCase() : '';
            const individualCheck = this.scheduleService.allUsers.filter(option => option.name.toLowerCase().includes(filterValue));
            const groupsCheck = this.scheduleService.allAssigneeGroups.filter(option => option.name.toLowerCase().includes(filterValue));

            const combined = [...individualCheck];
            combined.push(...groupsCheck);


            if (individualCheck.length === 0 && groupsCheck.length === 0) {
              this.assigneeId = undefined;
              this.assigneeGroup = null;
            } else if (individualCheck.length === 1 && groupsCheck.length === 0) {
              this.assigneeId = individualCheck[0].id;
              this.assigneeGroup = null;
            } else if (individualCheck.length === 0 && groupsCheck.length === 1) {
              this.assigneeId = groupsCheck[0].id;
              this.assigneeGroup = groupsCheck[0];
            } else {
              this.assigneeId = this.userControl.value.id;
              this.assigneeGroup = groupsCheck.find(e => e.id === this.assigneeId);
            }
            console.log('Assignee group', this.assigneeGroup);

           /* switch (combined.length) {
              case 0:
                this.assigneeId = undefined;
                break;
              case 1:
                this.assigneeId = combined[0].id;
                break;
              default:
                // KG: this seems to be the only place we assign the assigneeId, which didn't work if two users have similar name
                // eg. selecting 'John Lee' would return both 'John Lee' and 'John Lee-Anderson'
                if (this.userControl.value && this.userControl.value.id)
                  this.assigneeId = this.userControl.value.id;
            }*/

          const retval = [];
          if (individualCheck.length > 0) retval.push({optionName: 'Individuals', options: individualCheck});
          if (groupsCheck.length > 0) retval.push({optionName: 'Groups', options: groupsCheck});

            return retval;

        } catch (err) {
            this.error = {
                message: err
            };
        }
    }

  displayFn(user?: any): string | undefined {
    return user ? user.name : undefined;
  }

  accept() {
    // create
    if (!this.shiftReservation) {
      if (this.isSupervisor || this.shift.canModify) {
        this.create(this.assigneeId);
      } else {
        this.create(this.user.id);
      }
    // update
    } else {
      if (this.isSupervisor || this.shift.canModify) {
        this.openConfirmDialog(this.shiftBlock, 'Are you sure you want to update this reservation?');
        this.confirmDialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.update(this.assigneeId);
          }
        });
      } else {
        this.update(this.user.id);
      }
    }
  }

  createLinkedProperties(reserverId: number) {
    return this.scheduleService.createLinkedProperties(reserverId, this.shiftBlock, this.reservationType);
  }

  create(reserverId: number) {
    this.loading = true;
    // do the check to make sure ShiftBlock is allowed to be reserved
    if (this.scheduleService.canShiftBlockBeReserved(this.shiftBlock)) {
      if (this.scheduleService.checkSimultaneous(this.shiftBlock, reserverId, this.reservationType)) {
        this.error = {
          message: 'Unable to reserve shift as selected user is already booked during time frame.'
        };
      } else {
        this.scheduleService.createReservation(
        this.scheduleService.whichShiftBlock(this.dayMoment, this.shiftBlock, this.reservationType),
        this.dayMoment.toDate(), this.createLinkedProperties(reserverId)).then(success => {
          if (this.scheduleService.isAvailabilityMode(this.shiftBlock)) {
            this.notifyAvailabilityShiftAssigned(reserverId).subscribe(result => {
              this.updateHandled(333);
            });
          } else {
            this.dialogRef.close();
          }
        }).catch(reason => {
          // show user that it didn't work
          this.error = {
            message: reason
          };
        });
      }
    } else {
      // show user that it didn't work
      this.error = {
        message: 'Shift already reserved, please try another shift.'
      };
    }
  }

  update(reserverId: number) {
    this.loading = true;
    if (this.scheduleService.checkSimultaneous(this.shiftBlock, reserverId, this.reservationType)) {
      this.error = {
        message: 'Unable to reserve shift as selected user is already booked during time frame.'
      };
    } else {
      this.scheduleService.updateReservation(this.shiftReservation.id, this.createLinkedProperties(reserverId)).then(success => {
        this.dialogRef.close();
      }).catch(reason => {
        this.error = {
          message: reason
        };
      });
    }
  }

  delete() {
    this.loading = true;
    return this.scheduleService.deleteReservation(this.shiftReservation).catch(reason => {
      this.error = {
        message: reason
      };
    });
  }

  openConfirmDialog(shiftBlock: ShiftBlock, message: string) {
    if (this.scheduleService.ifSendReplacementEmail(shiftBlock)) {
      message = 'The reserver has sent an email to find a replacement. ' + message;
    }
    this.confirmDialogRef = this.confirmDialog.open(ConfirmDialogComponent, {
        minWidth: '25vw',
        data: {
          message
        }
    });
  }

  deleteConfirm() {
    if (!this.isSupervisor && this.shiftBlock.isLockedDownSupervisorOnly) {
      this.loading = true;
      this.error = {
        message: 'The reservation has been locked for supervisor delete only.'
      };
    } else {
        this.openConfirmDialog(this.shiftBlock, 'Are you sure you want to delete this reservation?');
        this.confirmDialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.delete().then(success => {
              this.dialogRef.close();
            });
          }
        });
    }

  }

  openReplacementDialog(shiftBlock: ShiftBlock, day: any) {
    this.replacementDialogRef = this.replacementDialog.open(FindShiftReplacementDialogComponent, {
        minWidth: '25vw',
        data: {
          shiftBlock: shiftBlock,
          user: this.user,
          day
        }
    });

    this.replacementDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.dialogRef.close();
      }
    });

  }

  futureAcceptConfirm() {
    const message = 'Are you sure you want to book this shift until ' + moment(this.untilDate).format('M/D/YYYY') + '?';
    this.confirmDialogRef = this.confirmDialog.open(ConfirmDialogComponent, {
      minWidth: '25vw',
      data: {
        message
      }
    });
    this.confirmDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.futureAccept();
      }
    });
  }

  getMaxUntilDate(start) {
    return moment(start).add(60, 'days').toDate();
  }

  SetUser(assigneeId: number) {
      let value;
      if (!this.assigneeGroup) {
       value = this.scheduleService.allUsers.find(option => option.id === assigneeId);
      } else {
        value = this.assigneeGroup;
      }
      if (value) {
          this.userControl.setValue(value);
      }

  }




  futureAccept() {
    this.scheduleService.getFutureShiftBlocks(this.shiftBlock, this.untilDate).then(result => {
      const futureShiftBlocks = result;
      console.log('future shiftBlocks: ', futureShiftBlocks);
      this.loading = true;
      let reserverId = this.user.id;
      if (this.isSupervisor) {
        reserverId = this.assigneeId;
      }

      const countSuccessful = 0;

      futureShiftBlocks.forEach(sb => {
        if (!sb.reservation && !this.scheduleService.checkSimultaneous(sb, reserverId, this.reservationType)) {
          this.scheduleService.createReservation(
          sb,
          sb.date, this.createLinkedProperties(reserverId)).then(success => {
            this.snackBar.open( sb.date + ' shift reservation', 'Created', {
                duration: 5000,
              });
            this.dialogRef.close();
          }).catch(reason => {
            // show user that it didn't work
            this.error = {
              message: reason
            };
          });
        } else {
          this.dialogRef.close();
        }
      });
    });
  }

  available() {
    this.loading = true;
    this.scheduleService.createAvailabilityListRow(
    this.scheduleService.whichShiftBlock(this.dayMoment, this.shiftBlock, this.reservationType),
    this.dayMoment.toDate()).then(success => {
      this.dialogRef.close();
    }).catch(reason => {
      //show user that it didn't work
      this.error = {
        message: reason
      };
    });
  }

  unAvailable() {
    const aListRow = this.ifAvailable();
    if (aListRow) {
      this.loading = true;
      this.scheduleService.deleteAvailabilityListRow(aListRow).subscribe(result => {
        this.dialogRef.close();
      });
    }
  }

  ifAvailable() {
    if (this.shiftBlock.availabilityList && this.shiftBlock.availabilityList.rows) {
      return this.shiftBlock.availabilityList.rows.find(r => r.linkedProperties.user.id === this.user.id);
    } else {
      return null;
    }
  }

  updateHandled(ishandled_cfcid) {
    this.loading = true;
    if (this.shiftBlock.availabilityList) {
      // update availability list
      this.scheduleService.updateAvailabilityList(
        this.shiftBlock.availabilityList.id,
        { ishandled_cfcid },
        { }
      ).subscribe(result => {
        this.dialogRef.close();
      });
    } else {
      this.scheduleService.createAvailabilityList(
        this.scheduleService.whichShiftBlock(this.dayMoment, this.shiftBlock, this.reservationType),
        this.dayMoment.toDate(),
        ishandled_cfcid
      ).subscribe(result => {
        this.dialogRef.close();
      });
    }
  }

  notifyAvailabilityShiftAssigned(reserverId: number) {
    return this.scheduleService.notifyAvailabilityShiftAssigned(reserverId, this.shiftBlock);
  }

  notHandled() {
    if (this.shiftReservation) {
      const message = 'Do you also want to delete the reservation?';
      this.confirmDialogRef = this.confirmDialog.open(ConfirmDialogComponent, {
        minWidth: '25vw',
        data: {
          message
        }
      });
      this.confirmDialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.delete().then(success => {
            this.updateHandled(334);
          });
        }
      });
    } else {
      this.updateHandled(334);
    }
  }
}
