import { EntityDataService } from 'src/app/Services/Data/entity-data.service';



import { Component, OnInit, Inject } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';


@Component({
    selector: 'app-user-select-dialog',
    templateUrl: './user-select-dialog.component.html',
    styleUrls: ['./user-select-dialog.component.scss']
  })
  export class UserSelectDialogComponent implements OnInit {
 users = [];
 selectedUser = null;
    constructor(public dialogRef: MatDialogRef<UserSelectDialogComponent>,
        private entityDataService: EntityDataService,
        @Inject(MAT_DIALOG_DATA) public data: any) {

        }

    ngOnInit() {
        this.entityDataService.getAll(583).subscribe(
            results => {
                this.users = results;
            });
    }

}

