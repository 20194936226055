import { Injectable, NgZone } from '@angular/core';
import AudioHelper from '@twilio/voice-sdk/es5/twilio/audiohelper';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, first, map, skipUntil } from 'rxjs/operators';
import { UserService } from 'src/app/Services/Data/user.service';

type HTMLAudio = HTMLAudioElement & { setSinkId?: (string) => Promise<undefined> };

export enum SoundContext {
  Notification,
  UISound,
  Alert,
  Voice,
  All,
}

export interface OpenAudioSettingsOptions {
  wasAutomatic?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AudioService {

  public readonly defaultAudio: Map<SoundContext, string> = new Map([
    [ SoundContext.UISound, 'assets/audio/OutgoingMessage.wav' ],
    [ SoundContext.Notification, 'assets/audio/IncomingMessage.wav' ],
    [ SoundContext.Alert, 'assets/audio/NewMessage.mp3' ],
    [ SoundContext.Voice, null ],
  ]);
  public readonly isOutputSelectionSupported: boolean;
  public readonly streamInterval = 1000 / 60;
  private bound;

  private fallbackDevice = 'communications';
  private defaultInput = 'communications';
  private defaultOutput = 'communications';
  private audio: Map<SoundContext, HTMLAudio>;
  private _currentStream: MediaStream;
  private _mediaStream: ReplaySubject<MediaStream>;
  private _audioHelper: BehaviorSubject<AudioHelper>;
  private _initialized: ReplaySubject<boolean>;
  private _inputReadySub: Subscription;
  private _inputSubs: Subscription[] = [];
  private _outputSubs: Subscription[] = [];

  private listeners = new Set();

  private input: string;
  private track: string;
  private _shouldBeStreaming = false;
  private _shouldMuteInput = new BehaviorSubject(true);
  private _inputStream: Subject<number>;

  private ctx: AudioContext;
  private ana: AnalyserNode;
  private streamSrc: MediaStreamAudioSourceNode;

  private _openSettings: Subject<OpenAudioSettingsOptions>;
  private _updateOutputDevice: Subject<{id: string, context: SoundContext}>;
  private _updateInputDevice: ReplaySubject<string>;
  private _updateDeviceLists: ReplaySubject<{inputs: MediaDeviceInfo[], outputs: MediaDeviceInfo[]}>;

  public availableDevices():
    Observable<{inputs: MediaDeviceInfo[], outputs: MediaDeviceInfo[]}>  { return this._updateDeviceLists; }

  public onSettingsOpen(): Observable<any> {
    return this._openSettings.asObservable();
  }

  public openSettings(options?: OpenAudioSettingsOptions) {
    options = {
      wasAutomatic: false,
      ...(options || {})
    };

    this._openSettings.next(options);
  }

  public outputDevice(): Observable<{id: string, context: SoundContext}> { return this._updateOutputDevice; }
  public inputDevice(): Observable<string>                               { return this._updateInputDevice; }
  public isMuted(): Observable<boolean>                                  { return this._shouldMuteInput; }
  public audioHelper(): Observable<AudioHelper>                          { return this._audioHelper.pipe(filter( _ => !!_)); }
  public inputReady(): Observable<boolean>                               { return this._mediaStream.pipe( map(_ => !!_) ); }
  public inputStream(): Observable<number>                               { return this._inputStream; }
  public initialized(): Promise<true>                                    { return this._initialized.toPromise() as Promise<true>; }

  private mediaStream(): Observable<MediaStream>   { return this._mediaStream.pipe(skipUntil(this._initialized), filter( _ => !!_)); }
  private firstMediaStream(): Promise<MediaStream> { return this.mediaStream().pipe(first()).toPromise(); }
  private  get currentStream(): MediaStream {
    return (this._audioHelper.value && this._audioHelper.value.inputStream) || this._currentStream;
  }
  private get twilioAudioHelperMode() {
    return !!this._audioHelper.value;
  }

  // TODO: make type AudioHelper MediaStream the same as the internal one here

  constructor( private zone: NgZone, user: UserService ) {
    user.currentUser$.pipe(filter(u => !!u)).subscribe(u => {
      this.log('Defaults from user:', u.properties.defaultinputdevice, u.properties.defaultoutputdevice);
      this.defaultInput = u.properties.defaultinputdevice || this.defaultInput;
      this.defaultOutput = u.properties.defaultoutputdevice || this.defaultOutput;
    });

    this._audioHelper = new BehaviorSubject(null);
    this._mediaStream = new ReplaySubject(1);
    this._initialized = new ReplaySubject(1);
    this._inputStream = new Subject();
    // this._inputStream.pipe(throttleTime(333)).subscribe( v => this.log('audio lvl', v));
    this._updateOutputDevice = new Subject();
    this._openSettings = new Subject();
    this._updateInputDevice = new ReplaySubject(1);
    this._updateDeviceLists = new ReplaySubject(1);

    const isEnumerationSupported: boolean = !!(navigator.mediaDevices && navigator.mediaDevices.enumerateDevices);
    const isSetSinkSupported: boolean = typeof (HTMLAudioElement.prototype as any).setSinkId === 'function';
    this.isOutputSelectionSupported = isEnumerationSupported && isSetSinkSupported;
    console.log('Platform Support', {
                  'Output Selection': this.isOutputSelectionSupported,
                  'Enumeration': isEnumerationSupported,
                  'Change Output': isSetSinkSupported
                });

    this.initializeOnFirstClick();

    // combineLatest([this.audioHelper(), this.inputDevice()])
    //   .subscribe(([a, input]) => a.setInputDevice(input).then( _ => {
    //     this.log('Updating MediaStream from AudioHelper', a, input, a.inputStream);
    //     if (a.inputStream) this._mediaStream.next(a.inputStream);
    //   }));

    // combineLatest([this.mediaStream(), this.inputDevice()])
    //   .subscribe(([ms, input]) => ms.getTracks().forEach( t => t.enabled = (t.id === this.track) && !this._shouldMuteInput.value ));
  }

  private initializeOnFirstClick() {
    const initOnce = () => {
      if (!this.bound)
        return;
      this.initialize();
      document.removeEventListener('click', this.bound);
      this.bound = undefined;
    };

    this.bound = initOnce.bind(this);
    document.addEventListener('click', this.bound);
  }

  private initialize() {
    this.ctx = new AudioContext();
    this.ana = this.ctx.createAnalyser();
    this.ana.fftSize = 32;
    this.ana.smoothingTimeConstant = 0.6;

    this.audio = new Map();
    for (const key of this.defaultAudio.keys())
      this.audio.set(key, new Audio());

    this.mediaStream().subscribe( ms => {
      this._currentStream = ms;

      if (this.track) // set track
        ms.getTracks().forEach( t => {
          t.enabled = (t.id === this.track);
        });

      this.streamSrc = this.ctx.createMediaStreamSource(ms);
      this.streamSrc.connect(this.ana);
      // this.ana.connect(this.ctx.destination);

      if (this._shouldBeStreaming)
        this.ctx.resume();
      else
        this.ctx.suspend();
    });

    combineLatest([
      this._updateInputDevice,
      this.audioHelper(),
      this._shouldMuteInput,
    ]).pipe(distinctUntilChanged())
      .subscribe(([deviceId, audioHelper, shouldMute]) => {
        if (shouldMute)
          audioHelper.unsetInputDevice().catch(() => {});
        else
          audioHelper.setInputDevice(deviceId).then(_ => this.input = audioHelper.inputDevice.deviceId);
      });

    this._initialized.next(true);
    this._initialized.complete();
    this.log('Initialized.');
  }

  private _playAudio(src: string, audio: HTMLAudio) {
    audio.src = src;
    audio.load();
    audio.addEventListener('canplaythrough', () => audio.play(), {once: true});
  }

  playAudio(context: SoundContext, url: string = null) {
    const src = url || this.defaultAudio.get(context);
    if (!src)
      return this.log('Cannot play sound - no track found');

    this.log('Playing', context.toString(), src);
    if (context === SoundContext.All)
      this.audio.forEach( audio => this._playAudio(src, audio));
    else
      this._playAudio(src, this.audio.get(context));
  }

  setOutputDevice(deviceOrId: 'id-multimedia' | 'id-communications' | string | MediaDeviceInfo, context: SoundContext = SoundContext.All) {
    this.initialized().then( _ => {
      if (this.isOutputSelectionSupported) {
        const id = typeof deviceOrId === 'string' ? deviceOrId : deviceOrId.deviceId;
        const promises = [];
        if (context === SoundContext.All)
          this.audio.forEach( audio => promises.push(audio.setSinkId(id)) );
        else
          promises.push(this.audio.get(context).setSinkId(id));

        const ah = ( (audioHelper: AudioHelper) => {
          const device = audioHelper.availableOutputDevices.get(id);
          Array.from(audioHelper.ringtoneDevices.get()).forEach( d => {
            if (d.deviceId !== device.deviceId ) audioHelper.ringtoneDevices.delete(d);
          });
          Array.from(audioHelper.speakerDevices.get()).forEach( d => {
            if (d.deviceId !== device.deviceId ) audioHelper.speakerDevices.delete(d);
          });

          if (context === SoundContext.Alert || context === SoundContext.Notification ||
              context === SoundContext.UISound || context === SoundContext.All)
            promises.push(audioHelper.ringtoneDevices.set(device.deviceId));

          if (context === SoundContext.Voice || context === SoundContext.All)
            promises.push(audioHelper.speakerDevices.set(device.deviceId));
        });

        if (this.twilioAudioHelperMode) ah(this._audioHelper.value);

        Promise.all(promises).then( () => this._updateOutputDevice.next({id, context}) );
      } else {
        this.warn('Output Selection not supported');
      }
    });
  }

  setInputDevice(deviceId: string) {
    if (this.input !== deviceId) {
      this.input = deviceId;
      this._updateInputDevice.next(this.input);
    }
  }

  getInputPermission(): Promise<boolean> {
    if (!this.currentStream)
      return navigator.mediaDevices
        .getUserMedia({video: false, audio: true})
        .then( ms => {
          this._mediaStream.next(ms);
          return true;
        }).catch( err => {
          this.warn('Error retrieving Audio Devices: ' + err);
          return false;
        });
      else
        return new Promise( resolve => resolve(true) );
  }

  /** @deprecated Not Implemented */
  globalOutputMute() {
    throw new Error('Not Implemented');
  }

  /** @deprecated Not Implemented */
  muteOutput() {
    throw new Error('Not Implemented');
  }

  private _ensureMute() {
    this.log('ensure Mute!', this.inputIsMuted, this._shouldMuteInput.value);
    if (this._shouldMuteInput.value !== this.inputIsMuted)
      this._muteInput(this._shouldMuteInput.value);
  }

  toggleInputMute() {
    this.log(this.inputIsMuted ? 'Un-Muting!' : 'Muting!');
    this.muteInput(!this.inputIsMuted);
  }

  addInputListener(caller: any) {
    this.listeners.add(caller);
  }
  removeInputListener(caller) {
    this.listeners.delete(caller);
    if (this.listeners.size === 0)
      this._muteInput(true);
  }

  muteInput(shouldMute = true) {
    this._shouldMuteInput.next(shouldMute);
    this._muteInput(shouldMute);
  }

  private _muteInput(shouldMute: boolean) {
    this.initialized().then(_ => {
      this.log(`internal mute [${this.twilioAudioHelperMode ? 'AudioHelper' : 'Manual'}]`, shouldMute);
      if (this.twilioAudioHelperMode) {
        shouldMute ?
          this._audioHelper.value.unsetInputDevice()
            .catch(() => {/* if a call is in progress, unsetInputDevice will fail */}) :
          this._audioHelper.value.setInputDevice(this.input)
            .then(() => this._updateInputDevice.next(this._audioHelper.value.inputDevice.deviceId));
      } else {
        if (!shouldMute && this._shouldBeStreaming)
          this._startInputStream();
        else
          this._stopInputStream();
        if(this.currentStream)
        this.currentStream.getAudioTracks().forEach(t => t.enabled = t.enabled && !shouldMute);
      }
    });
  }


  get inputIsMuted() {
    if (this.twilioAudioHelperMode)
      return !this._audioHelper.value.inputDevice;

    return !this.currentStream || this.currentStream.getAudioTracks().every(t => t.muted || !t.enabled );
  }

  setTwilioAudioHelper(audio: AudioHelper) {
    this.initialized().then( _ => {
      audio.on('deviceChange', () => {
        // default to communications device
        if (!audio.inputDevice) {
          const device = audio.availableInputDevices.get(this.defaultInput) || audio.availableInputDevices.get(this.fallbackDevice) ;
          if (device) {
            this.log('Setting default input device', device.deviceId);
            this.setInputDevice(device.deviceId);
          }
        } else if (this.input !== audio.inputDevice.deviceId) {
          this.log('missing input device, falling back to twilio default', this.input, audio.inputDevice);
          this.setInputDevice(audio.inputDevice.deviceId);
        }

        if (audio.speakerDevices.get().size === 0) {
          const device = audio.availableOutputDevices.get(this.defaultOutput);
          if (device) {
            this.log('Setting default output device', device.deviceId);
            this.setOutputDevice(device);
          }
        }

        this._updateDeviceLists.next({
          inputs: Array.from(audio.availableInputDevices.values()),
          outputs: Array.from(audio.availableOutputDevices.values()),
        });

      });

      const setConstraints = () => audio.setAudioConstraints({
        autoGainControl: { ideal: true },
        noiseSuppression: { ideal: true },
        echoCancellation: { ideal: true },
      } as any);

      if (this._inputReadySub) this._inputReadySub.unsubscribe();
      this._inputReadySub = this._mediaStream.subscribe(setConstraints);

      this._audioHelper.next(audio);
    });
  }

  public startInputStream() {
    this._shouldBeStreaming = true;
    this._startInputStream();
  }

  public stopInputStream() {
    this._shouldBeStreaming = false;
    this._stopInputStream();
    if (this.listeners.size === 0)
      this._muteInput(true);
  }

  // tslint:disable-next-line: member-ordering
  private inpInt: NodeJS.Timer;
  private _startInputStream() {
    if (this.twilioAudioHelperMode) {
      this.log('Starting AudioHelper Input Stream');
      const a = this._audioHelper.value;
      a.on( 'inputVolume', volume => this._inputStream.next(volume));
      a._maybeStartPollingVolume();
    } else {
      this.log('Starting Manual Input Stream');
      if(this.ctx)
      this.ctx.resume();

      this.inpInt = setInterval( _ => {
        if(!this.ana) return;
        const data = new Uint8Array(this.ana.frequencyBinCount);
        this.ana.getByteFrequencyData(data);
        const mx = data.reduce( (s, n) => s + n, 0);
        const v = mx / data.length / 255.0 * 0.9;
        this._inputStream.next( v );
      }, this.streamInterval);
    }
  }

  public _stopInputStream() {
    this.log('Stopping Input Stream');

    if (this.twilioAudioHelperMode) {
      const a = this._audioHelper.value;
      a.removeAllListeners('inputVolume');
      a._maybeStopPollingVolume();
    }
    clearInterval(this.inpInt);
    this.ctx.suspend();
  }

  log(...args) { console.log('Keenan - Audio Service', ...args); }
  warn(...args) { console.warn('Keenan - Audio Service', ...args); }
}
