import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Inject, Output, ViewChild } from '@angular/core';
import { MatDialogRef, MatSelect, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';
import { Subscription, merge } from 'rxjs';
import { filter, first, switchMap } from 'rxjs/operators';
import { AudioService, OpenAudioSettingsOptions } from 'src/app/Services/audio.service';
import { UserService } from 'src/app/Services/Data/user.service';
import { EnvironmentService } from 'src/app/Services/environment.service';
import { IAudioControls } from 'src/app/voice/audio-controls/audio-controls.interface';
import { VoiceService } from '../voice-service/voice.service';

@Component({
  selector: 'app-audio-options-dialog',
  templateUrl: './audio-options-dialog.component.html',
  styleUrls: ['./audio-options-dialog.component.scss']
})
export class AudioOptionsDialogComponent implements AfterViewInit {
  showTagBar = false;
  clickedGetStarted: boolean = false;

  @Output() resized = new EventEmitter<undefined>();
  @ViewChild('tagBar') tagBar: ElementRef<HTMLDivElement>;

  subs: Subscription[] = [];
  set nextSub(s: Subscription) { this.subs.push(s); }

  @ViewChild('inputSelect')
  inputSelect: MatSelect;

  @ViewChild('outputSelect')
  outputSelect: MatSelect;

  constructor(
    public user: UserService,
    protected voice: VoiceService,
    public audio: AudioService,
    public dialogRef: MatDialogRef<AudioOptionsDialogComponent>,
    private router: Router,
    protected envService: EnvironmentService,
    @Inject(MAT_DIALOG_DATA) public audioControls: IAudioControls & OpenAudioSettingsOptions
  ) {
      this.clickedGetStarted = audioControls.openedSettingsByClick;
  }

  ngAfterViewInit(): void {
    if (!this.inputSelect)
      return;

    this.nextSub = this.inputSelect.selectionChange.subscribe(s => {
      this.audio.setInputDevice((s.value as MediaDeviceInfo).deviceId);
    });

    this.nextSub = this.outputSelect.selectionChange.subscribe(s => {
      this.audio.setOutputDevice(s.value);
    });

    this.nextSub = merge(this.outputSelect.selectionChange, this.inputSelect.selectionChange)
      .pipe(
        filter(() => !!(this.audioControls.selectedInput && this.audioControls.selectedOutput)),
        switchMap(change => this.user.currentUser$.pipe(filter(u => !!u), first())))
      .subscribe(u => {
        // if either devices are different, save it
        if (u.properties.defaultinputdevice !== this.audioControls.selectedInput.deviceId ||
            u.properties.defaultoutputdevice !== this.audioControls.selectedOutput.deviceId)
          this.user.setDefaultAudioDevices({
            id: u.id, input: this.audioControls.selectedInput.deviceId, output: this.audioControls.selectedOutput.deviceId
          });
      });
  }

  // tslint:disable-next-line: member-ordering
  heightMap = new Map<HTMLElement, number>();
  sizeToChildren(element: HTMLElement, padding = 5) {
    if (!this.heightMap.has(element))
      this.heightMap.set(element, [].reduce.call(element.childNodes, (t: number, child: HTMLElement) => t + child.scrollHeight, padding));

    return `${this.heightMap.get(element)}px`;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.heightMap.clear();
  }

  // tslint:disable-next-line: member-ordering
  tryCloseTagBarTimeout: NodeJS.Timer;
  tryCloseTagBar() {
    if (!this.tryCloseTagBarTimeout) {
      this.tryCloseTagBarTimeout = setTimeout(_ => {
          this.toggleTagBar(false);
      }, 500);
    }
  }

  cancelCloseTagBar() {
    if (this.tryCloseTagBarTimeout) {
      clearTimeout(this.tryCloseTagBarTimeout);
      this.tryCloseTagBarTimeout = undefined;
    }
  }

  toggleTagBar(forceShow?: boolean) {
    this.cancelCloseTagBar();
    this.showTagBar = typeof forceShow !== 'undefined' ? forceShow : !this.showTagBar;
    this.tagBar.nativeElement.addEventListener('transitionend', (e: TransitionEvent) => {
        if (!e || /height/.test(e.propertyName))
          this.resized.emit();
      },
      { once: true }
    );
  }

  close() {
    this.dialogRef.close({});
  }

  log(...args) {
    this.voice.log('Keenan - Audio Options Dialog', ...args);
  }

  getStarted() {
    this.clickedGetStarted = true;
  }

  redirectTotroubleShooting() {
    this.router.navigateByUrl("/troubleshoot-audio")
    this.close();
  }
}
