import { GoogleTranslationService } from './Services/Data/google-translation.service';
import { UserSelectDialogComponent } from './dialogs/user-select-dialog/user-select-dialog.component';
import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'; // <-- NgModel lives here
import { HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpModule } from '@angular/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import { LayoutModule } from '@angular/cdk/layout';

// Material Design Imports
import { MatNativeDateModule, MatProgressBarModule} from '@angular/material';

import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';

import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatListModule } from '@angular/material/list';
import { MatBadgeModule } from '@angular/material/badge';
import { MatChipsModule } from '@angular/material/chips';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';


import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDatepickerModule } from '@angular/material/datepicker';

// in memory to simulate HTTP requests.
// import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
// import { InMemoryDataService }  from './in-memory-data.service';

import { AppComponent } from './app.component';
// import { TicketComponent } from './ticket/ticket.component';
// import { ChatComponent } from './chat/chat.component';
// import { ResourceGuideComponent } from './resource-guide/resource-guide.component';
// import { ConversationsComponent } from './conversations/conversations.component';
import { MessagesComponent } from './messages/messages.component';
// import { DashboardComponent } from './dashboard/dashboard.component';
import { AppRoutingModule } from './app-routing.module';
// import { ConversationReportComponent } from './conversation-report/conversation-report.component';
// import { ChatMessageComponent } from './chat-message/chat-message.component';
import { TypingIndicatorComponent } from './typing-indicator/typing-indicator.component';
// import { ConversationComponent } from './conversation/conversation.component';

// import { MessageService } from './message.service';
// import { ResourcePanelComponent } from './resource-panel/resource-panel.component';

import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';


// Services
import { HubService } from 'ngx-signalr-hubservice';
// import { ChatService } from "./chat.service";
import { EntityService } from './Services/Hubs/entity.service';
import { EntityTypeService } from './Services/Hubs/entity-type.service';
import { MessageService } from './Services/Data/message.service';
import { ConversationsService } from './Services/Data/conversations.service';
// import { CookieService } from 'angular2-cookie/services/cookies.service';
import { CookieModule } from 'ngx-cookie';
import { ResourceService } from './Services/Data/resource.service';
import { UserService } from './Services/Data/user.service';
import { PushNotificationsService } from './Services/Notifcations/push-notifications.service';
import { HttpServiceInterceptor } from './Services/Hubs/httpInterceptor.service';
import { InternalCookieService } from './Services/cookie.service';


// import Pipes
// tslint:disable-next-line:max-line-length
import {
  // ConversationsFilterPipe,
  ConversationsConnectionTimePipe,
  // ConversationsByUserPipe,
  ResourceFilterPipe} from './Pipes/conversations-filter.pipe';
// import { ResourceGuideSearchComponent } from './resource-guide-search/resource-guide-search.component';
import { CallerNoteDialogComponent } from './dialogs/caller-note-dialog/caller-note-dialog.component';
import { EndCallComponent } from './dialogs/end-call/end-call.component';
// import { AasasTicketRadioComponent } from './ticket/questions/aasas-ticket-radio/aasas-ticket-radio.component';
// import { AasasTicketCheckboxComponent } from './ticket/questions/aasas-ticket-checkbox/aasas-ticket-checkbox.component';
// import { AasasTicketSectionComponent } from './ticket/questions/aasas-ticket-section/aasas-ticket-section.component';
// import { SchedulingComponent } from './schedule/scheduling/scheduling.component';
//import { CalendarComponent } from './calendar/calendar.component';
import { ShiftSelectDialogComponent } from './schedule/shift-select-dialog/shift-select-dialog.component';
// import { fromEventPattern } from 'rxjs';
// import { ResourceLocationComponent } from './resource-location/resource-location.component';
// import { SchedulingMasterComponent } from './schedule/scheduling-master/scheduling-master.component';
// import { ScheduleDashPanelComponent } from './schedule/schedule-dash-panel/schedule-dash-panel.component';
import { UserProfileMenuHeaderComponent } from './user-profile-menu-header/user-profile-menu-header.component';
import { UserProfileAvatarComponent } from './user-profile-avatar/user-profile-avatar.component';
// import { MasterShiftComponent } from './schedule/master-shift/master-shift.component';
// import { SchedulingMonthlyComponent } from './schedule/scheduling-monthly/scheduling-monthly.component';
import { NewConversationDialogComponent } from './dialogs/new-conversation-dialog/new-conversation-dialog.component';
import { RandomConversationDialogComponent } from './dialogs/random-conversation-dialog/random-conversation-dialog.component';


// import angular calendar

import { BlockUIModule } from 'ng-block-ui';

import { FindShiftReplacementDialogComponent } from './schedule/find-shift-replacement-dialog/find-shift-replacement-dialog.component';
import { ShiftReplacementConfirmComponent } from './schedule/shift-replacement-confirm/shift-replacement-confirm.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
// import { SanitizeHtmlPipe } from './Pipes/sanitizehtml.pipe';
import { ExcelExportDialogComponent } from './schedule/excel-export-dialog/excel-export-dialog.component';
import { InactiveDialogComponent } from './dialogs/inactive-dialog/inactive-dialog.component';
// import { BrowserNotifierComponent } from './browser-notifier/browser-notifier.component';
// import { ChromeRequiredDialogComponent } from './dialogs/chrome-required-dialog/chrome-required-dialog.component';
// import { AasasTicketTextComponent } from './ticket/questions/aasas-ticket-text/aasas-ticket-text.component';
import { SettokenComponent } from './settoken/settoken.component';
import { ResourceDashboardComponent } from './resource-dashboard/resource-dashboard.component';
import { SignalrDisconnectDialogComponent } from './dialogs/signalr-disconnect-dialog/signalr-disconnect-dialog.component';
import { InterpreterComponent } from './dialogs/interpreter/interpreter.component';
// import { VolunteerComponent } from './reports/volunteer/volunteer.component';
// import { CallsComponent } from './reports/calls/calls.component';
// import { ReportQuestionsComponent } from './reports/calls/questions/report-questions/report-questions.component';
// import { ReportCallSectionsComponent } from './reports/calls/questions/report-call-sections/report-call-sections.component';
import { ConversationResultComponent } from './reports/calls/results/conversation-result/conversation-result.component';
// import { ChildCategoryComponent } from './resource-guide/child-category/child-category.component';
// import { ConversationFilterComponent } from './reports/calls/snippet/conversation-filter/conversation-filter.component';
// import { MobileConversationTabComponent } from './mobile-conversation-tab/mobile-conversation-tab.component';
// import { MobileTicketTabComponent } from './mobile-ticket-tab/mobile-ticket-tab.component';
// import { MobileResourceTabComponent } from './mobile-resource-tab/mobile-resource-tab.component';
// import { ConversationMenuComponent } from './conversation-menu/conversation-menu.component';
import { Platform, ModalController, AngularDelegate } from '@ionic/angular';
import { MobileLoginComponent } from './mobile-apps/mobile-login/mobile-login.component';
// import { KPIComponent } from './reports/kpi/kpi.component';
// import { ActiveTimeReportComponent } from './reports/active-time-report/active-time-report.component';
// import { KPIComponent } from './reports/kpi/kpi.component';
// import { BaseReportComponent } from './reports/base-report/base-report.component';
// import { KpiItemComponent } from './reports/kpi/kpi-item/kpi-item.component';
import { ShiftAvailabilityListComponent } from './schedule/shift-availability-list/shift-availability-list.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AutoAssignDialogComponent } from './schedule/auto-assign-dialog/auto-assign-dialog.component';
import { SignalRConnectionService } from './Services/signal-r-connection.service';
import { SignalrConnectionCheckerService } from './Services/Hubs/signalr-connection-checker.service';

import { Deploy } from "cordova-plugin-ionic/dist/ngx";
import { MobileUpdateProgressComponent } from './mobile-apps/mobile-update-progress/mobile-update-progress.component';
// import { SplashPageComponent } from './splash-page/splash-page.component';
import { MobileInAppNotificationService } from './Services/Notifcations/mobile-in-app-notification.service';
import { MobileLoginModuleModule } from './mobile-apps/mobile-login-module/mobile-login-module.module';
// import { MobileConversationModule } from './mobile-conversation/mobile-conversation.module';
// import { MobileDashboardComponent } from './mobile-dashboard/mobile-dashboard.component';

// uncomment these if anything fails to load at the beginning
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { MomentModule } from 'ngx-moment';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDividerModule, MatDivider } from '@angular/material/divider';
import { MatTabsModule } from '@angular/material/tabs';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ContenteditableModule } from 'ng-contenteditable';
import { ConnectionToHubService } from './Services/Hubs/connection-to-hub-check.service';
import { DiagnosticsComponent } from './diagnostics/diagnostics.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MobileAppComponent } from './mobile-apps/mobile-app/mobile-app.component';
import { EnvironmentService } from './Services/environment.service';
import { AuthService } from './Services/Hubs/auth.service';

import {
  AudioControlsComponent,
  VoiceNotificationComponent,
  ModalVoiceNotificationComponent,
  OutgoingModalVoiceNotificationComponent,
  AudioOptionsDialogComponent,
  AudioFeedbackComponent,
  TroubleshootAudioComponent,
  VibesComponent,
  VoiceService } from './voice';

// Localization
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import { lang } from '../i18n';
import { LinkClientDialogComponent } from './dialogs/link-client-dialog/link-client-dialog.component';
import { NewClientDialogComponent } from './dialogs/new-client-dialog/new-client-dialog.component';
import { ICMDialogComponent } from './dialogs/icm-client-dialog/icm-dialog.component';
import { DialOutDialogComponent } from './voice/dial-out-dialog/dial-out-dialog.component';
import { NoVoipDialogComponent } from './no-voip-dialog/no-voip-dialog.component';

registerLocaleData(localeFr, 'fr');

@NgModule({
  declarations: [
    AppComponent,
    MessagesComponent,
    TypingIndicatorComponent,
    ResourceFilterPipe,
    ConversationsConnectionTimePipe,
    // ConversationReportComponent,
    // lazy load has these guys separately in their own modules
    // TicketComponent,
    // ChatComponent,
    // ResourceGuideComponent,
    // ConversationsComponent,
    // DashboardComponent,
    // ChatMessageComponent,
    // ConversationComponent,
    // ConversationsByUserPipe,
    // ConversationsFilterPipe,
    // ResourcePanelComponent,
    // ResourceGuideSearchComponent,
    // AasasTicketRadioComponent,
    // AasasTicketCheckboxComponent,
    // AasasTicketSectionComponent,
    // ResourceLocationComponent,
    // SanitizeHtmlPipe,
    // AasasTicketTextComponent,
    // ResourceDashboardComponent,
    // ChildCategoryComponent,
    // MobileConversationTabComponent,
    // ConversationMenuComponent,
    // MobileDashboardComponent,
    // VolunteerComponent,
    // CallsComponent,
    // ReportCallSectionsComponent,
    // ReportQuestionsComponent,
    // ConversationFilterComponent,
    // SchedulingMasterComponent,
    // SchedulingComponent,
    // SchedulingMonthlyComponent,
    // ScheduleDashPanelComponent,
    // MasterShiftComponent,
    // MobileTicketTabComponent,
    // MobileResourceTabComponent,
    CallerNoteDialogComponent,
    EndCallComponent,
    // CalendarComponent,
    ShiftSelectDialogComponent,
    UserProfileMenuHeaderComponent,
    UserProfileAvatarComponent,
    NewConversationDialogComponent,
    RandomConversationDialogComponent,
    FindShiftReplacementDialogComponent,
    ShiftReplacementConfirmComponent,
    ConfirmDialogComponent,
    ExcelExportDialogComponent,
    InactiveDialogComponent,
    // BrowserNotifierComponent,
    // ChromeRequiredDialogComponent,
    SettokenComponent,
    // ResourceDashboardComponent,
    SignalrDisconnectDialogComponent,
    InterpreterComponent,
    UserSelectDialogComponent,
    ConversationResultComponent,
//    ChildCategoryComponent,
//    ConversationFilterComponent,
  //  ActiveTimeReportComponent,
 //   KPIComponent,
  //  BaseReportComponent,
  //  KpiItemComponent,
    ShiftAvailabilityListComponent,
    AutoAssignDialogComponent,
    // ActiveTimeReportComponent,
    // KPIComponent
    MobileLoginComponent,
    MobileUpdateProgressComponent,
    DiagnosticsComponent,
    MobileAppComponent,
    AudioControlsComponent,
    VoiceNotificationComponent,
    ModalVoiceNotificationComponent,
    OutgoingModalVoiceNotificationComponent,
    TroubleshootAudioComponent,
    AudioOptionsDialogComponent,
    AudioFeedbackComponent,
    VibesComponent,
    LinkClientDialogComponent,
    NewClientDialogComponent,
    ICMDialogComponent,
    DialOutDialogComponent,
    NoVoipDialogComponent,

    // SplashPageComponent,
  ],
  imports: [
    BrowserModule,
    BlockUIModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatRadioModule,
    MatAutocompleteModule,
    MatListModule,
    MatBadgeModule,
    MatChipsModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatSnackBarModule,
    MatSelectModule,
    MatDialogModule,
    MatSlideToggleModule,
    MatSidenavModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatToolbarModule,
    MatCheckboxModule,
     MatExpansionModule,
     MatFormFieldModule,
     MatDividerModule,
     MatTabsModule,
     MatTooltipModule,
     MatPaginatorModule,
     MatTableModule,
     MatSortModule,
     MatInputModule,
    LayoutModule,
    CookieModule.forRoot(),
    ContenteditableModule,
    NgxMaterialTimepickerModule.forRoot(),
    HttpModule,
    MobileLoginModuleModule,
     MomentModule,
     CalendarModule.forRoot({
       provide: DateAdapter,
       useFactory: adapterFactory,
     }),
     PickerModule,
    // MobileConversationModule,
    // HttpClientInMemoryWebApiModule.forRoot(
    //   InMemoryDataService, { dataEncapsulation: false }
    // ),
    NgbModule
  ],
  // tslint:disable-next-line:max-line-length
  entryComponents: [
    CallerNoteDialogComponent,
    EndCallComponent,
    ShiftSelectDialogComponent,
    NewConversationDialogComponent,
    RandomConversationDialogComponent,
    FindShiftReplacementDialogComponent,
    ExcelExportDialogComponent,
    InactiveDialogComponent,
    ConfirmDialogComponent,
    ExcelExportDialogComponent,
    // ChromeRequiredDialogComponent,
    SignalrDisconnectDialogComponent,
    InterpreterComponent,
    AutoAssignDialogComponent,
    ModalVoiceNotificationComponent,
    OutgoingModalVoiceNotificationComponent,
    TroubleshootAudioComponent,
    AudioOptionsDialogComponent,
    UserSelectDialogComponent,
    LinkClientDialogComponent,
    NewClientDialogComponent,
    ICMDialogComponent,
    DialOutDialogComponent,
    NoVoipDialogComponent,
  ],
  // tslint:disable-next-line:max-line-length
  providers: [
    AuthService,
    HubService,
    EntityService,
    EntityTypeService,
    MessageService,
    VoiceService,
    ConversationsService,
    GoogleTranslationService,
    ResourceService,
    UserService,
    PushNotificationsService,
    MobileInAppNotificationService,
    Platform,
    ModalController,
    AngularDelegate,
    Deploy,
    ConnectionToHubService,
    SignalRConnectionService,
    SignalrConnectionCheckerService,
    {provide: LOCALE_ID, useValue: lang },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpServiceInterceptor,
      multi: true,
      deps: [InternalCookieService]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (envService: EnvironmentService, injector: Injector) => {
        return () => envService.getEnviromentVariablesFromServer(injector);
      },
      multi: true,
      deps: [EnvironmentService, Injector]
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
