import { ShiftWrapper } from './shiftWrapper';

export class ShiftGroupWrapper {


    constructor(
        public shiftGroup: any,
        public shifts: ShiftWrapper[]
    )
    {
        
    }

}