import { Component, Inject, OnInit, SecurityContext } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-icm-dialog',
  templateUrl: './icm-dialog.component.html',
  styleUrls: ['./icm-dialog.component.scss']
})
export class ICMDialogComponent implements OnInit {

  clientUrl: SafeResourceUrl = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<ICMDialogComponent>) { }

  ngOnInit() {

    const container = document.querySelector('mat-dialog-container') as HTMLElement;
    container.style.padding = '1px';
    container.style.overflowX = 'hidden';

    container.style.display = 'flex';
    container.style.flexDirection = 'column';


    this.clientUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.clientUrl);

    const iframe = document.getElementById(this.data.title) as HTMLIFrameElement;
    const handleLoad = () => {
      // remove headers

      const menuElements = [
        iframe.contentWindow.document.getElementsByTagName('ripple-menu')[0] as HTMLElement,
        iframe.contentWindow.document.getElementsByClassName('pane__header--sticky')[0] as HTMLElement
      ].filter(elem => !!elem); // only those that exist

      for (const element of menuElements) {
        element.style.display = 'none';
        element.remove();
      }

      iframe.contentWindow.document.documentElement.style.setProperty('--menubar-height', '0px');
    };

    iframe.addEventListener('load', handleLoad, true);

  }

  close() {
    this.dialogRef.close();
  }

  navToClient() {
    window.open(this.data.clientUrl);
  }

}
