import { Component, OnInit, Input, ViewChild, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { ScheduleService } from '../../Services/Data/schedule.service';
import { ShiftBlock } from '../../models/shiftBlock';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-shift-availability-list',
  templateUrl: './shift-availability-list.component.html',
  styleUrls: ['./shift-availability-list.component.scss']
})
export class ShiftAvailabilityListComponent implements OnInit {

  @Input() shiftBlock: ShiftBlock;
  @Input() dayMoment: any;
  @Input() reservationType: any;
  users: any[];
  displayedColumns: string[] = ['numOfShifts', 'userName', 'availability', 'action', 'book'];
  data: any[];
  // dataSource: any;
  saving = false;
  // sort;
  // @ViewChild(MatSort) set content(content: ElementRef) {
  //   this.sort = content;
  //   if (this.sort && this.dataSource) {
  //     this.dataSource.sort = this.sort;
  //   }
  // }
  @Output() book: EventEmitter<number> = new EventEmitter();
  @Output() isSaving: EventEmitter<boolean> = new EventEmitter();

  userControl = new FormControl();
  filteredOptions: Observable<string[]>;

  unAvailableElementSelected: any;
  availableElementSelected: any;

  constructor(public scheduleService: ScheduleService) {
  }

  ngOnInit() {
    this.generateAvailabilityData();
  }

  private _unavailable_filter(value: any): string[] {
    try {
        const filterValue = value ? value.toLowerCase() : '';
        const res = this.data.filter(option => !option.availability && option.userName.toLowerCase().includes(filterValue));
        // if (res.length !== 1) {
        //     this.unAvailableElementSelected = undefined;
        // } else {
        //     this.unAvailableElementSelected = res[0];
        // }
        return res;
    } catch (err) {
    }
  }

  displayFn(user?: any): string | undefined {
    return user ? user.userName : undefined;
  }

  generateAvailabilityData() {
    if (!this.shiftBlock) {
      return;
    }
    this.scheduleService.calculateNumOfShiftsReserved(this.dayMoment.toDate(), 60).then(result => {
      this.users = this.scheduleService.allUsers;
      this.data = [];
      for (const user of this.users) {
        const row = {
          numOfShifts: user.numOfShifts || 0,
          userName: user.label,
          user,
          availability: false,
          shiftAvailabilityListRow: null
        };
        if (this.shiftBlock.availabilityList && this.shiftBlock.availabilityList.rows) {
          const aListRow = this.shiftBlock.availabilityList.rows.find(r => r.linkedProperties.user.id === user.id);
          if (aListRow) {
            row.availability = true;
            row.shiftAvailabilityListRow = aListRow;
          }
        }
        this.data.push(row);
      }
      // this.dataSource = new MatTableDataSource(this.data);
      this.filteredOptions = this.userControl.valueChanges.pipe(
        startWith<string | any>(''),
        map(value => typeof value === 'string' ? value : (value && value.userName ? value.userName : '')),
        map(name => name ? this._unavailable_filter(name) : this.data.filter(option => !option.availability).slice())
      );
    });
  }

  action(element) {
    this.userControl.setValue('');
    if (element) {
      // this.saving = true;
      this.isSaving.emit(this.saving);
      if (element.availability) {
        this.delete(element);
      } else {
        this.unAvailableElementSelected = undefined;
        this.add(element);
      }
    }
  }

  add(element) {
    if (element.shiftAvailabilityListRow) {
      return;
    }
    element.availability = true;
    this.scheduleService.createAvailabilityListRow(
      this.scheduleService.whichShiftBlock(this.dayMoment, this.shiftBlock, this.reservationType),
      this.dayMoment.toDate(),
      element.user).then(aListRow => {
      this.saving = false;
      this.isSaving.emit(this.saving);
      element.shiftAvailabilityListRow = aListRow;
    });
  }

  delete(element) {
    if (!element.shiftAvailabilityListRow) {
      return;
    }
    element.availability = false;
    this.scheduleService.deleteAvailabilityListRow(element.shiftAvailabilityListRow).subscribe(result => {
      this.saving = false;
      this.isSaving.emit(this.saving);
      element.shiftAvailabilityListRow = null;
    });
  }

  bookShift(element) {
    this.saving = true;
    this.isSaving.emit(this.saving);
    this.book.emit(element.user.id);
  }

}
