import { Component, OnInit, Input } from '@angular/core';


@Component({
  selector: 'app-conversation-result',
  templateUrl: './conversation-result.component.html',
  styleUrls: ['./conversation-result.component.scss']
})
export class ConversationResultComponent implements OnInit {

    reportData: any;
    @Input() data: any;
    @Input() title: any;
  constructor() { }

  ngOnInit() {
    this.reportData = this.data;

  }

}
