import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ScheduleService } from 'src/app/Services/Data/schedule.service';
import { yearsPerPage } from '@angular/material/datepicker/typings/multi-year-view';

@Component({
  selector: 'app-excel-export-dialog',
  templateUrl: './excel-export-dialog.component.html',
  styleUrls: ['./excel-export-dialog.component.scss']
})
export class ExcelExportDialogComponent implements OnInit {

  public months = [];
  public years = [];
  public startMonth: string;
  public startYear: string;
  public loading = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<ExcelExportDialogComponent>,
  public scheduleService: ScheduleService) {
    this.months = scheduleService.months;
    const thisYear = new Date().getFullYear();
    for (let i = 0; i < 10; i++) {
      this.years.push((thisYear - i).toString());
    }
  }

  ngOnInit() {
  }

  close() {
    const dateString = this.startYear + '/' + this.startMonth + '/' + '1';
    const startDate = new Date(dateString);
    console.log(dateString);
    console.log(startDate);
    this.exportAsExcel(startDate);
  }

  exportAsExcel(startDate: Date) {
    this.loading = true;
    console.log('loading...', this.loading);
    this.scheduleService.exportHoursAsExcel(startDate).then(result => {
      console.log('loaded', this.loading);
      this.dialogRef.close(true);
    });
  }

}
