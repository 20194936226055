import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Call } from '@twilio/voice-sdk';
import { first } from 'rxjs/operators';
import { VoiceService, OutgoingCallEvent } from '../voice-service/voice.service';
import { CallData, VoiceNotificationContainer } from './call-notification-data';

interface ModalAnswerObject {
  action: 'reject' | 'accept' | 'defer' | 'ignore';
  callData: CallData;
}

@Component({
  selector: 'app-voice-notification-dialog',
  templateUrl: './voice-notification-modal.component.html',
  styleUrls: ['./voice-notification.component.scss']
})
export class ModalVoiceNotificationComponent {
  min = Math.min;

  constructor(
    public dialogRef: MatDialogRef<ModalVoiceNotificationComponent, ModalAnswerObject>,
    @Inject(MAT_DIALOG_DATA) public callData: CallData
  ) {
    callData.call.on('accept', _ => this.close('accept', callData));
    callData.call.on('cancel', _ => this.close('ignore', callData));
  }

  close(action: ModalAnswerObject['action'], callData: CallData) {
    this.dialogRef.close({ action, callData });
  }
}

@Component({
  selector: 'app-voice-notification-dialog',
  templateUrl: './voice-notification-modal-outgoing.component.html',
  styleUrls: ['./voice-notification.component.scss']
})
export class OutgoingModalVoiceNotificationComponent implements OnDestroy {
  timerInterval: NodeJS.Timer;
  blink = true;

  constructor(
    private voice: VoiceService,
    public dialogRef: MatDialogRef<OutgoingModalVoiceNotificationComponent>,
    @Inject(MAT_DIALOG_DATA) public callEvent: OutgoingCallEvent
  ) {
    this.timerInterval = setInterval(_ => this.blink = !this.blink, 1000);
  }

  ngOnDestroy(): void {
    clearInterval(this.timerInterval);
  }

  userNumber() {
    const ext = (this.callEvent.user.properties.phoneextension || '').replace(/[_\s]/g, '');
    const number = `${this.callEvent.user.properties.onelinephone}${ext ? `#${ext}` : ''}`;
    return this.voice.formatNumber(number);
  }

  close() { this.dialogRef.close(); }
}

@Component({
  selector: 'app-voice-notification',
  templateUrl: './voice-notification.component.html',
  styleUrls: ['./voice-notification.component.scss']
})
export class VoiceNotificationComponent implements VoiceNotificationContainer {
  calls: CallData[] = [];
  callActive = false;

  constructor(protected voice: VoiceService, public dialog: MatDialog) {
    this.log('init');
    this.voice.callIncoming().subscribe(this.callIncoming.bind(this));
    this.voice.callOutgoing().subscribe(this.callOutgoing.bind(this));
  }

  hasPreviouslyMissed(o: CallData) {
    // find another call that is for same convo, but is different call
    return this.calls.findIndex(c =>
        c.callSid !== o.callSid &&
        c.call.customParameters.get('conversationId') === o.call.customParameters.get('conversationId')
      ) >= 0;
  }

  callOutgoing(callEvent: OutgoingCallEvent) {
    if (!callEvent.isVoip)
      this.dialog.open(OutgoingModalVoiceNotificationComponent, {
        width: '500px',
        data: callEvent,
      });
  }

  callIncoming(call: Call) {
    this.log('received call', call.parameters, call.customParameters);
    const callData = new CallData(call, this, false);
    const dialogRef = this.dialog
      .open<ModalVoiceNotificationComponent, CallData, ModalAnswerObject>(ModalVoiceNotificationComponent, {
        width: '500px',
        data: callData,
        disableClose: true,
      });

    dialogRef.afterClosed()
      .pipe(first())
      .subscribe(d => this.modalAnswer(d || { action: 'reject', callData }));
  }

  modalAnswer(closeObject: ModalAnswerObject) {
    this.calls.push(closeObject.callData.show());
    this[closeObject.action](closeObject.callData);
  }

  reject(call: CallData) {
    this.voice.rejectCall( call.dispose(call.status === 'active' ? 'ended' : 'declined') );
  }

  accept(call: CallData) {
    if (!this.callActive)
      this.voice.acceptCall( call.call  );
  }

  defer(call: CallData) {
      this.voice.deferCall( call.dispose('postponed') );
  }

  ignore(call: CallData) {
      this.voice.ignoreCall( call.dispose('missed') );
  }

  log(...args) {
    this.voice.log('Notification Component', ...args);
  }
}
