import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MessageService } from './Data/message.service';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { InternalCookieService } from './cookie.service';
import { EntityDataService } from './Data/entity-data.service';
import * as moment from 'moment';
import { RouterService } from './Hubs/router.service';
import { EnvironmentService } from './environment.service';
import { EnvironmentVar } from '../models/environmentvar';

@Injectable({
  providedIn: "root",
})
export class EmailService {
  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private cookieService: InternalCookieService,
    public entityDataService: EntityDataService,
    private routerService: RouterService,
    public envService: EnvironmentService
    ) {}
// todo: IzzyIonic Merge

  httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Headers": "Content-Type",
    }),
  };

  sendFindShiftReplacementEmail(item: any): Observable<any[]> {
    console.log("send email!!!!", item);

    return this.http
      .post<any[]>(
        this.envService.localEnvironment.restEndpointUrl + "/api/aasas/FindShiftReplacement/",
        item,
        this.httpOptions
      )
      .pipe(
        tap((email) => this.log("fetched email")),
        catchError(this.handleError("sendFindShiftReplacementEmail", []))
      );
  }

  notifyAvailabilityShiftAssigned (item: any): Observable<any[]> {
    console.log('send email!!!!', item);

    return this.http.post<any[]>(this.envService.localEnvironment.restEndpointUrl + '/api/aasas/notifyAvailabilityShiftAssigned/', item, this.httpOptions).pipe(
      tap(email => this.log('fetched email')),
      catchError(this.handleError('notifyAvailabilityShiftAssigned', []))
    );
  }

  notifyIfNoEnoughFilledAvailabilityShift (): Observable<any[]> {
    console.log('send email!!!!');

    return this.http.get<any[]>(this.envService.localEnvironment.restEndpointUrl + '/api/aasas/notifyIfNoEnoughFilledAvailabilityShift/',
      this.httpOptions).pipe(
      tap(email => this.log('fetched email')),
      catchError(this.handleError('notifyIfNoEnoughFilledAvailabilityShift', []))
    );
  }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      if (error.status === 401) {
        this.routerService.redirectToLoginPage();
      } else {
        //window.location.href = window.location.protocol + '//' + window.location.hostname + '/Login.aspx';
      }

      // TODO: send the error to remote logging infrastructure
      console.log("error ", error);
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a HeroService message with the MessageService */
  private log(message: string) {
    this.messageService.add(`email Service: ${message}`);
  }
}
